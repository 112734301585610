import { gql } from '@apollo/client';

export const GET_TEMPLATE_ROOMS = gql`
  query GetTemplateRooms($id: Uuid!) {
    proposal(id: $id) {
      id
      rooms {
        id
        name
        imageUrl
        iconUrl
        equipment {
          id
          name
          imageUrl
        }
      }
      templateRooms {
        id
        name
        imageUrl
        iconUrl
      }
    }
  }
`;
