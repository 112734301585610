/**
 *
 * ResetPasswordModal
 *
 */
// @ts-nocheck
import React from 'react';
import { Props } from './types';

import Modal from 'react-modal';
import { customStylesPasswordModal, Container, Text, FlexRow } from './styles';
import ButtonHover from '../ButtonHover';

const ResetPasswordModal = ({ isOpen, onRequestClose, mutation, type }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      style={customStylesPasswordModal}
    >
      <Container width='556px'>
        <Text marginTop={50} fontSize={61}>
          RESET PASSWORD
        </Text>
        <Text marginTop={15} fontSize={18}>
          {`Are you sure you want to reset this ${type}'s password?`}
        </Text>

        <FlexRow marginTop={42}>
          <ButtonHover
            color='white'
            label='cancel'
            onClick={() => onRequestClose()}
            type='submit'
          />
          <ButtonHover
            color='black'
            label='yes i`m sure'
            onClick={() => mutation()}
          />
        </FlexRow>
      </Container>
    </Modal>
  );
};

export default ResetPasswordModal;
