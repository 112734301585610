/**
 *
 * SubHeaderDesktop
 *
 */
// @ts-nocheck
import { FC } from 'react';
import { Props } from './types';
import {
  StyleWrapper,
  Container,
  Links,
  StyledLink,
  SubHeaderContainer,
  Space,
} from './styles';
import Logo from '../Logo';
import { schema, schemaType } from '../../routes';
import { useLocation, matchPath } from 'react-router-dom';

const SubHeaderDesktop: FC<Props> = ({
  currentRouteSchema,
  authorisedRoutes,
  location,
  permissions,
}) => {
  return (
    <StyleWrapper>
      <Container>
        <Logo width='187px' height='56px' />

        {/* no sub header component use links instead */}
        {!currentRouteSchema[0]?.subheaderComponent &&
          permissions?.roleName !== 'Customer' && (
            <Links>
              {authorisedRoutes?.map((route) => (
                <StyledLink
                  key={route?.nav?.navLabel}
                  to={route?.path}
                  matchedRoute={!!matchPath(location.pathname, route?.path)}
                >
                  {route?.nav?.navLabel}
                </StyledLink>
              ))}
            </Links>
          )}

        <Space />
      </Container>

      {/* has sub header component */}
      {currentRouteSchema[0]?.subheaderComponent && (
        <SubHeaderContainer>
          {currentRouteSchema[0]?.subheaderComponent()}
        </SubHeaderContainer>
      )}
    </StyleWrapper>
  );
};

export default SubHeaderDesktop;
