import { useEffect, useState } from 'react';
// gql
import { useQuery } from '@apollo/client';
import { WhoAmI } from '../gql/login/__generated__/WhoAmI';
import { WHO_AM_I } from '../gql/login/whoAmIQuery';
import { isLoggedInVar } from '../../apollo/cache';

import { useLocation, matchPath } from 'react-router-dom';
import { schema } from '../../routes';

const usePermissions = () => {
  const { data, refetch: refetchWhoAmI } = useQuery<WhoAmI>(WHO_AM_I, {
    pollInterval: 30000,
  });

  useEffect(() => {
    refetchWhoAmI();
  }, [isLoggedInVar]);

  const location = useLocation();

  const getCurrentRouteSchema = () =>
    schema().filter((route) =>
      matchPath(location.pathname, { path: route.path, exact: true })
    );

  const [currentRouteSchema, setCurrentRouteSchema] = useState(
    getCurrentRouteSchema()
  );

  useEffect(() => {
    setCurrentRouteSchema(getCurrentRouteSchema());
  }, [location.pathname]);

  return {
    permissions: data?.permissions,
    roleName: data?.permissions?.roleName,
    currentRouteSchema,
  };
};

export default usePermissions;
