import React from 'react';
import { Route, Switch } from 'react-router-dom';
import makeRouteMap from './utils/helperFunctions/makeRouteMap';
import SaveExit from './components/SaveExit';
import TestComp from './components/TestComp';
import ProofOfContext from './components/ProofOfContext';
import FormikProof from './components/FormikProof';

import UsersContainer from './containers/UsersContainer';
import ClientsContainer from './containers/ClientsContainer';
import ProposalsContainer from './containers/ProposalsContainer';
import ContentManagementContainer from './containers/ContentManagementContainer';
import OrganisationsContainer from './containers/OrganisationsContainer';

import CreateProposalContainer from './containers/CreateProposalContainer';
import SendProposalContainer from './containers/SendProposalContainer';

import LoginContainer from './containers/Login';

import HomeContainer from './containers/HomeContainer';

import ViewProposalContainer from './containers/ViewProposalContainer';
import ProposalSuccessContainer from './containers/ProposalSuccessContainer';
import CreateNewPassword from './containers/CreateNewPassword';
import SummaryContainer from './containers/SummaryContainer';

export const routeDefinitions = {
  unauthorised: {
    path: '/unauthorised',
  },
  demo: {
    path: '/demo',
  },
  home: {
    path: '/',
  },
  contextProof: {
    path: '/contextProof',
  },
  graphqlProof: {
    path: '/graphqlProof',
  },
  formikProof: {
    path: '/formikProof',
  },
  tableProof: {
    path: '/tableProof',
  },
  newsArticle: {
    path: '/newsfeed/article/:id',
    params: {
      id: true,
    },
  },
  users: {
    path: '/users',
  },
  clients: {
    path: '/clients',
  },
  proposals: {
    path: '/proposals',
  },
  proposalsCreate: {
    path: '/proposals/create',
  },
  proposalsView: {
    path: '/proposals/view/:id',
    params: {
      id: true,
    },
  },
  proposalsSend: {
    path: '/proposals/send/:id',
    params: {
      id: true,
    },
  },
  proposalsSuccess: {
    path: '/proposals/success/:id',
    params: {
      id: true,
    },
  },
  yourProposals: {
    path: '/proposals',
  },
  contentManagement: {
    path: '/content-management/:tab?',
  },
  contentManagementWorkSpaces: {
    path: '/content-management/workspaces',
  },
  contentManagementTechnology: {
    path: '/content-management/technology',
  },
  contentManagementSlides: {
    path: '/content-management/slides',
  },
  contentManagementProposalbricks: {
    path: '/content-management/proposalbricks',
  },
  organisations: {
    path: '/organisations',
  },
  passwordReset: {
    path: '/password-reset',
  },
  login: {
    path: '/login-modal',
  },
  CreateRoomModal: {
    path: '/create-room-modal',
  },
  ProposalBricks: {
    path: '/proposal-bricks',
  },
  SummaryOfActivity: {
    path: '/proposals/summary-of-activity/:id',
    params: {
      id: true,
    },
  },
};

export interface schemaType {
  path: string;
  type: 'Route';
  component: React.ElementType | null;
  isInNav?: boolean;
  navLabel?: string;
  exact?: boolean;
  subheaderComponent?: React.ElementType | null;
  nav?: {
    navLabel: string;
    moduleKey?: string;
    subItems?: any[];
  };
}

export const schema = () => {
  return [
    {
      path: routeDefinitions.home.path,
      type: 'Route',
      component: HomeContainer,
      exact: true,
    },
    {
      path: routeDefinitions.unauthorised.path,
      type: 'Route',
      component: TestComp,
    },
    {
      path: routeDefinitions.contextProof.path,
      type: 'Route',
      component: ProofOfContext,
    },
    {
      path: routeDefinitions.formikProof.path,
      type: 'Route',
      component: FormikProof,
    },

    // real route mappings
    {
      path: routeDefinitions.users.path,
      type: 'Route',
      component: UsersContainer,
      nav: {
        navLabel: 'users',
        moduleKey: 'users',
      },
    },
    {
      path: routeDefinitions.clients.path,
      type: 'Route',
      component: ClientsContainer,
      nav: {
        navLabel: 'clients',
        moduleKey: 'clients',
      },
    },
    {
      path: routeDefinitions.proposals.path,
      type: 'Route',
      component: ProposalsContainer,
      exact: true,
      nav: {
        navLabel: 'proposals',
        moduleKey: 'proposals',
      },
    },
    {
      path: routeDefinitions.proposalsCreate.path,
      type: 'Route',
      component: CreateProposalContainer,
      subheaderComponent: () => <span></span>,
      exact: true,
    },
    {
      path: routeDefinitions.contentManagement.path,
      type: 'Route',
      component: ContentManagementContainer,
      nav: {
        navLabel: 'content management',
        moduleKey: 'contentManagement',
        subItems: [
          {
            path: routeDefinitions.contentManagementWorkSpaces.path,
            label: 'Workspaces',
          },
          {
            path: routeDefinitions.contentManagementTechnology.path,
            label: 'Technology',
          },

          {
            path: routeDefinitions.contentManagementProposalbricks.path,
            label: 'Proposal Bricks',
          },
        ],
      },
    },
    {
      path: routeDefinitions.organisations.path,
      type: 'Route',
      component: OrganisationsContainer,
      nav: {
        navLabel: 'organisations',
        moduleKey: 'organisations',
      },
    },
    {
      path: routeDefinitions.proposalsView.path,
      type: 'Route',
      component: ViewProposalContainer,
      // subheaderComponent by default overrides the render of the navigation items
      subheaderComponent: () => <SaveExit />,
      exact: true,
    },
    {
      path: routeDefinitions.proposalsSend.path,
      type: 'Route',
      component: SendProposalContainer,
      // subheaderComponent by default overrides the render of the navigation items
      subheaderComponent: () => <span></span>,
      exact: true,
    },
    {
      path: routeDefinitions.proposalsSuccess.path,
      type: 'Route',
      component: ProposalSuccessContainer,
      exact: true,
    },
    {
      path: routeDefinitions.login.path,
      type: 'Route',
      component: LoginContainer,
      nav: {
        navLabel: 'your proposals',
        moduleKey: 'clientView',
      },
    },

    {
      path: routeDefinitions.passwordReset.path,
      type: 'Route',
      component: CreateNewPassword,
      nav: {
        navLabel: 'your proposals',
        moduleKey: 'clientView',
      },
    },

    {
      path: routeDefinitions.SummaryOfActivity.path,
      type: 'Route',
      component: SummaryContainer,
    },
  ];
};

export const genRoutes = (schema: any[]) => {
  return schema?.map((route, i) => {
    if (route.type === 'Route') return <Route {...route} key={i} />;

    // @@TODO implement special routes here for being logged in etc, different levels of permission
    // if (route.type === 'ManagersFunctions') {
    //     return <ManagersRoute {...route} key={i} />;
    // }
    // if (route.type === 'MustLogInRoute')
    //     return <MustLogInRoute {...route} key={i} />;

    return null;
  });
};

// @@TODO use this boilerplate to create a group of routes for a rule if needed
// all routes here will use the entitypopup calls with the manager flag set to true
// export const managerRoutesPaths = [
//     routeDefinitions?.studentDetailsMandatoryLearning?.path,
//     // routeDefinitions?.studentDetailsLearnerJourneys?.path,
//     routeDefinitions?.studentDetailsPendingEnrolments?.path,
//     routeDefinitions?.mandatoryLearningManagersRemoveUsers?.path,
//     routeDefinitions?.mandatoryLearningManagersAddUsers?.path,
//     routeDefinitions?.mandatoryLearningManagersStaffView?.path,
//     routeDefinitions?.mandatoryLearningManagersAddAssessment?.path,
//     routeDefinitions?.mandatoryLearningManagersAddOnlineLearning?.path,
//     routeDefinitions?.mandatoryLearningManagersAdd?.path,
//     routeDefinitions?.mandatoryLearningManagers?.path,
//     routeDefinitions?.enrolmentsEnrolmentHistory?.path,
//     routeDefinitions?.enrolmentsApprovedEnrolments?.path,
//     routeDefinitions?.enrolmentsAssignedClassrooms?.path,
//     routeDefinitions?.enrolments?.path,
//     routeDefinitions?.enrolmentsPendingEnrolments?.path,
//     routeDefinitions?.staffCalendar?.path,
//     routeDefinitions?.staffList?.path,
//     routeDefinitions?.reports?.path,
// ];

const generatedRoutes = genRoutes(schema());

const routes = (
  <Switch>
    {!!generatedRoutes && generatedRoutes}

    {/*/!** demo new container *!/*/}
    {/*<MustLogInRoute exact path="/demo" component={Demo}/>*/}

    {/** not found */}
    <Route render={() => <h1>Page Not Found</h1>} />
  </Switch>
);

export default routes;

/**
 * Keep your routes in a single, type-safe source of truth with a routeMap
 */
export const routeMap = makeRouteMap(routeDefinitions);
