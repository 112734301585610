import { CREATE_INTERACTION } from '../gql/clickstats/createUserInteraction';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';

export const useInteractionState = (brick: any) => {
  const [createInteraction] = useMutation(CREATE_INTERACTION);
  const { id }: any = useParams();

  const createBrickInteractionMutation = () => {
    const input = {
      userInteractionTypeId: 1,
      entityId: 1,
      entityTableId: brick?.id,
      proposalId: id,
    };
    createInteraction({ variables: { input } })
      .then(() => null)
      .catch((error) => null);
  };

  const createMeetingSpaceInteraction = () => {
    const input = {
      userInteractionTypeId: 1,
      entityId: 5,
      entityTableId: 'bc7898dd5ff143608e0f95301668bada',
      proposalId: id,
    };
    createInteraction({ variables: { input } })
      .then(() => null)
      .catch((error) => null);
  };

  return {
    createBrickInteractionMutation,
    createMeetingSpaceInteraction,
  };
};
