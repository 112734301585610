/**
 *
 * EditUserModal
 *
 */

import styled from 'styled-components';

import { colors, fonts } from '../../utils/styledTheme';

export const customStylesEditModal = {
  content: {
    maxWidth: 1401,
    margin: 'auto',
    borderRadius: 6,
    background: '#F7F7F7',

    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
    paddingbottom: 70,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,

    onset: 0,
    zIndex: 1,
  },
};

export const customStylesDeleteModal = {
  content: {
    maxWidth: 656,
    margin: 'auto',
    height: 296,
    borderRadius: 6,
    background: '#F7F7F7',
    padding: 0,
    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 1,
  },
};

export const Container = styled.div<{ width?: string }>`
  width: ${(props) => props.width || '90%'};
  height: auto;
  margin: 0 auto;
`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div<{ width?: string; minHeight?: string }>`
  position: relative;
  width: ${(props) => props.width || '100%'};
  min-height: ${(props) => props.minHeight || '106px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Label = styled.div<{ marginTop?: string }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop};
 `;

export const FlexRow = styled.div<{
  flexDirection?: string;
  justify?: string;
  marginTop?: number;
  alignItems?: string;
}>`
  width: 100%;
  display: flex;

  height: auto;
  justify-content: ${(props) => props.justify || 'space-between'};
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems || 'center'};
  margin-top: ${(props) => props.marginTop}px;
`;

export const LineBreak = styled.div`
  margin-top: 21px;
  width: 100%;
  height: 2px;
  border-bottom: 2px solid ${colors.grey};
`;

export const Text = styled.div<{ marginTop?: number; fontSize?: number }>`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  white-space: nowrap;
  margin-top: ${(props) => props.marginTop}px;
  font-size: ${(props) => props.fontSize}px;
`;
