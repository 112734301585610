import { gql } from '@apollo/client';

export const EDIT_EQUIPMENT = gql`
  mutation EditEquipment($input: EditEquipmentRequestInput!) {
    editEquipment(input: $input) {
      equipment {
        id
        name
        imageUrl
        equipmentCategoryId
        collateral {
          id
          name
          collateralTypeId
        }
      }
    }
  }
`;
