/**
 *
 * TableProofContainer
 *
 */
// @ts-nocheck
import React from 'react';
import { Grid } from '@material-ui/core';

import { Title, Row } from '../../components/TableProof/styles';

import Table from '../../components/Table';

import OrgModal from '../../components/OrgModal';
import { ReactComponent as Edit } from '../../images/svg/EditIcon.svg';
import { ReactComponent as Delete } from '../../images/svg/DeleteIcon.svg';

import { useQuery } from '@apollo/client';

import SearchTermForm from '../../components/Forms/SearchTermForm';

import { GetRegions } from '../../utils/gql/regions/__generated__/GetRegions';
import { GET_REGIONS } from '../../utils/gql/regions/regionsQuery';
import ButtonHover from '../../components/ButtonHover';
import DeleteModal from '../../components/DeleteModal';
import useOrganisationState from './useOrganisationState';
import Container from '../../components/Container';

import usePermissions from '../../utils/hooks/usePermissions';

const OrganisationsContainer = () => {
  const { permissions } = usePermissions();

  const {
    editOrganisationFormik,
    createOrganisationFormik,
    editOrganisationMutation,
    deleteOrganisationMutation,
    createOrganisationMutation,
    setCreateModal,
    setEditModal,
    setDeleteModal,
    selectedModal,
    selectedOrganisation,
    resetAllModals,
    setSearchTerm,
    searchTerm,
    data,
  } = useOrganisationState();

  const { createModal, editModal, deleteModal } = selectedModal;

  const {
    loading: regionsLoading,
    error: regionsError,
    data: regionsData,
  } = useQuery<GetRegions>(GET_REGIONS);

  const resolveDomains = (
    domains:
      | (SearchOrganisastionsTable_searchOrganisations_domains | null)[]
      | null
      | undefined
  ) => {
    const result = domains?.map((domain, index) => {
      if (!!domain?.name && domains?.length === index + 1) return domain.name;
      if (!!domain?.name) return `${domain.name}, `;
      return null;
    });
    return result;
  };

  const resolveRegions = (regionId: number | undefined, labelOnly = true) => {
    let matchingRegion = null;
    if (!!regionId) {
      matchingRegion = regionsData?.regions?.filter(
        (region) => region?.value === regionId
      );
    }
    if (!!matchingRegion && matchingRegion?.length > 0) {
      if (labelOnly) return matchingRegion[0]?.label;
      return matchingRegion;
    }

    return regionId;
  };

  const returnDomains = (domains: any) => {
    let reformattedArray = domains?.map((obj: any, index: number) => {
      return <div key={index}>{obj.name}</div>;
    });
    return reformattedArray;
  };

  const returnRows = (data: any) => {
    let map = data?.searchOrganisations
      ?.filter((org) => org.status !== -1)
      ?.map((org) => {
        return {
          ...org,
          id: org?.id,
          organisationName: org?.name,
          domains: returnDomains(org?.domains),
          regionId: org?.regionId,
          region: resolveRegions(org?.regionId),
          organisationDomains: resolveDomains(org?.domains),
        };
      });
    return map;
  };

  const icons = [
    {
      component: Edit,
      action: setEditModal,
    },
    {
      component: Delete,
      action: setDeleteModal,
    },
  ];

  const salesIcons = [
    {
      component: Edit,
      action: setEditModal,
    },
    {
      component: Delete,
      action: setDeleteModal,
    },
  ];

  return (
    <Container marginTop={50} paddingBottom={159}>
      <Row marginBottom={27}>
        <Title fontSize={22}>Organisation management</Title>
        <ButtonHover
          color='black'
          label='Create organisation'
          onClick={() => setCreateModal()}
        />
      </Row>

      {setSearchTerm && (
        <SearchTermForm changeCallback={(e) => setSearchTerm(e.target.value)} />
      )}

      <Table
        headers={['Logo', 'Organisation', 'Domain(s)', 'Region', 'icons']}
        colObjectKeys={[
          ['logoUrl'],
          ['organisationName'],
          ['domains'],
          ['region'],
          ['icons'],
        ]}
        rows={returnRows(data)}
        icons={permissions?.roleName === 'Sales' ? salesIcons : icons}
      />

      <Grid container alignItems='flex-end' justifyContent='flex-end'>
        <ButtonHover
          color='black'
          label='Create organisation'
          onClick={() => setCreateModal()}
        />
      </Grid>

      <DeleteModal
        type='organisation'
        onRequestClose={() => resetAllModals()}
        mutation={() => deleteOrganisationMutation(selectedOrganisation)}
        isOpen={deleteModal}
      />

      <OrgModal
        isOpen={createModal}
        formik={createOrganisationFormik}
        onRequestClose={() => resetAllModals()}
        title='CREATE ORGANISATION'
        mutation={() => createOrganisationMutation(searchTerm)}
      />

      <OrgModal
        isOpen={editModal}
        formik={editOrganisationFormik}
        onRequestClose={() => resetAllModals()}
        title='EDIT ORGANISATION'
        mutation={() => editOrganisationMutation()}
      />
    </Container>
  );
};

export default OrganisationsContainer;
