/**
 *
 * WebCamImg
 *
 */

import { FC } from 'react';
import { Props } from './types';
import { StyleWrapper, Img } from './styles';
import src from '../../images/WebCamImg/RallyBar.jpg';
import { colors } from '../../utils/styledTheme';

const WebCamImg: FC<Props> = ({ type }) => (
  <StyleWrapper background={type === 'PREVIEW' ? 'white' : 'transparent'}>
    <Img src={src} alt='web cam image' />
  </StyleWrapper>
);

export default WebCamImg;
