import { FC, MouseEvent } from 'react';
import { EditToggleContainer, EditToggle } from './styles';

const Eye: FC<EyeProps> = ({ openMenu, colorCode, isOpen }) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    openMenu();
  };

  return (
    <EditToggleContainer
      onClick={(e: MouseEvent<HTMLDivElement>) => onClick(e)}
    >
      <EditToggle />
    </EditToggleContainer>
  );
};

export default Eye;
