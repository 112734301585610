// @ts-nocheck
import React, { useState, useContext, FC } from 'react';
import { useStyles, WrappedImg } from './styles';
import EditInputHelper from '../../EditInputHelper';
import { Grid, InputLabel } from '@material-ui/core';
import ProposalSchema, { EditProposalInterface } from './formik';
import { Form, Formik, FormikProps } from 'formik';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';

import { GetProposal } from '../../../utils/gql/proposals/__generated__/GetProposal';
import InlineTitleField from '../../FormFields/InlineTitleField';

import InlineNameField from '../../FormFields/InlineNameField';

import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROPOSAL } from '../../../utils/gql/proposals/proposalsQuery';
import InlineWysiwygField from '../../FormFields/InlineWysiwygField';
import AutoSave from '../../AutoSave';
import { EditProposal } from '../../../utils/gql/proposals/__generated__/EditProposal';
import { EDIT_PROPOSAL } from '../../../utils/gql/proposals/editProposalMutation';
import { useSnackbar } from 'notistack';
import { WhoAmI } from '../../../utils/gql/login/__generated__/WhoAmI';
import { WHO_AM_I } from '../../../utils/gql/login/whoAmIQuery';

import ProposalContext from '../../../context/proposal/proposalContext';
import { ProposalI } from '../../../context/proposal/types';

import { useHistory } from 'react-router';

const EditProposalForm = () => {
  let history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { id } = useParams<any>();
  const [editTitle, setEditTitle] = useState(false);
  const [editCustomerName, setEditCustomerName] = useState(false);
  const [edit, setEdit] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { setSendEditProposal } = proposalContext;

  const { loading, error, data } = useQuery<GetProposal>(GET_PROPOSAL, {
    variables: { id },
  });

  const { data: userData, loading: userLoading } = useQuery<WhoAmI>(WHO_AM_I, {
    fetchPolicy: 'cache-only',
  });

  const readMode =
    userData?.permissions?.roleName === 'Customer' ||
    !!!userData?.permissions?.roleName;

  const [
    editProposal,
    {
      data: editProposalData,
      loading: editProposalLoading,
      error: editProposalError,
    },
  ] = useMutation<EditProposal>(EDIT_PROPOSAL);

  const handleSubmit = (values: EditProposalInterface) => {
    console.log('fired');
  };

  const resolveKeyChanged = (
    value: string | null | undefined,
    intialValue: string | null | undefined
  ) => {
    return !!value && value?.length > 0;
  };

  const returnToHomePage = (returnHome?: boolean) => {
    if (returnHome) {
      history.push('/');
    } else return;
  };

  const saveMutation = (
    values: EditProposalInterface,
    returnHome?: boolean
  ) => {
    // construct the arguments to be sent to mutate the proposal
    const args = {
      ...(resolveKeyChanged(values?.proposalTitle, data?.proposal?.title) && {
        title: values?.proposalTitle,
      }),
      ...(resolveKeyChanged(
        values?.customerName,
        data?.proposal?.customerFullName
      ) && { customerFullName: values?.customerName }),
      ...(resolveKeyChanged(values?.proposalIntro, data?.proposal?.intro) && {
        intro: values?.proposalIntro,
      }),
    };

    // we only want to fire the mutation if we have new populated arguments to send
    if (!!args && Object.keys(args).length > 0) {
      editProposal({
        variables: {
          input: { id, ...args, status: 0 },
        },
      })
        .then((resp) => {
          enqueueSnackbar(intl.formatMessage(messages.proposalSaved), {
            variant: 'success',
          });
          setSendEditProposal(false);
          returnToHomePage(returnHome);
        })
        .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    }
  };

  const getProposalIntro = () => {
    let intro = data?.proposal?.intro;

    if (!intro?.length) {
      intro = data?.proposal?.templateIntro;
    }
    return intro;
  };

  const initialValues: EditProposalInterface = {
    proposalTitle: data?.proposal?.title,
    customerName: data?.proposal?.customerFullName,
    proposalIntro: getProposalIntro(),
  };

  const calcFieldWidth = (name: string, values: EditProposalInterface) => {
    // @ts-ignore
    if (values[name]?.length === null || !!!values[name]) {
      return '0ch';
    }

    // @ts-ignore
    return `${values[name].length + 2.3}ch`;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // initialValues={data}
      // @ts-ignore
      validationSchema={ProposalSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
      }: FormikProps<EditProposalInterface>) => (
        <Form autoComplete='off' className={classes.formStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.proposalTitle} />

                <EditInputHelper
                  onClick={() => setEditTitle(!editTitle)}
                  open={editTitle}
                  edit={intl.formatMessage(messages.edit)}
                  close={intl.formatMessage(messages.doneEdit)}
                  readMode={readMode}
                />
              </InputLabel>
              <InlineTitleField
                readMode={readMode}
                name='proposalTitle'
                onBlur={handleBlur}
                editMode={editTitle}
                variant='outlined'
                onChange={handleChange}
                value={values.proposalTitle}
                marginTop='5px'
                width={calcFieldWidth('proposalTitle', values)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.companyLogo} />
              </InputLabel>
              {!!data?.proposal?.organisation && (
                <WrappedImg src={data?.proposal?.organisation?.logoUrl} />
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.customerName} />

                <EditInputHelper
                  onClick={() => setEditCustomerName(!editCustomerName)}
                  open={editCustomerName}
                  edit={intl.formatMessage(messages.edit)}
                  close={intl.formatMessage(messages.doneEdit)}
                  readMode={readMode}
                />
              </InputLabel>
              <InlineNameField
                readMode={readMode}
                editMode={editCustomerName}
                name='customerName'
                onBlur={handleBlur}
                variant='outlined'
                onChange={handleChange}
                value={values.customerName}
                marginTop='5px'
                width={calcFieldWidth('customerName', values)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.proposalIntro} />

                <EditInputHelper
                  onClick={() => setEdit(!edit)}
                  open={edit}
                  edit={intl.formatMessage(messages.edit)}
                  close={intl.formatMessage(messages.doneEdit)}
                  readMode={readMode}
                />
              </InputLabel>
              <InlineWysiwygField editMode={edit} name={'proposalIntro'} />
            </Grid>
            {!readMode && <AutoSave save={saveMutation} timeout={20000} />}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditProposalForm;
