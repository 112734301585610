import { StateInterface, ACTIONTYPE } from './types';

const organisationReducer = (state: StateInterface, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_CREATE_MODAL':
      return {
        ...state,
        selectedOrganisation: null,
        selectedModal: {
          createModal: true,
          editModal: false,
          deleteModal: false,
        },
      };

    case 'SET_EDIT_MODAL':
      return {
        ...state,
        selectedOrganisation: action.payload.selectedOrganisation,
        selectedModal: {
          createModal: false,
          editModal: true,
          deleteModal: false,
        },
      };

    case 'SET_DELETE_MODAL':
      return {
        ...state,
        selectedOrganisation: action.payload.selectedOrganisation,
        selectedModal: {
          createModal: false,
          editModal: false,
          deleteModal: true,
        },
      };

    case 'RESET_ALL_MODALS':
      return {
        ...state,
        selectedOrganisation: null,
        selectedModal: {
          createModal: false,
          editModal: false,
          deleteModal: false,
        },
      };

    default:
      return state;
  }
};

export default organisationReducer;
