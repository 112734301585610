/**
 *
 * EditInputHelper
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fonts } from '../../utils/styledTheme';

export const EditWrapper = styled.div<{ marginLeft?: string }>`
  margin-left: ${(props) => props.marginLeft};
  width: fit-content;
  width: -moz-fit-content;
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const EditIcon = styled(motion.div)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' class='sc-iMbuyt eDdAkK'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23393b3a;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath class='b' d='M289.346,295.485l-.456-.747,4.415-2.774.455.747a1.774,1.774,0,0,1-.562,2.42l-1.472.924A1.716,1.716,0,0,1,289.346,295.485Zm-5.18-10.178a.429.429,0,0,0-.6-.143.442.442,0,0,0-.14.6l5.005,8.221.736-.463ZM287.845,283a.429.429,0,0,0-.6-.143.443.443,0,0,0-.14.6l5.006,8.221.736-.462Zm3.535,9.145-5.006-8.221a.858.858,0,0,0-1.191-.285.885.885,0,0,0-.28,1.21l5.005,8.22Zm-8.262-7.724a1.247,1.247,0,0,1,.8-.167,1.826,1.826,0,0,1,.036-.27,1.745,1.745,0,0,1,.775-1.091,1.62,1.62,0,0,1,1.543-.134,1.3,1.3,0,0,1,.524-.648c.023-.015.05-.015.075-.028L283,280.049l.06,4.413c.021-.014.038-.032.059-.046Z' transform='matrix(-0.259, -0.966, 0.966, -0.259, -194.191, 362.766)'%3E%3C/path%3E%3C/svg%3E");
`;

export const EditText = styled.div`
  width: fit-content;
  width: -moz-fit-content;
  height: auto;
  font-family: ${fonts.bold};
  color: black;
  font-weight: bold;
  margin-left: 0.5rem;
  font-size: 20px;
`;

export const CloseIcon = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  color: #c0291c;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`;
