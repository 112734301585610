import { gql } from '@apollo/client';

export const EDIT_USER = gql`
  mutation EditUser($input: EditUserRequestInput!) {
    editUser(input: $input) {
      user {
        id
        firstName
        lastName
        phoneNumber
        jobTitle
        locked
        status
      }
    }
  }
`;
