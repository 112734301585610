import { gql } from '@apollo/client';

export const CREATE_ROOM = gql`
  mutation CreateRoom($input: CreateRoomRequestInput!) {
    createRoom(input: $input) {
      room {
        id
        name
        imageUrl
        iconUrl
        minCapacity
        maxCapacity
      }
    }
  }
`;
