/**
 *
 * Reusable Table
 *
 */

import { FC } from 'react';
import { Props } from './types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { IconButton } from '@material-ui/core';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  StyledTableRow,
  StyledTableCell,
  Image,
  logoBackground,
  Svg,
  Flex,
  Container,
} from './styles';

const returnElement = (row: any, key: string) => {
  if (Array.isArray(row[key]) && key === 'collateral') {
    let extra = row[key].length > 4 ? row[key].length - 4 : null;

    return (
      <Flex>
        {row[key]?.slice(0, 4)?.map((el: any, index: number) => (
          <Svg src={el} key={index} alt='svg' />
        ))}
        {extra && <div> {'+' + ' ' + extra}</div>}
      </Flex>
    );
  }

  if (Array.isArray(row[key])) {
    return row[key].map((el: any, index: number) => (
      <div key={index}>{el}</div>
    ));
  }

  if (
    key === 'logoUrl' ||
    key === 'imageUrl' ||
    key === 'iconUrl' ||
    key === 'fileUrl'
  ) {
    return <Image src={row[key] ? row[key] : logoBackground} alt='image' />;
  }

  if (key === 'collLogo') {
    console.log(row[key], 'collLogo');
    return <Svg src={row[key]} alt='logo' />;
  }

  if (row[key]) {
    return row[key];
  }
  if (row[key] === 0) {
    return row[key];
  }
};

const returnColData = (row: any, keys: any[]) => {
  return keys?.map((key, index: number) => (
    <div key={index}>{returnElement(row, key)}</div>
  ));
};

const onClick = (icon: any, row: any) => {
  icon.action(row);
};

const returnIcons = (keys: any[], icons: any[], row: any) => {
  if (keys[0] === 'icons') {
    return icons?.map((icon, index: number) => (
      <IconButton key={index} onClick={() => onClick(icon, row)}>
        <icon.component />
      </IconButton>
    ));
  }
};

const CustomTable: FC<Props> = ({ headers, rows, colObjectKeys, icons }) => {
  return (
    <Container>
      <Table
        sx={{ minWidth: 740, width: '100%', boxSizing: 'border-box' }}
        aria-label='customized table'
      >
        <TableHead>
          <TableRow>
            {headers?.map((header) => (
              <StyledTableCell key={header}>{header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <StyledTableRow key={row.id}>
              {colObjectKeys?.map((keys, index) => (
                <StyledTableCell key={index} align='left' style={{ border: 0 }}>
                  {returnColData(row, keys)}

                  {/* last column for icons */}
                  {returnIcons(keys, icons, row)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default CustomTable;
