// @ts-nocheck
import { useState, useReducer, useEffect } from 'react';
import UserTableReducer from './userTableReducer';

// graphql
import { useMutation, useQuery } from '@apollo/client';

import { SEARCH_USERS } from '../../utils/gql/users/searchUsersQuery';
import { SearchUsers } from '../../utils/gql/users/__generated__/SearchUsers';
import useDebounce from '../../utils/hooks/useDebounce';

import { EDIT_USER } from '../../utils/gql/users/editUserMutation';
import { CREATE_USER } from '../../utils/gql/users/createUserMutation';
import { RESET_PASSWORD } from '../../utils/gql/users/resetPasswordMutation';

import { USER_ROLES } from '../../utils/gql/users/getUserRoles';
import { UserRoles_userRoles } from '../../utils/gql/users/__generated__/UserRoles';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// formik
import { useFormik } from 'formik';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  role: Yup.object().shape({
    value: Yup.string().required('Role is Required'),
    label: Yup.string(),
  }),
});

const UserTableState = () => {
  const initialState = {
    selectedUser: {},
    selectedModal: {
      editModal: false,
      deleteModal: false,
      createModal: false,
      resetPasswordModal: false,
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const { data: userRolesData } = useQuery<UserRoles_userRoles>(USER_ROLES);

  const [state, dispatch] = useReducer(UserTableReducer, initialState);

  const { selectedUser, selectedModal } = state;

  const [searchTerm, setSearchTerm] = useState('');

  const { loading, error, data, refetch } = useQuery<SearchUsers>(
    SEARCH_USERS,
    {
      variables: { input: { searchTerm: null, skip: null, take: null } },
      fetchPolicy: 'network-only',
    }
  );

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const refetchHelper = () => {
    if (searchTerm === '' || searchTerm === null) {
      refetch({
        input: { searchTerm: null, skip: null, take: null },
      });
    } else if (debouncedSearchTerm) {
      refetch({
        input: { searchTerm: debouncedSearchTerm, skip: 0, take: null },
      });
    }
  };

  useEffect(() => {
    refetchHelper();
    // eslint-disable-next-line
  }, [searchTerm, debouncedSearchTerm]);

  const setCreateModal = () => {
    dispatch({
      type: 'SET_CREATE_MODAL',
    });
  };

  const setEditModal = (selectedUser: any) => {
    dispatch({
      type: 'SET_EDIT_MODAL',
      payload: { selectedUser },
    });
  };

  const setDeleteModal = (selectedUser: any) => {
    dispatch({
      type: 'SET_DELETE_MODAL',
      payload: { selectedUser },
    });
  };

  const setResetPasswordModal = (selectedUser: any) => {
    dispatch({
      type: 'SET_RESET_PASSWORD_MODAL',
      payload: { selectedUser },
    });
  };

  const resetAllModals = () => {
    dispatch({
      type: 'RESET_ALL_MODALS',
    });
  };

  // DELETE MUTATION
  const [editUser] = useMutation<any>(EDIT_USER);

  const deleteUserMutation = () => {
    let input = {
      id: selectedUser?.id,
      status: -1,
    };

    editUser({
      variables: { input },
    })
      .then((res) => {
        resetAllModals();
        refetchHelper();
        formikEditUser.resetForm();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  // EDIT MUTATION
  const formikEditUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedUser?.firstName,
      lastName: selectedUser?.lastName,
      email: selectedUser?.email,
      phoneNumber: selectedUser?.phoneNumber,
      jobTitle: selectedUser?.jobTitle,
      role: {
        label: selectedUser?.userRole?.name,
        value: selectedUser?.userRole?.id,
      },
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const editUserMutation = () => {
    let input = {
      id: selectedUser?.id,
      firstName: formikEditUser.values.firstName,
      lastName: formikEditUser.values.lastName,
      jobTitle: formikEditUser.values.jobTitle,
      phoneNumber: formikEditUser.values.phoneNumber,
      userRoleId: formikEditUser.values.role?.value,
    };

    if (
      formikEditUser.errors?.firstName ||
      formikEditUser?.errors.lastName ||
      formikEditUser?.errors?.email
    ) {
      return;
    }

    editUser({
      variables: { input },
    })
      .then((res) => {
        resetAllModals();
        refetchHelper();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  // CREATE MUTATION
  const [createUser] = useMutation<any>(CREATE_USER);

  const formikCreateUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: {},
      phoneNumber: '',
      jobTitle: '',
    },

    onSubmit: (values) => {
      console.log(values);
    },

    validationSchema,
  });

  const createUserMutation = () => {
    let input = {
      firstName: formikCreateUser.values.firstName,
      lastName: formikCreateUser.values.lastName,
      jobTitle: formikCreateUser.values.jobTitle,
      phoneNumber: formikCreateUser.values.phoneNumber,
      userRoleId: formikCreateUser.values.role.value,
      email: formikCreateUser.values.email,
      password: null,
    };

    if (
      formikCreateUser?.errors?.firstName ||
      formikCreateUser?.errors.lastName ||
      formikCreateUser?.errors?.email
    ) {
      return;
    }

    createUser({
      variables: { input },
    })
      .then((res) => {
        refetchHelper();
        resetAllModals();
        formikCreateUser.resetForm();
      })
      .catch((error) => {
        formikCreateUser.setErrors({ email: error.message });
      });
  };

  // RESET PASSWORD
  const [resetPassword] = useMutation<any>(RESET_PASSWORD);

  const resetPasswordMutation = () => {
    resetPassword({
      variables: {
        input: {
          email: selectedUser?.email,
        },
      },
    })
      .then((res) => {
        resetAllModals();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        resetAllModals();
      });
  };

  return {
    searchTerm,
    setSearchTerm,
    searchUsers: data?.searchUsers,
    loading,

    selectedUser,
    selectedModal,
    setCreateModal,
    setEditModal,
    setDeleteModal,
    setResetPasswordModal,
    resetAllModals,
    deleteUserMutation,
    formikEditUser,
    formikCreateUser,
    editUserMutation,
    createUserMutation,
    resetPasswordMutation,
    userRoles: userRolesData?.userRoles,
  };
};

export default UserTableState;
