import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import styled from 'styled-components';
import { fonts } from '../../../utils/styledTheme';

export const fontColor = '#393b3a';
export const grey = '#E1E2E3';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    formStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '12px',
      width: '100%',
    },
    errorMessage: {
      fontSize: '14px',
      paddingLeft: '4px',
    },
    formTitle: {
      color: fontColor,
      fontSize: 32,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    inputLabel: {
      color: fontColor,
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    gridLine: {
      border: '1px solid red',
    },
    fileDrop: {
      border: `1px solid ${grey}`,
      display: 'flex',
      flexGrow: 1,
    },
    imageIcon: {
      display: 'flex',
      height: '100%',
    },
    iconRoot: {
      textAlign: 'center',
      border: '1px solid red',
    },
  })
);

export const FlexRow = styled.div<{
  marginTop?: number;
  marginBottom?: number;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

export const Title = styled.div`
  width: fit-content;
  font-size: 31px;
  font-family: ${fonts.bold};
  color: black;
  text-transform: uppercase;

  span {
    color: #00b8fc;
  }
`;

export const InputWrapper = styled.div<{
  width?: string;
  align?: string;
  justify?: string;
}>`
  position: relative;
  width: ${(props) => props.width || '100%'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'space-between'};
  align-items: ${(props) => props.align || 'flex-start'};
`;
