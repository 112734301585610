/**
 *
 * SummaryTabs
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fonts, constants, mediaQueries } from '../../utils/styledTheme';

const { iPad } = constants.mediaQueries;

export const TabWrapper = styled.div`
  background: white;
  margin-top: 69px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 129px;
`;

export const Tabs = styled.div`
  width: ${iPad}px;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  ${mediaQueries.iPadPro} {
    width: fit-content;
  }
`;

export const Tab = styled(motion.div)<{ background?: string }>`
  width: 309px;
  height: 100%;
  background: ${(props) => props.background || '#fff'};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: ${fonts.bold};
  color: black;
  cursor: pointer;
`;
