import { FC, Fragment } from 'react';
import { colors } from '../../utils/styledTheme';
import { MenuWrapper, Text, DocumentImg, CloseMenuBtn, Title } from './styles';

import Pdf from './pdf';

const Menu: FC<MenuProps> = ({
  gridRow,
  isOpen,
  onClose,
  brick,
  editMode,
  type,
}) => {
  const checkForPdf = (str: string) => {
    const lastThree = str.substr(str.length - 3);
    return lastThree === 'pdf' ? true : false;
  };

  const checkForUrl = (url: string) => {
    if (url) {
      return true;
    }

    return false;
  };

  const Document = () => (
    <Fragment>
      {checkForPdf(brick?.fileUrl) ? (
        <Pdf file={brick.fileUrl} />
      ) : (
        <DocumentImg src={brick?.fileUrl} alt={brick?.fileUrl} />
      )}
    </Fragment>
  );

  const returnContents = () => {
    if (checkForUrl(brick.fileUrl)) return <Document />;
    if (checkForUrl(brick.fileUrl) && checkForUrl(brick.url))
      return <Document />;
    if (brick.text && !brick?.fileUrl && !brick?.url)
      return <Text>{brick.text}</Text>;
  };

  if (!isOpen) {
    return null;
  }

  if (type !== 'CMS' && (!isOpen || editMode)) {
    return null;
  }

  const returnDisplay = () => {
    if (checkForUrl(brick.url) && !checkForUrl(brick.fileUrl)) {
      return 'none';
    } else return 'display';
  };

  return (
    <MenuWrapper gridRow={gridRow} display={returnDisplay()}>
      <CloseMenuBtn onClick={() => onClose()} />
      <Title color={colors.lightBlue} marginTop='58px' whiteSpace='nowrap'>
        {brick?.name}
      </Title>
      {returnContents()}
    </MenuWrapper>
  );
};

export default Menu;
