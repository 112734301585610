/**
 *
 * SummaryActivity
 *
 */

import { FC, useState, useEffect } from 'react';
import { Props } from './types';
import { StyleWrapper } from './styles';

import Slider from '../Slider';
import Container from '../Container';

const questionOne =
  'Are you confident that you have all the information you need? ';

const questionTwo =
  'Are you confident the right information for the rest of your buying group is included? ';

const SummaryActivity: FC<any> = ({ proposal }) => {
  return (
    <StyleWrapper>
      <Container background='white'>
        <Slider
          question={questionOne}
          marginTop={129}
          value={proposal?.informationConfidence}
        />

        <Slider
          question={questionTwo}
          marginTop={80}
          value={proposal?.buyingGroupConfidence}
        />
      </Container>
    </StyleWrapper>
  );
};

export default SummaryActivity;
