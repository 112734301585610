import React, { useState } from 'react';
import { useStyles } from './styles';
import { Grid, InputLabel } from '@material-ui/core';
import ProposalSchema, {
  initialValues,
  CreateProposalInterface,
} from './formik';
import { Form, Formik, FormikProps } from 'formik';
import TextField from '../../FormFields/TextField';
import Button from '@material-ui/core/Button';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';
import Asynchronous from '../../FormFields/AsyncSelectField';

import { useMutation, useQuery } from '@apollo/client';
import { CreateProposal } from '../../../utils/gql/proposals/__generated__/CreateProposal';
import { CREATE_PROPOSAL } from '../../../utils/gql/proposals/createProposalMutation';
import useNavigate from '../../../utils/hooks/useNavigate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SEARCH_ORGANISATIONS } from '../../../utils/gql/organisations/searchOrganisationsQuery';
import { SearchOrganisastions } from '../../../utils/gql/organisations/__generated__/SearchOrganisastions';
import MultiSelect from '../../MultiSelect';

import { useSnackbar } from 'notistack';

import ButtonHover from '../../ButtonHover/index';
import { FlexRow, Title, InputWrapper } from './styles';
import CreateOrganisationForm from '../CreateOrganisation';
import useOrganisationState from '../../../containers/OrganisationsContainer/useOrganisationState';

const CreateProposalForm = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [createProposal, { data, loading, error }] =
    useMutation<CreateProposal>(CREATE_PROPOSAL);
  const navigate = useNavigate();
  const [orgOptions, setOrgOptions] = useState<any>([]);
  const [orgModal, setOrgModal] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    data: orgsData,
    loading: orgsLoading,
    error: orgsErrors,
    refetch: orgsRefetch,
  } = useQuery<SearchOrganisastions>(SEARCH_ORGANISATIONS, {
    variables: { input: { name: '', skip: 0, take: 20 } },
  });

  const handleSubmit = (values: CreateProposalInterface) => {
    const input = {
      name: values.name,
      // we infer the title key for the first title state from the name. After this, it can be edited in
      title: values.name,
      customerFirstName: values.customerFirstName,
      organisationId: values.organisation[0]?.value,
      customerLastName: values.customerLastName,
      customerEmail: `${values?.emailPrefix}${values?.emailSuffix?.label}`,
      ...(values?.organisation?.length > 0 && {
        organisationId: values?.organisation?.[0]?.value,
      }),
    };

    createProposal({ variables: { input } })
      .then((response) => {
        // @ts-ignore
        navigate.proposalsView({
          params: { id: response.data?.createProposal?.proposal?.id },
        });
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  };

  const orgKeyStrokeCallback = async (value: string) => {
    orgsRefetch({ input: { name: value, skip: 0, take: 100 } })
      .then((result) => {
        setOrgOptions(result?.data?.searchOrganisations || []);
      })
      .catch((err) => console.log('org options error', err));
  };

  // @@TODO - get the current value of the organisation field, and build the options array from it
  // @@TODO - pass this to the multiselect prop for options

  const getDomainOptions = (id: string | undefined) => {
    if (!id) return [];

    const found = orgsData?.searchOrganisations?.find(
      (org) => org?.value === id
    );

    if (!!found && !!found?.domains) {
      return found?.domains.map((domain) => {
        return {
          ...domain,
          label: '@' + domain?.label,
        };
      });
    }

    return [];
  };

  const {
    createOrganisationFormik,
    selectedModal,
    setCreateModal,
    createOrganisationMutation,
    resetAllModals,
  } = useOrganisationState();

  const { createModal }: any = selectedModal;

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // @ts-ignore
        validationSchema={ProposalSchema}
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          errors,
          touched,
          setFieldValue,
        }: FormikProps<CreateProposalInterface>) => (
          <Form autoComplete='off' className={classes.formStyle}>
            <Grid container style={{ width: '100%' }}>
              <TextField
                name='name'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                marginTop='10px'
                placeholder={intl.formatMessage(messages.proposalProjectName)}
                padding='0 2.5%'
              />

              <FlexRow marginTop={50} marginBottom={38}>
                <Title>
                  <span>
                    <FormattedMessage {...messages.step1} />
                  </span>
                  <FormattedMessage {...messages.clientDetails} />
                </Title>
              </FlexRow>

              <FlexRow>
                <TextField
                  name='customerFirstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.customerFirstName}
                  placeholder={intl.formatMessage(messages.firstName)}
                  width='49%'
                  padding='0 2.5%'
                />

                <TextField
                  name='customerLastName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.customerLastName}
                  placeholder={intl.formatMessage(messages.lastName)}
                  width='49%'
                  padding='0 2.5%'
                />
              </FlexRow>

              <FlexRow marginTop={38}>
                <Asynchronous
                  name={'organisation'}
                  placeholder={intl.formatMessage(
                    messages.pleaseSelectOrganisation
                  )}
                  searchTermCallback={orgKeyStrokeCallback}
                  options={orgOptions}
                  multiple={true}
                  limit={1}
                  clearable={false}
                  isLoading={orgsLoading}
                />
                <ButtonHover
                  onClick={() => setCreateModal()}
                  color='black'
                  label={intl.formatMessage(messages.createNew)}
                  height='72px'
                />
              </FlexRow>

              <FlexRow marginTop={38}>
                <InputWrapper>
                  <InputLabel className={classes.inputLabel}>
                    Customer Email Prefix
                  </InputLabel>
                  <TextField
                    name='emailPrefix'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.emailPrefix}
                    placeholder={intl.formatMessage(messages.customerEmail)}
                    width='100%'
                    padding='0 2.5%'
                  />
                </InputWrapper>
                <span
                  style={{
                    fontSize: 28,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#707070',
                    fontWeight: 'bold',
                    marginTop: '0.75em',
                    width: 'auto',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                  }}
                >
                  @
                </span>
                <InputWrapper>
                  <InputLabel className={classes.inputLabel}>
                    Organisation Email Domain
                  </InputLabel>
                  <MultiSelect
                    name='emailSuffix'
                    // @ts-ignore
                    options={getDomainOptions(values?.organisation?.[0]?.value)}
                    addNew={false}
                    placeholder={intl.formatMessage(messages.emailDomain)}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    width={100}
                  />
                </InputWrapper>
              </FlexRow>
              <Grid item xs={12} container justifyContent={'center'}>
                <Button
                  type='submit'
                  disabled={!isValid}
                  style={{ marginBottom: '88px', marginTop: '72px' }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <FormattedMessage {...messages.nextStep} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <CreateOrganisationForm
        organisation={null}
        create
        formik={createOrganisationFormik}
        isOpen={createModal}
        mutation={() => createOrganisationMutation('')}
        onRequestClose={() => resetAllModals()}
        type='organisation'
      />
    </React.Fragment>
  );
};

export default CreateProposalForm;
