/**
 *
 * ButtonHover
 *
 */

import { useState, FC } from 'react';
import { Props } from './types';
import { Button } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonHover: FC<Props> = ({ ...props }) => {
  const { loading, label, color, disabled } = props;

  const returnBackground = (color: string) => {
    if (disabled) return;

    if (color === 'black' && isHovered) {
      return '#fff';
    }

    if (color === 'black' && !isHovered) {
      return '#2E3132';
    }

    if (color === 'white' && isHovered) {
      return '#F2F2F2';
    }

    if (color === 'white' && !isHovered) {
      return '#fff';
    }
  };

  const returnColor = (color: string) => {
    if (disabled) return '#999';

    if (color === 'black' && isHovered) {
      return '#2E3132';
    }

    if (color === 'black' && !isHovered) {
      return '#fff';
    }

    if (color === 'white') {
      return '#2E3132';
    }
  };

  const [isHovered, setHovered] = useState(false);

  return (
    <Button
      type='submit'
      {...props}
      animate={{
        backgroundColor: returnBackground(color),
        color: returnColor(color),
        transition: { duration: 0.3 },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      background={color === 'black' ? '#2E3132' : '#fff'}
      fontSize={props.fontSize && props.fontSize}
    >
      {!!loading ? <CircularProgress size={24} color={'primary'} /> : label}
    </Button>
  );
};

export default ButtonHover;
