/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.components.Footer.header",
    defaultMessage: "© 2021 Logitech. All rights reserved",
  },
});
