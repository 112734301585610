import { gql } from "@apollo/client";

export const EDIT_PROPOSAL = gql`
    mutation EditProposal($input: EditProposalRequestInput!) {
        editProposal(input: $input) {
            proposal{
                id
                name
                title
                intro
                description
                mainImageUrl
                footerImageUrl
                customerId
                customerFullName
                status
                type
                status
                createdDate
                updatedDate
            }
        }
    }
`;
