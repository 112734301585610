/**
 *
 * LoginModal
 *
 */
// @ts-nocheck
import { FC } from 'react';
import { Formik } from 'formik';

// comps
import Logo from '../Logo';
import Modal from 'react-modal';
import { useHistory } from 'react-router';

// styles
import {
  FormStyled,
  Label,
  Input,
  Submit,
  customStylesPasswordReset,
} from './styles';

import { colors } from '../../utils/styledTheme';

// graphql
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../../utils/gql/users/forgotPasswordMutation';

import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

const initialValues: { email: string } = {
  email: '',
};

const PasswordResetModal: FC<{ isOpen: boolean; onRequestClose: Function }> = ({
  isOpen,
  onRequestClose,
}) => {
  const intl = useIntl();
  let history = useHistory();

  const [forgotPassword, { data, loading, error }] =
    useMutation<any>(FORGOT_PASSWORD);

  return (
    <Modal
      isOpen={isOpen}
      style={customStylesPasswordReset}
      onRequestClose={() =>
        onRequestClose({ loginModal: true, resetPassword: false })
      }
    >
      <Logo marginTop='90px' />
      <Label>
        <FormattedMessage {...messages.passwordLabel} />
      </Label>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          forgotPassword({
            variables: {
              input: {
                email: values.email,
              },
            },
          }).then((data) => {
            history.push('/');
          });
        }}
      >
        <FormStyled>
          <Input
            id='email'
            type='email'
            name='email'
            placeholder='example@logitech.com'
          />

          <Submit
            type='submit'
            value={intl.formatMessage(messages.resetPassword)}
            background={colors.lightBlue}
            color='black'
            style={{ marginTop: '35px' }}
          />
        </FormStyled>
      </Formik>
    </Modal>
  );
};

export default PasswordResetModal;
