/**
 *
 * Background
 *
 */

import React from 'react';
import { Props } from './types';

import { StyleWrapper, Container, Title, Text } from './styles';

import ButtonHover from '../ButtonHover';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { useQuery } from '@apollo/client';
import { WHAT_ROLE_AM_I } from '../../utils/gql/login/whatRoleAmIQuery';

const Background = ({ imageUrl, actionButtonClick }: Props) => {
  const intl = useIntl();
  const { data, loading } = useQuery(WHAT_ROLE_AM_I, {
    fetchPolicy: 'network-only',
  });

  const roleName = data?.userPermissions?.roleName || 'Customer';
  const isCustomer = data?.userPermissions?.roleName === 'Customer';

  return (
    <StyleWrapper imageUrl={imageUrl}>
      <Container marginTop='84px'>
        <Title>
          <FormattedMessage {...messages.welcome} />
        </Title>

        <Title>
          <FormattedMessage {...messages.sales} />
          <span>
            <FormattedMessage {...messages.view} />
          </span>
        </Title>
        {!isCustomer && !loading && (
          <Text marginTop='57px'>
            Customer proposal management like a boss!
          </Text>
        )}
        {/* @@TODO internationalise this*/}
        <ButtonHover
          color='black'
          marginTop='50px'
          onClick={actionButtonClick}
          label={
            roleName === 'Customer'
              ? intl.formatMessage(messages.viewProposals)
              : intl.formatMessage(messages.createAProposal)
          }
          loading={loading}
        />
      </Container>
    </StyleWrapper>
  );
};

export default Background;
