/**
 *
 * SubHeader
 *
 */
// @ts-nocheck
import { FC, Fragment } from 'react';
import SubHeaderIpad from '../SubHeaderIpad';
import SubHeaderDesktop from '../SubHeaderDesktop';
import { schema } from '../../routes';
import { useLocation } from 'react-router-dom';

import usePermissions from '../../utils/hooks/usePermissions';

const SubHeader: FC = () => {
  const { permissions, currentRouteSchema } = usePermissions();
  const location = useLocation();

  const returnAuthorisedRoutes = (schema: any) => {
    return schema().filter(
      (route: any) =>
        route?.nav?.moduleKey && permissions?.[route?.nav?.moduleKey]?.hasModule
    );
  };

  return (
    <Fragment>
      <SubHeaderIpad
        currentRouteSchema={currentRouteSchema}
        authorisedRoutes={returnAuthorisedRoutes(schema)}
        permissions={permissions}
      />
      <SubHeaderDesktop
        currentRouteSchema={currentRouteSchema}
        authorisedRoutes={returnAuthorisedRoutes(schema)}
        location={location}
        permissions={permissions}
      />
    </Fragment>
  );
};

export default SubHeader;
