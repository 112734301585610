import { gql } from '@apollo/client';

export const SEARCH_CLIENTS = gql`
  query SearchClients($input: SearchCustomersRequestInput) {
    searchCustomers(input: $input) {
      id
      email
      firstName
      lastName
      jobTitle
      passwordHash
      createdDate
      phoneNumber
      status
      organisation {
        name
      }
    }
  }
`;
