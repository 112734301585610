import { StateInterface, ACTIONTYPE } from './types';

const proposalTableReducer = (state: StateInterface, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'RESET_ALL_MODALS':
      return {
        selectedProposal: null,
        selectedModal: {
          deleteModal: false,
          snapShotModal: false,
          shareModal: false,
        },
        goToEditProposalPage: false,
        goToSummaryPage: false,
      };

    case 'GO_TO_EDIT_PROPOSAL':
      return {
        ...state,
        selectedProposal: action.payload.selectedProposal,
        selectedModal: {
          deleteModal: false,
          snapShotModal: false,
          shareModal: false,
        },
        goToEditProposalPage: true,

        goToSummaryPage: false,
      };

    case 'SET_DELETE_MODAL':
      return {
        ...state,
        selectedProposal: action.payload.selectedProposal,
        selectedModal: {
          deleteModal: true,
          snapShotModal: false,
          shareModal: false,
        },
        goToEditProposalPage: false,
        goToSummaryPage: false,
      };

    case 'SET_SNAPSHOT_MODAL':
      return {
        ...state,
        selectedProposal: action.payload.selectedProposal,
        selectedModal: {
          deleteModal: false,
          snapShotModal: true,
          shareModal: false,
        },
        goToEditProposalPage: false,
        goToSummaryPage: false,
      };

    case 'SET_SHARE_MODAL':
      return {
        ...state,
        selectedProposal: action.payload.selectedProposal,
        selectedModal: {
          deleteModal: false,
          snapShotModal: false,
          shareModal: true,
        },
        goToEditProposalPage: false,
        goToSummaryPage: false,
      };

    case 'SET_USER_IDS':
      return {
        ...state,
        userIds: action.payload,

        goToEditProposalPage: false,
        goToSummaryPage: false,
      };

    case 'GO_TO_SUMMARY':
      return {
        ...state,
        selectedProposal: action.payload.selectedProposal,
        selectedModal: {
          deleteModal: false,
          snapShotModal: false,
          shareModal: false,
        },
        goToEditProposalPage: false,
        goToSummaryPage: true,
      };

    default:
      return state;
  }
};

export default proposalTableReducer;
