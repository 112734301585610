/**
 *
 * HardwareComponents
 *
 */

import styled from 'styled-components';

export const grey = '#E1E2E3';
export const fontColor = '#000000';

export const StyleWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
  padding-right: 5rem;
`;

export const Title = styled.h3`
  text-transform: uppercase;
  color: #00b8fc;
  font-family: 'BrownProTTBold';
  font-size: 31px;
`;
export const DisplayTitle = styled.h4`
  margin: 0;
  margin-left: 0.5rem;
  text-align: left;
  font-family: 'BrownProTTBold';
`;
export const DisplayItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 330px;
  margin-left: 0.5rem;
  margin-right: 2rem;
`;

export const ShopItemImg = styled.img`
  width: 100%;
  object-fit: contain;
  height: 240px;
`;
export const CheckBoxWrapper = styled.div`
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const CheckBox = styled.input<{ readOnly?: boolean }>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid ${grey};
  background: white;
  cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg id='Group_24749' data-name='Group 24749' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Crect id='Rectangle_18' data-name='Rectangle 18' width='28' height='28' rx='5' fill='%2300b8fc'/%3E%3Cpath id='Path_3' data-name='Path 3' d='M5.36,14l6.5,5.869L22.437,6.475' fill='none' stroke='%23fff' stroke-width='4'/%3E%3C/svg%3E%0A");
    border: 0px solid ${grey};
  }
`;

export const Text = styled.div`
  font-size: 20px;
  margin-left: 3.6%;
  font-family: 'BrownProTTLight';
  color: ${fontColor};

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ShopItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 1.25rem;
`;
export const InfoWrapper = styled.div`
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 24px;
  font-size: 16px;
  font-family: 'BrownProTTLight';
  color: ${fontColor};

  .MuiSvgIcon-root {
    padding-top: calc(0.125rem + 1px);
  }

  &:hover {
    color: #00b8fc;
  }
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${fontColor} !important;
  text-decoration: none;
  &:hover {
    color: #00b8fc !important;
  }
`;
