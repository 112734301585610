import { gql } from '@apollo/client';

export const ADD_EQUIPMENT_TO_ROOM = gql`
  mutation AddEquipmentToRoom($input: AddEquipmentToRoomRequestInput!) {
    addEquipmentToRoom(input: $input) {
      room {
        id
        equipment {
          id
          name
          equipmentCategoryId
          isSelected
          collateral {
            id
            url
            fileUrl
            collateralTypeId
            name
            equipmentId
          }
        }
      }
    }
  }
`;
