/**
 *
 * Input
 *
 */

import { FC, Fragment } from 'react';
import { Props } from './types';
import {
  InputWrapper,
  Label,
  TextInput,
  EmailInputStyled,
  Error,
  SearchLogo,
} from './styles';

const NormalInput: FC<Props> = ({ label, formik, ...props }) => (
  <InputWrapper {...props}>
    <Label>{label}</Label>
    <TextInput {...props} />

    {formik.errors && formik.errors[props.name] && (
      <Error>
        {formik.errors[props.name] ? formik.errors[props.name] : null}
      </Error>
    )}
  </InputWrapper>
);

const EmailInput: FC<Props> = ({ label, defaultValue, formik, ...props }) => {
  const { value } = props;

  const green = value === defaultValue ? true : false;

  return (
    <InputWrapper {...props}>
      <Label>{label}</Label>
      <EmailInputStyled green={green} {...props} />

      {formik.errors[props.name] && (
        <Error>
          {formik.errors[props.name] ? formik.errors[props.name] : null}
        </Error>
      )}
    </InputWrapper>
  );
};

const SearchInput: FC<Props> = ({ label, ...props }) => (
  <InputWrapper {...props}>
    <Label>{label}</Label>
    <TextInput type='text' background={SearchLogo} {...props} />
  </InputWrapper>
);

const Input: FC<Props> = ({ label, defaultValue, formik, ...props }) => (
  <Fragment>
    {/* email input with green tick */}
    {props.type === 'email' && (
      <EmailInput
        label={label}
        defaultValue={defaultValue}
        formik={formik}
        {...props}
      />
    )}

    {/* normal input */}
    {props.type === 'text' && (
      <NormalInput label={label} formik={formik} {...props} />
    )}

    {/* normal input */}
    {props.type === 'tel' && (
      <NormalInput label={label} formik={formik} {...props} />
    )}

    {/* normal input */}
    {props.type === 'search' && <SearchInput label={label} {...props} />}
  </Fragment>
);

export default Input;
