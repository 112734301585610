/**
 *
 * Input
 *
 */

import styled from 'styled-components';

import { colors, fonts } from '../../utils/styledTheme';

export const TickUrl = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26.87' height='19.799' viewBox='0 0 26.87 19.799'%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(16.971 -7.071) rotate(45)'%3E%3Crect id='Rectangle_13' data-name='Rectangle 13' width='4' height='24' transform='translate(10 0)' fill='%2311b10a'/%3E%3Crect id='Rectangle_14' data-name='Rectangle 14' width='4' height='14' transform='translate(0 24) rotate(-90)' fill='%2311b10a'/%3E%3C/g%3E%3C/svg%3E")`;

export const InputWrapper = styled.div<{
  width?: string;
  minHeight?: string;
  marginTop?: string;
}>`
  position: relative;
  width: ${(props) => props.width || '100%'};
  min-height: ${(props) => props.minHeight || '106px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || '30px'};
  max-height: 106px;
`;

export const TextInput = styled.input<{
  padding?: string;
  background?: string;
  backgroundColor?: string;
  color?: string;
  noBorder?: boolean;
  cursor?: string;
}>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  max-height: 72px;
  border-radius: 5px;
  border: 2px solid #e1e2e3;

  padding: ${(props) => props.padding};
  flex: 1;

  background-image: ${(props) => props.background};
  cursor: ${(props) => props.cursor};
  background-position: right 35px top 50%;
  background-repeat: no-repeat;
  background: ${(props) => props.backgroundColor || 'white'};
  color: ${(props) => props.color || colors.fontColor};

  &:focus {
    border: ${(props) => (props.noBorder ? null : `3px solid ${colors.blue}`)};
  }

  &:disabled {
    background: transparent;
  }
`;

export const Label = styled.div<{ marginTop?: string }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop};
   font-weight: bold;
 `;

export const EmailInputStyled = styled.input<{
  padding?: string;
  green?: boolean;
  cursor?: string;
  backgroundColor?: string;
  noBorder?: boolean;
}>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  border-radius: 5px;
  border: 2px solid #e1e2e3;
  color: ${colors.fontColor};
  padding: ${(props) => props.padding};
  flex: 1;
  background-position: right 22px top 50%;
  background-repeat: no-repeat;
  background-image: ${(props) => (props.green ? TickUrl : null)};

  flex-shrink: 0;
  cursor: ${(props) => props.cursor};
  background-color: ${(props) => props.backgroundColor || 'white'};

  &:focus {
    border: ${(props) => (props.noBorder ? null : `3px solid ${colors.blue}`)};
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  color: red;
`;

export const SearchLogo = `url("data:image/svg+xml,%3Csvg id='Group_17' data-name='Group 17' xmlns='http://www.w3.org/2000/svg' width='19.707' height='18.707' viewBox='0 0 19.707 18.707'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23393b3a' stroke-width='2'%3E%3Ccircle cx='7' cy='7' r='7' stroke='none'/%3E%3Ccircle cx='7' cy='7' r='6' fill='none'/%3E%3C/g%3E%3Cline id='Line_2' data-name='Line 2' x2='6' y2='6' transform='translate(13 12)' fill='none' stroke='%23393b3a' stroke-width='2'/%3E%3C/svg%3E%0A")`;
