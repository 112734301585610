import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import styled from 'styled-components';

export const fontColor = '#000000';
export const grey = '#E1E2E3';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    formStyle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '13px',
    },
    formTitle: {
      color: fontColor,
      fontSize: 32,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    inputLabel: {
      color: fontColor,
      fontSize: 20,
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      alignItems: 'center',
    },
    gridLine: {
      border: '1px solid red',
    },
    fileDrop: {
      border: `1px solid ${grey}`,
      display: 'flex',
      flexGrow: 1,
    },
    imageIcon: {
      display: 'flex',
      height: '100%',
    },
    iconRoot: {
      textAlign: 'center',
      border: '1px solid red',
    },
  })
);

export const WrappedImg = styled.img`
  width: 444px;
  height: 80px;
  object-fit: contain;
`;
