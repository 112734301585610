/**
 *
 * YourRoomSolutions
 *
 */

import { FC, useEffect, Fragment } from 'react';
import { Props } from './types';
import {
  StyleWrapper,
  YourRoomSolutionsContainer,
  Title,
  EditIcon,
  OverlayText,
} from './styles';

import { useIntl } from 'react-intl';

import { useAnimation } from 'framer-motion';

import imageUrl from '../../images/rooms/YourRoomSolutions.jpg';
import messages from './messages';

import { colors } from '../../utils/styledTheme';

import MeetingSpaces from '../MeetingSpaces';

import { useInteractionState } from '../../utils/hooks/useInteraction';

import { useQuery } from '@apollo/client';
import { WHO_AM_I } from '../../utils/gql/login/whoAmIQuery';
import { GET_STATS } from '../../utils/gql/clickstats/getProposalStats';
import { useParams } from 'react-router';

const YourRoomSolutions: FC<Props> = ({
  isOpen,
  setIsOpen,
  type,
  templateRooms,
  rooms,
  handleAddRoomToProposal,
  readOnly,
}) => {
  const intl = useIntl();

  const roomControls = useAnimation();
  const editControls = useAnimation();

  const { createMeetingSpaceInteraction } = useInteractionState(null);

  const open = async () => {
    await roomControls.start({ opacity: 0.5 });
    return await editControls.start({ opacity: 0 });
  };

  const close = async () => {
    await roomControls.start({ opacity: 1 });
    return await editControls.start({ opacity: 1 });
  };

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const returnBackgroundColor = (type: BRICKTYPE) => {
    if (type === 'DEFAULT') return colors.offWhite;
    if (type === 'PREVIEW') return colors.normalWhite;
    if (type === 'CLICK_STATS') return colors.normalWhite;
  };

  const createInteraction = () => {
    setIsOpen(!isOpen);
    if (readOnly) {
      createMeetingSpaceInteraction();
    }
  };

  const { data: userData } = useQuery(WHO_AM_I);
  const { id: paramsId }: any = useParams();

  const { data } = useQuery(GET_STATS, { variables: { id: paramsId } });

  const stats = data && data?.proposalStats;

  const propStats = stats && stats?.proposalStats;

  const componentStats = propStats?.componentStats;

  const canCreateInteraction = userData?.permissions?.roleName === 'Customer';

  const hasInteractions = () => {
    if (
      componentStats &&
      componentStats?.find((component: any) => component.entityId === 5)
        ?.interactions > 0
    )
      return true;
    return false;
  };

  return (
    <Fragment>
      <StyleWrapper background={returnBackgroundColor(type)}>
        <YourRoomSolutionsContainer
          animate={roomControls}
          imageUrl={imageUrl}
          onClick={() => createInteraction()}
          overlay={type === 'CLICK_STATS' && hasInteractions() ? true : false}
        >
          {type === 'CLICK_STATS' ? null : <EditIcon animate={editControls} />}
          <Title>{intl.formatMessage(messages.yourRoomSolutions)}</Title>
          {type === 'CLICK_STATS' && hasInteractions() && (
            <OverlayText>
              {`${
                componentStats &&
                componentStats?.find(
                  (component: any) => component.entityId === 5
                )?.interactions
              } CLICK(S)`}
            </OverlayText>
          )}
        </YourRoomSolutionsContainer>
      </StyleWrapper>

      {isOpen && (
        <MeetingSpaces
          rooms={rooms}
          templateRooms={templateRooms}
          handleAddRoomToProposal={handleAddRoomToProposal}
          type={type}
          readOnly={readOnly}
        />
      )}
    </Fragment>
  );
};

export default YourRoomSolutions;
