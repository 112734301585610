/**
 *
 * SummaryData
 *
 */

import React, { FC } from 'react';
import { Props, SquareProps } from './types';
import { Header, Title, FlexRow, FlexItem, Value } from './styles';

import Container from '../Container';

import { useQuery } from '@apollo/client';
import { GET_STATS } from '../../utils/gql/clickstats/getProposalStats';
import { useParams } from 'react-router';
import { CircularProgress } from '@material-ui/core';

const SummaryData: FC<Props> = ({ proposal }) => {
  const Square: FC<SquareProps> = ({ title, value, loading }) => (
    <FlexItem>
      <Title>{title}</Title>
      {!!loading && <CircularProgress size={24} color={'primary'} />}
      {!loading && <Value>{value}</Value>}
    </FlexItem>
  );

  const { id }: any = useParams();

  const { data, loading } = useQuery(GET_STATS, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const proposalStats = data?.proposalStats?.proposalStats;

  const formatViewTime = () => {
    if (proposalStats) {
      return proposalStats?.averageViewTime?.substr(3, 5);
    }
  };

  return (
    <Container>
      <Header>{proposal?.title}</Header>
      <FlexRow marginTop={35}>
        <Square
          title='UNIQUE VISITORS'
          value={proposalStats && proposalStats?.uniqueVisitors}
          loading={loading}
        />
        <Square
          title='AVERAGE VIEW TIME'
          value={proposalStats && formatViewTime()}
          loading={loading}
        />
        <Square
          title='AVERAGE LINKS CLICKED'
          value={proposalStats && proposalStats?.averageLinksClicked}
          loading={loading}
        />
      </FlexRow>
    </Container>
  );
};

export default SummaryData;
