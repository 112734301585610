/**
 *
 * CollModal
 *
 */

import { FC, Fragment, useEffect, useState } from 'react';
import { T } from './types';
import { StyleWrapper, Form, Container, Title, Flex } from './styles';

// constants

import Modal from 'react-modal';
import Input from '../../components/Input';
import MultiSelect from '../MultiSelect';
import ButtonHover from '../ButtonHover';
import MyDropzone from '../DropzoneNoBackground';

const customStyles = {
  content: {
    width: '65%',
    paddingLeft: '55px',
    paddingRight: '55px',
    borderRadius: '6px',
    paddingBottom: '55px',
    background: '#f7f7f7',
    onset: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    onset: 0,
    zIndex: 1,
  },
};

const collateralTypes = [
  {
    label: 'Product Literature',
    value: '0',
  },
  { label: 'Data Sheet', value: '1' },
  {
    label: 'Product Videos',
    value: '2',
  },
  {
    label: 'Wiring Diagram',
    value: '3',
  },
];

const CollModal: FC<T> = ({
  isOpen,
  onRequestClose,
  formik,
  title,
  mutation,
  selectedCollateral,
}) => {
  const isValid = () => {
    const { collateralTypeId, url, file, name } = formik?.values;
    if (collateralTypeId?.value && name && file) {
      return true;
    }
    if (collateralTypeId?.value && name && url) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
    >
      <Container>
        <Title>{title}</Title>

        <Form onSubmit={formik.handleSubmit}>
          <Input
            label='Collateral Name'
            id='name'
            name='name'
            type='text'
            value={formik?.values?.name}
            onChange={formik?.handleChange}
            width='100%'
            placeholder=''
            padding='0 2.5%'
            formik={formik}
            backgroundColor='#FFF'
          />

          <MultiSelect
            options={collateralTypes}
            setFieldValue={formik?.setFieldValue}
            name='collateralTypeId'
            renderIcon
            placeholder='Technology Type'
            errors={formik?.errors}
            value={formik?.values?.collateralTypeId}
            label='Collateral Type'
            padding='0 5%'
            width={100}
            marginTop={15}
          />

          <Flex alignItems='flex-end'>
            <Input
              label='URL'
              id='url'
              name='url'
              type='text'
              value={formik?.values?.url}
              onChange={formik?.handleChange}
              width='48%'
              placeholder='https://'
              padding='0 5%'
              formik={formik}
              backgroundColor='#FFF'
              noBorder={true}
              disabled={formik?.values?.file}
            />

            <MyDropzone
              formik={formik}
              item={selectedCollateral ? selectedCollateral : null}
            />
          </Flex>

          <Flex marginTop={24} justify='flex-end'>
            <ButtonHover
              label='CANCEL'
              color='white'
              onClick={() => onRequestClose()}
            />
            <ButtonHover
              label='SAVE'
              color='black'
              marginLeft='15px'
              disabled={!isValid()}
              onClick={() => mutation()}
            />
          </Flex>
        </Form>
      </Container>
    </Modal>
  );
};

export default CollModal;
