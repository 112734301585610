import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ProposalBricks from './ProposalGrid';

const DefaultProvider: React.FC<ProposalGridProps> = ({
  bricks,
  refetchBricks,
  type,
  editMode,
  proposalId,
  setAddBrick,
}) => (
  <DndProvider backend={HTML5Backend}>
    {bricks && (
      <ProposalBricks
        bricks={bricks}
        refetchBricks={refetchBricks}
        type={type}
        editMode={editMode}
        proposalId={proposalId}
        setAddBrick={setAddBrick}
      />
    )}
  </DndProvider>
);

export default DefaultProvider;
