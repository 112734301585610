/**
 *
 * FileDrop
 *
 */

import React, { useEffect } from 'react';
import { FormikProps, FormikValues, useFormikContext } from 'formik';
import {
  FiledropLabelWrapper,
  FiledropLabel,
  FiledropWrapper,
  FileInput,
  ImgPreview,
  Label,
  Wrapper,
} from './styles';
import { CustomFieldProps, Props } from './types';
import { ReactComponent as UploadIcon } from '../../images/svg/UploadIcon.svg';
import { SvgIcon } from '@material-ui/core';

function FileDrop(props: CustomFieldProps) {
  const { values, setFieldValue }: FormikProps<FormikValues> =
    useFormikContext();

  const checkVariable = values[props?.name];

  useEffect(() => {
    const reader: any = new FileReader();
    reader.onloadend = () => {
      setFieldValue('imgPreview', reader.result);
      setFieldValue('imageUrl', reader.result.split(',')[1]);
    };
    if (props.enablePreview) {
      checkVariable && reader.readAsDataURL(checkVariable);
    }
    setFieldValue('logoImageName', values?.[checkVariable]?.name);
  }, [checkVariable]);

  const { enablePreview, ...rest } = props;

  return (
    <Wrapper width={props.width} marginTop={props.marginTop}>
      <label htmlFor={props.htmlFor}>
        {props.label && <Label>{props.label}</Label>}
        <FiledropWrapper height={props.height}>
          <FileInput
            {...rest}
            value={undefined}
            accept='image/*'
            type='file'
            id={props.id}
          />
          {values.imgPreview && enablePreview ? (
            <ImgPreview src={values.imgPreview} />
          ) : (
            <FiledropLabelWrapper>
              <SvgIcon component={UploadIcon} viewBox='0 0 48 48' />
              <FiledropLabel>{props.imagePlaceholder}</FiledropLabel>
            </FiledropLabelWrapper>
          )}
        </FiledropWrapper>
      </label>
    </Wrapper>
  );
}

export default FileDrop;
