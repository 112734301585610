/**
 *
 * CreateProposalContainer
 *
 */
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import Container from '../../components/Container';
import TableHeader from '../../components/TableHeader';
import CreateProposalForm from '../../components/Forms/CreateProposal';

const CreateProposalContainer: FC = () => {
  return (
    <Container desktopWidth={1242}>
      <TableHeader marginTop={50}>
        <FormattedMessage {...messages.header} />
      </TableHeader>
      <CreateProposalForm />
    </Container>
  );
};

export default CreateProposalContainer;
