/**
 *
 * ProposalSuccessContainer
 *
 */
import { FC } from 'react';

import {
  StyleWrapper,
  Title,
  Container,
  Success,
  Img,
  imageUrl,
  Th,
} from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

import ButtonHover from '../../components/ButtonHover';
import useNavigate from '../../utils/hooks/useNavigate';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const ProposalSuccessContainer: FC = () => {
  const navigate = useNavigate();
  const history = useHistory();
  const intl = useIntl();
  const { id }: any = useParams();

  return (
    <StyleWrapper>
      <Container>
        <Title>
          <FormattedMessage {...messages.congratulations} />
        </Title>

        <table>
          <tr>
            <Th>
              <Success>
                {intl.formatMessage(messages.submissionSuccessful)}
              </Success>
              <ButtonHover
                label={intl.formatMessage(messages.createNewProposal)}
                marginLeft='0'
                onClick={() => navigate.proposalsCreate()}
                color='black'
              />
              <ButtonHover
                label={intl.formatMessage(messages.viewProposal)}
                marginTop='30px'
                marginLeft='0'
                color='black'
                onClick={() =>
                  history.push(`/proposals/summary-of-activity/${id}`)
                }
              />
            </Th>
            <th>
              <Img />
            </th>
          </tr>
        </table>
      </Container>
    </StyleWrapper>
  );
};

export default ProposalSuccessContainer;
