import { gql } from '@apollo/client';

export const SHARE_PROPOSAL = gql`
  mutation ShareProposal($input: ShareProposalRequestInput!) {
    shareProposal(input: $input) {
      users {
        id
      }
    }
  }
`;
