import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_TEMPLATE_ROOMS } from '../../utils/gql/templateRooms/templateRoomsQuery';
import { ADD_ROOM_TO_PROPOSAL } from '../../utils/gql/templateRooms/addRoomToProposalMutation';
import { AddRoomToProposal } from '../../utils/gql/templateRooms/__generated__/AddRoomToProposal';
import { REMOVE_ROOM_FROM_PROPOSAL } from '../../utils/gql/templateRooms/removeRoomsFromProposalMutation';
import { RemoveRoomFromProposal } from '../../utils/gql/templateRooms/__generated__/removeRoomFromProposal';
import { CREATE_SESSION } from '../../utils/gql/clickstats/createViewingSession';

const useMeetingSpaces = () => {
  const { id } = useParams<any>();

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [createSession] = useMutation(CREATE_SESSION);

  const createViewingSession = (start: any, end: any) => {
    const input = {
      start,
      end,
      entityId: 0,
      entityTableId: id,
    };
    createSession({ variables: { input } })
      .then(() => {})
      .catch((error) => console.log(error.message));
  };

  const { data, refetch: refetchRooms } = useQuery<any>(GET_TEMPLATE_ROOMS, {
    variables: { id },
  });

  // for Thiago's carousel
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [openYourRooms, setOpenYourRooms] = useState<boolean>(false);

  const resetRooms = () => {
    refetchRooms();
    setSelectedRoom(null);
  };

  const [
    addRoomToProposal,
    { loading: addRoomLoading, data: addRoomData, error: addRoomErr },
  ] = useMutation<AddRoomToProposal>(ADD_ROOM_TO_PROPOSAL);
  const [
    removeRoomFromProposal,
    { loading: rmRoomLoading, data: rmRoomData, error: rmRoomErr },
  ] = useMutation<RemoveRoomFromProposal>(REMOVE_ROOM_FROM_PROPOSAL);

  const handleAddRoomToProposal = (roomId: string) => {
    const input = {
      proposalId: id,
      roomId,
    };
    const roomIsSelected = data?.proposal?.rooms
      ?.map((room: Record<string, any>) => room?.id)
      ?.includes(roomId);

    if (!roomIsSelected) {
      addRoomToProposal({
        variables: { input },
      })
        .then((response) => {
          refetchRooms();
        })
        .catch((error) => console.log(error));
      return;
    }
    removeRoomFromProposal({
      variables: { input },
    }).then(() => resetRooms());
  };

  return {
    templateRooms: data?.proposal?.templateRooms,
    rooms: data?.proposal?.rooms,
    handleAddRoomToProposal,

    selectedRoom,
    setSelectedRoom,

    openYourRooms,
    setOpenYourRooms,

    setStartTime,
    startTime,
    setEndTime,
    endTime,
    createViewingSession,
  };
};

export default useMeetingSpaces;
