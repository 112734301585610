/**
 *
 * SendProposalContainer
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { ContentArea } from '../../layout/ContentArea';
import styled from 'styled-components';
import SendProposalForm from '../../components/Forms/SendProposalForm';

const SendProposalContainer = () => {
  return (
    <Wrapper>
      <ContentArea>
        <Heading>
          <FormattedMessage {...messages.sendTailoredSolution} />
        </Heading>
        <SendProposalForm />
      </ContentArea>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #f7f7f7;
`;

const Heading = styled('h1')`
  font-size: 61px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
`;

export default SendProposalContainer;
