//@ts-nocheck
import { FC, useState, Fragment, ReactNode } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { PdfContainer, PdfButtons } from './styles';

const Pdf: FC<{ file: ReactNode }> = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber !== numPages) {
      setPageNumber((prevState) => prevState + 1);
    }
  };

  const prevPage = () => {
    setPageNumber((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };

  return (
    <PdfContainer className='pdf-container'>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page scale={96 / 72} pageNumber={pageNumber} />
      </Document>

      {numPages && (
        <Fragment>
          <p>
            Page {pageNumber} of {numPages}
          </p>

          <PdfButtons>
            <button onClick={() => prevPage()}>prev</button>
            <button onClick={() => nextPage()}>next</button>
          </PdfButtons>
        </Fragment>
      )}
    </PdfContainer>
  );
};

export default Pdf;
