import React, { ChangeEvent, useMemo, useState } from 'react';
import { useStyles } from './styles';
import { Grid, InputAdornment, SvgIcon } from '@material-ui/core';
import ProposalSchema, { initialValues, SearchTermInterface } from './formik';
import { Form, Formik, FormikProps } from 'formik';
import TextField from '../../FormFields/TextField';

import messages from './messages';
import { useIntl } from 'react-intl';

import { Search } from '@mui/icons-material';

export const SearchLogo = `url("data:image/svg+xml,%3Csvg id='Group_1968' data-name='Group 1968' xmlns='http://www.w3.org/2000/svg' width='27.114' height='26.858' viewBox='0 0 27.114 26.858'%3E%3Cg id='Ellipse_22' data-name='Ellipse 22' fill='%23fff' stroke='%23707070' stroke-width='3'%3E%3Ccircle cx='11.5' cy='11.5' r='11.5' stroke='none'/%3E%3Ccircle cx='11.5' cy='11.5' r='10' fill='none'/%3E%3C/g%3E%3Cg id='Group_1967' data-name='Group 1967' transform='matrix(0.695, -0.719, 0.719, 0.695, 17.862, 20.606)'%3E%3Cg id='Rectangle_972' data-name='Rectangle 972' transform='translate(0 0)' fill='%23707070' stroke='%23707070' stroke-width='1'%3E%3Crect width='4' height='9' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='3' height='8' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")`;

interface IProps {
  changeCallback?: (e: any) => void;
  placeHolder?: string;
}

const SearchTermForm = ({ changeCallback, placeHolder }: IProps) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // @ts-ignore
      validationSchema={ProposalSchema}
      validateOnMount
      onSubmit={() => {}}
      handleChange={changeCallback}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        errors,
        touched,
        setFieldValue,
      }: FormikProps<SearchTermInterface>) => (
        <Form autoComplete='off' className={classes.formStyle}>
          <TextField
            name='searchTerm'
            placeholder={
              placeHolder ? placeHolder : intl.formatMessage(messages.search)
            }
            variant='outlined'
            padding='0 55px'
            background={SearchLogo}
            onChange={(e) => {
              setFieldValue('searchTerm', e.target.value);
              // set value of field using setFieldvalue and field name
              if (changeCallback) {
                changeCallback(e);
              }
            }}
            value={values.searchTerm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchTermForm;
