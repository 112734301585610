import { FC } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  Observable,
  HttpLink,
  gql,
  NormalizedCacheObject,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';

import { cache, isLoggedInVar } from './cache';

import { getAccessToken, fetchAccessToken } from './accessToken';

import jwtDecode from 'jwt-decode';

const isDev = process.env.NODE_ENV !== 'production';
const uri = isDev ? process.env.REACT_APP_API_ENDPOINT : '#{API_URL}';

export let client: ApolloClient<NormalizedCacheObject>;

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((operation) => {
          const accessToken = getAccessToken();

          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        if (!token) {
          return true;
        }

        try {
          const { exp }: { exp: any } = jwtDecode(token);
          /* if current time is greater token is false invalid */
          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => fetchAccessToken(),

      handleFetch: (refreshAuthTokens) => {},

      handleError: (err) => {},
    }),
    onError(({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors);
      console.log(networkError);
      if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
        isLoggedInVar(false);
      }
    }),
    requestLink,
    new HttpLink({
      uri: uri,
      credentials: 'include',
    }),
  ]),
  cache,
  typeDefs,
  connectToDevTools: true,
});

const ApolloClientWrapper: FC = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default ApolloClientWrapper;
