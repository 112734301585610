/**
 *
 * HomeContainer
 *
 */
import React, { Fragment } from 'react';
import imageUrl from '../../images/homeBackground/home.jpg';
import Background from '../../components/Background/index';
import useNavigate from "../../utils/hooks/useNavigate";
import {useQuery} from "@apollo/client";
import {WHAT_ROLE_AM_I} from "../../utils/gql/login/whatRoleAmIQuery";

interface HomeContainerProps {}

const HomeContainer = ({}: HomeContainerProps) => {
    const navigate = useNavigate();
    const { data } = useQuery(WHAT_ROLE_AM_I);

    const roleName = data?.userPermissions?.roleName || 'Customer';

    const actionButtonClick = () => {
        if (roleName === 'Super Admin' || roleName === 'Admin' || roleName === 'Sales') {
         return   navigate.proposalsCreate()
        }

        return navigate.yourProposals();
    }

  return (
    <>
      <Background imageUrl={imageUrl} actionButtonClick={actionButtonClick}/>
    </>
  );
};

export default HomeContainer;
