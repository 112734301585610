/**
 *
 * SentTo
 *
 */

import styled from 'styled-components';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const SentToColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Recipient = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap !important;
`;
