// @ts-nocheck
import React from 'react';

import Table from '../Table';
import { Row } from './styles';

import Container from '../Container';
import SearchTermForm from '../Forms/SearchTermForm';
import { useTechnologyState } from './useTechnologyState';

import MultiSelect from '../MultiSelect';
import ButtonHover from '../ButtonHover';

import TechModal from '../TechModal';
import CollModal from '../CollModal';
import DeleteModal from '../DeleteModal/index';

import { ReactComponent as Edit } from '../../images/svg/EditIcon.svg';
import { ReactComponent as Delete } from '../../images/svg/DeleteIcon.svg';

import { techTypes, techIcons } from '../../constants/equipments';

const TechnologiesContainer: React.FC = () => {
  const {
    formikEquipSearch,
    searchEquipments,
    setCreateModal,
    setEditModal,
    setDeleteModal,
    resetAllModals,
    setAddCollModal,
    setEditCollateral,
    closeCollateral,
    setDeleteCollateral,
    selectedModal,
    selectedTechnology,
    createEquipFormik,
    editEquipFormik,

    createEquipmentMutation,
    editEquipmentMutation,
    deleteEquipmentMutation,

    //  coll
    collateralData,
    createCollFormik,
    editCollFormik,
    selectedCollateral,
    createCollateralMutation,
    editCollateralMutation,
    deleteCollateralMutation,
  } = useTechnologyState();

  const returnEquipments = (category: string) => {
    if (category)
      return searchEquipments?.filter(
        (el) => el.equipmentCategoryId === Number(category) && el.status !== -1
      );
    return searchEquipments?.filter((el) => el.status !== -1);
  };

  const returnCollateral = (obj: any) => {
    return obj?.collateral
      ?.filter((c) => c.status !== -1)
      .map((el) => techIcons[el.collateralTypeId]);
  };

  const returnRows = (category: any) => {
    const equipmentData = returnEquipments(category);

    let map = equipmentData?.map((obj: any) => {
      return {
        ...obj,
        type: techTypes
          .filter((i) => i.value !== null)
          .find((item) => Number(item.value) === obj.equipmentCategoryId)
          ?.label,

        suitableRooms: obj?.rooms
          ?.filter((rm: any) => rm.status !== -1)
          .map((room: any) => room.name),

        rawSuitableRooms: obj?.rooms
          ?.filter((rm: any) => rm.status !== -1)
          .map((room: any) => ({
            label: room.name,
            value: room.id,
          })),

        rawCollateral: obj?.collateral
          ?.map((col: any) => ({
            ...col,
            status: col.status,
            label: col.name,
            value: col.collateralTypeId.toString(),
          }))
          .filter((el) => el.status !== -1),

        collateral: returnCollateral(obj),
      };
    });
    return map;
  };

  return (
    <Container marginTop={50}>
      <Row marginBottom='1rem' justify='flex-end'>
        <ButtonHover
          color='black'
          label='Add new item'
          onClick={() => setCreateModal(!selectedModal?.create)}
        />
      </Row>
      <Row>
        <SearchTermForm
          changeCallback={(e) =>
            formikEquipSearch.setFieldValue('searchTerm', e.target.value)
          }
          placeHolder='Search by Name'
        />

        <MultiSelect
          options={techTypes}
          placeholder='Type'
          width={25}
          marginLeft={25}
          setFieldValue={formikEquipSearch.setFieldValue}
          name='type'
          height={72}
        />
      </Row>

      <Table
        headers={[
          'Image',
          'Name',
          'Technology Type',
          'Suitable Workspaces',
          'Collateral',
          'icons',
        ]}
        rows={returnRows(formikEquipSearch.values.type?.value)}
        colObjectKeys={[
          ['imageUrl'],
          ['name'],
          ['type'],
          ['suitableRooms'],
          ['collateral'],
          ['icons'],
        ]}
        icons={[
          {
            component: Edit,
            action: setEditModal,
          },
          {
            component: Delete,
            action: setDeleteModal,
          },
        ]}
      />
      <Row justify='flex-end'>
        <ButtonHover
          color='black'
          label='Add new item'
          onClick={() => setCreateModal(!selectedModal?.create)}
        />
      </Row>

      <TechModal
        isOpen={selectedModal?.edit}
        mutation={() => editEquipmentMutation()}
        title='EDIT ITEM'
        onRequestClose={() => resetAllModals()}
        formik={editEquipFormik}
        paddingBottom='50px'
        setAddCollModal={setAddCollModal}
        selectedTechnology={selectedTechnology}
        collateralData={collateralData}
        setEditCollateral={setEditCollateral}
        setDeleteCollateral={setDeleteCollateral}
      />

      <TechModal
        isOpen={selectedModal?.create}
        mutation={() => createEquipmentMutation()}
        title='ADD ITEM'
        onRequestClose={() => resetAllModals()}
        formik={createEquipFormik}
        paddingBottom='200px'
      />

      <DeleteModal
        isOpen={selectedModal?.delete}
        onRequestClose={() => resetAllModals()}
        mutation={() => deleteEquipmentMutation()}
        type='tech'
      />

      <CollModal
        isOpen={selectedModal?.createCollateral}
        onRequestClose={() => closeCollateral()}
        mutation={() => createCollateralMutation()}
        formik={createCollFormik}
        title='ADD COLLATERAL'
      />

      <CollModal
        isOpen={selectedModal?.editCollateral}
        onRequestClose={() => closeCollateral()}
        mutation={() => editCollateralMutation()}
        formik={editCollFormik}
        title='EDIT COLLATERAL'
        selectedCollateral={selectedCollateral}
      />

      <DeleteModal
        isOpen={selectedModal?.deleteCollateral}
        onRequestClose={() => closeCollateral()}
        mutation={() => deleteCollateralMutation()}
        type='collateral'
      />
    </Container>
  );
};

export default TechnologiesContainer;
