import { StateInterface, ACTIONTYPE } from './types';

const resetSelectedTech = {
  id: null,
};

const technologyReducer = (state: StateInterface, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_CREATE':
      return {
        ...state,
        selectedTechnology: resetSelectedTech,
        selectedModal: {
          create: true,
          edit: false,
          delete: false,
          createCollateral: false,
          editCollateral: false,
          deleteCollateral: false,
        },
      };

    case 'SET_EDIT':
      return {
        ...state,
        selectedTechnology: action.payload.selectedTechnology,
        selectedModal: {
          create: false,
          edit: true,
          delete: false,
          createCollateral: false,
          editCollateral: false,
          deleteCollateral: false,
        },
      };

    case 'SET_DELETE':
      return {
        ...state,
        selectedTechnology: action.payload.selectedTechnology,
        selectedModal: {
          create: false,
          edit: false,
          delete: true,
          createCollateral: false,
          editCollateral: false,
          deleteCollateral: false,
        },
      };

    case 'RESET_ALL_MODALS':
      return {
        ...state,
        selectedTechnology: resetSelectedTech,
        selectedModal: {
          create: false,
          edit: false,
          delete: false,
          createCollateral: false,
          editCollateral: false,
          deleteCollateral: false,
        },
      };

    case 'SET_CREATE_COLLATERAL':
      return {
        ...state,

        selectedModal: {
          create: false,
          edit: false,
          delete: false,
          createCollateral: true,
          editCollateral: false,
          deleteCollateral: false,
        },
      };

    case 'CLOSE_COLLATERAL':
      return {
        ...state,

        selectedModal: {
          create: false,
          edit: true,
          delete: false,
          createCollateral: false,
          editCollateral: false,
          deleteCollateral: false,
        },
      };

    case 'SET_EDIT_COLLATERAL':
      return {
        ...state,
        selectedCollateral: action.payload.selectedCollateral,
        selectedModal: {
          create: false,
          edit: false,
          delete: false,
          createCollateral: false,
          editCollateral: true,
          deleteCollateral: false,
        },
      };

    case 'SET_DELETE_COLLATERAL':
      return {
        ...state,
        selectedCollateral: action.payload.selectedCollateral,
        selectedModal: {
          create: false,
          edit: false,
          delete: false,
          createCollateral: false,
          editCollateral: false,
          deleteCollateral: true,
        },
      };

    default:
      return state;
  }
};

export default technologyReducer;
