import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query SearchUsers($input: SearchUsersRequestInput) {
    searchUsers(input: $input) {
      id
      email
      firstName
      lastName
      jobTitle
      passwordHash
      createdDate
      phoneNumber
      status
      organisation {
        id
        name
      }
      userRole {
        id
        name
      }
    }
  }
`;
