/**
 *
 * WebCamImg
 *
 */

import styled from 'styled-components';
import { mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled.div<{ background?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 598px;
  background: ${(props) => props.background || 'transparent'};
  padding-bottom: 55px;
`;

export const Img = styled.img`
  margin-top: 100px;
  object-fit: contain;
  width: 740px;
  height: 100%;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;
