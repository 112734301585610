/**
 *
 * Workspaces
 *
 */

import React from 'react';
import { T } from './types';
import { FlexReverse } from './styles';
import { ReactComponent as Edit } from '../../images/svg/EditIcon.svg';
import { ReactComponent as Delete } from '../../images/svg/DeleteIcon.svg';

import Table from '../Table';
import WorkspaceModal from '../WorkspaceModal';
import DeleteModal from '../DeleteModal';
import useWorkspaceState from './useWorkspaceState';
import Container from '../Container';

import ButtonHover from '../ButtonHover';

const Workspaces: React.FC<any> = () => {
  const {
    workSpaceHeaders,
    workSpaceRows,
    workSpaceObjectKeys,
    workspaceModals,
    createWorkspaceFormik,
    editWorkspaceFormik,
    setWorkspaceModals,
    setEditModal,
    setCreateModal,
    setDeleteModal,

    createWorkspaceMutation,
    editWorkspaceMutation,
    deleteWorkspaceMutation,
  } = useWorkspaceState();

  return (
    <Container>
      <FlexReverse>
        <ButtonHover
          color='black'
          label='ADD A NEW WORKSPACE'
          onClick={() => setCreateModal()}
          marginBottom='2rem'
        />
      </FlexReverse>
      <Table
        headers={workSpaceHeaders}
        rows={workSpaceRows}
        colObjectKeys={workSpaceObjectKeys}
        icons={[
          {
            component: Edit,
            action: setEditModal,
          },
          { component: Delete, action: setDeleteModal },
        ]}
      />

      <WorkspaceModal
        title='CREATE WORKSPACE'
        type='CREATE'
        isOpen={workspaceModals.addWorkspace}
        onRequestClose={() => {
          setWorkspaceModals({ ...workspaceModals, addWorkspace: false });
          createWorkspaceFormik.resetForm();
        }}
        formik={createWorkspaceFormik}
        editMutation={createWorkspaceMutation}
      />

      <WorkspaceModal
        title='EDIT WORKSPACE'
        type='EDIT'
        isOpen={workspaceModals.editWorkspace}
        onRequestClose={() => {
          setWorkspaceModals({ ...workspaceModals, editWorkspace: false });
          editWorkspaceFormik.resetForm();
        }}
        formik={editWorkspaceFormik}
        editMutation={editWorkspaceMutation}
        deleteMutation={deleteWorkspaceMutation}
      />

      <DeleteModal
        isOpen={workspaceModals.deleteWorkspace}
        onRequestClose={() =>
          setWorkspaceModals({ ...workspaceModals, deleteWorkspace: false })
        }
        mutation={() => deleteWorkspaceMutation()}
        type='work space'
      />

      <FlexReverse>
        <ButtonHover
          color='black'
          label='ADD A NEW WORKSPACE'
          onClick={() => setCreateModal()}
          marginBottom='2rem'
          marginTop='2rem'
        />
      </FlexReverse>
    </Container>
  );
};

export default Workspaces;
