/**
 *
 * BrickSelector
 *
 */

import { FC, useState, useEffect } from 'react';
import { Props, GridItemProps } from './types';
import {
  StyleWrapper,
  Container,
  Header,
  SubHeader,
  Grid,
  Item,
  CheckBox,
  Title,
  Close,
  colorArray,
} from './styles';

import ButtonHover from '../ButtonHover';
import { useSnackbar } from 'notistack';

// graphQl
import { useParams } from 'react-router-dom';
import { GET_TEMPLATE_BRICKS } from '../../utils/gql/templateBricks/templateBricksQuery';
import { useQuery, useMutation } from '@apollo/client';
import { GetTemplateBricks_proposal_templateBricks } from '../../utils/gql/templateBricks/__generated__/GetTemplateBricks';

import { ADD_BRICKS_TO_PROPOSAL } from '../../utils/gql/bricks/addBricksToProposalMutation';
import { GET_BRICKS_FROM_PROPOSAL } from '../../utils/gql/proposals/getBricksFromProposal';

const GridItem = ({
  brick,
  selectedBricks,
  addBrick,
  removeBrick,
  readOnly,
}: GridItemProps) => {
  const { name, colorCode } = brick;

  const [checked, setChecked] = useState(false);

  const toggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (checked) {
      addBrick(brick, selectedBricks);
    } else {
      removeBrick(brick, selectedBricks);
    }
    // eslint-disable-next-line
  }, [checked]);

  return (
    <Item background={colorCode}>
      <CheckBox
        type='checkbox'
        onChange={(e) => (readOnly ? null : toggle(e))}
      />
      {name && <Title color='white'>{name}</Title>}
    </Item>
  );
};

const BrickSelector: FC<Props> = ({ isOpen, setAddBrick, readOnly }) => {
  const [selectedBricks, setSelectedBricks] = useState<any[]>([]);

  const addBrick = (brick: any, selectedBricks: any) => {
    const include = selectedBricks.includes(brick);

    if (!include) {
      setSelectedBricks((oldArray) => [...oldArray, brick]);
    }
  };

  const removeBrick = (brick: any, selectedBricks: any) => {
    const filter = selectedBricks.filter((el: any) => el.id !== brick.id);
    setSelectedBricks(filter);
  };

  const { id } = useParams<any>();

  const { data } = useQuery<any>(GET_TEMPLATE_BRICKS, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const returnBricksWithColors = (newArr: any) => {
    if (!newArr) return;
    let counter = 0;

    let reformattedArray = newArr.map((obj: any, index: number) => {
      if (index % 8 === 0 || index === 0) {
        counter = 0;
      } else {
        counter = counter + 1;
      }
      return { ...obj, colorCode: colorArray[counter] };
    });

    return reformattedArray;
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [addBricksToProposal, { loading }] = useMutation<any>(
    ADD_BRICKS_TO_PROPOSAL
  );

  const addBricksToProposalHelper = () => {
    let input = {
      proposalId: id,
      bricks: selectedBricks.map((brick) => brick.id),
    };

    addBricksToProposal({
      variables: { input },
      refetchQueries: [{ query: GET_BRICKS_FROM_PROPOSAL, variables: { id } }],
    })
      .then((response) => {
        setAddBrick(false);
        setSelectedBricks([]);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setSelectedBricks([]);
      });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <StyleWrapper>
      <Close onClick={() => setAddBrick(false)} />
      <Container>
        <Header>SELECT FROM EXISTING Proposal Bricks</Header>
        <SubHeader>
          Choose the Proposal brick you want to add to your proposal
        </SubHeader>

        {data?.proposal?.templateBricks && (
          <Grid>
            {returnBricksWithColors(data?.proposal?.templateBricks)
              .map((brick: GetTemplateBricks_proposal_templateBricks) => (
                <GridItem
                  key={brick.id}
                  brick={brick}
                  selectedBricks={selectedBricks}
                  addBrick={addBrick}
                  removeBrick={removeBrick}
                  readOnly={readOnly}
                />
              ))
              .filter(
                (brick: GetTemplateBricks_proposal_templateBricks) =>
                  brick.status !== -1
              )}
          </Grid>
        )}

        <ButtonHover
          marginTop='20px'
          marginLeft='auto'
          label='OK'
          color='black'
          onClick={addBricksToProposalHelper}
        />
      </Container>
    </StyleWrapper>
  );
};

export default BrickSelector;
