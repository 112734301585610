import { gql } from '@apollo/client';

export const ADD_BRICKS_TO_PROPOSAL = gql`
  mutation AddBricksToProposal($input: AddBricksToProposalRequestInput!) {
    addBricksToProposal(input: $input) {
      proposal {
        id
        bricks {
          name
          id
        }
      }
    }
  }
`;
