import { gql } from '@apollo/client';

export const SEARCH_ORGANISATIONS = gql`
  query SearchOrganisastions($input: SearchOrganisationsRequestInput) {
    searchOrganisations(input: $input) {
      value: id
      label: name
      domains {
        value: id
        label: name
      }
    }
  }
`;

export const SEARCH_ORGANISATIONS_TABLE = gql`
  query SearchOrganisastionsTable($input: SearchOrganisationsRequestInput) {
    searchOrganisations(input: $input) {
      id
      name
      logoUrl
      status
      domains {
        name
      }
      regionId
    }
  }
`;
