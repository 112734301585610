/**
 *
 * Login
 *
 */
import { Fragment, useState } from 'react';
import LoginModal from '../../components/AuthModal/loginModal';
import PasswordResetModal from '../../components/AuthModal/passwordResetModal';
import Background from '../../components/Background/index';
import imageUrl from '../../images/salesBackground/salesBackground.jpg';

const Login = () => {
  const [modals, setModals] = useState({
    loginModal: true,
    resetPasswordModal: false,
  });

  const { loginModal, resetPasswordModal } = modals;

  return (
    <Fragment>
      <Background imageUrl={imageUrl} />
      <LoginModal
        isOpen={loginModal}
        onRequestClose={setModals}
        type='DEFAULT'
      />
      <PasswordResetModal
        isOpen={resetPasswordModal}
        onRequestClose={setModals}
      />
    </Fragment>
  );
};

export default Login;
