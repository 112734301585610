/**
 *
 * TextField
 *
 */

import React from 'react';
import { Props } from './types';
import { Input, HelperText } from './styles';

const TextField: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <Input {...props} />
      {props.error && props.name && <HelperText>{props.helperText}</HelperText>}
    </React.Fragment>
  );
};

export default TextField;
