/**
 *
 * SummaryActivity
 *
 */

import styled from 'styled-components';

export const StyleWrapper = styled.div`
  width: 100%;
  background: white;
  padding-bottom: 129px;
`;
