/*
 * Carousel Messages
 *
 * This contains all the text for the Carousel component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.Carousel.title',
    defaultMessage: 'Your spaces',
  },
});
