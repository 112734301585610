/**
 *
 * LoginModal
 *
 */
// @ts-nocheck
import { FC, useEffect } from 'react';
import useNavigate from '../../utils/hooks/useNavigate';
import { useSnackbar } from 'notistack';

import { Formik } from 'formik';

// comps
import Logo from '../Logo';
import Modal from 'react-modal';

// styles
import {
  FormStyled,
  Label,
  Input,
  CheckBoxWrapper,
  CheckBox,
  Text,
  Forgot,
  Submit,
  customStylesLogin,
} from './styles';
import { colors } from '../../utils/styledTheme';

import { MyFormValues } from './types';

// graphql
import { useMutation } from '@apollo/client';
import { UserLogin } from '../../utils/gql/login/__generated__/UserLogin';
import { USER_LOGIN } from '../../utils/gql/login/userLoginMutation';
import { isLoggedInVar } from '../../apollo/cache';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

import { useHistory } from 'react-router';

const initialValues: MyFormValues = {
  email: '',
  password: '',
  rememberMe: false,
};

const LoginModal: FC<{
  isOpen: boolean;
  onRequestClose: Function;
  type: string;
}> = ({ isOpen, onRequestClose, type }) => {
  const [loginUser, { data, loading, error }] =
    useMutation<UserLogin>(USER_LOGIN);

  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let history = useHistory();

  return (
    <Modal isOpen={isOpen} style={customStylesLogin}>
      <Logo marginTop='90px' />
      <Label>
        <FormattedMessage {...messages.loginLabel} />
      </Label>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          loginUser({ variables: { ...values } })
            .then((data) => {
              localStorage.setItem(
                'accessToken',
                data.data?.userLogin?.loginDetails?.accessToken || ''
              );
              localStorage.setItem(
                'refreshToken',
                data.data?.userLogin?.loginDetails?.refreshToken || ''
              );
            })
            .then((data) => {
              console.log(data);
              console.log(isLoggedInVar(true));

              if (type === 'DEFAULT') {
                history.push('/');
              }
            })
            .catch((error) =>
              enqueueSnackbar(error.message, { variant: 'error' })
            );
        }}
      >
        <FormStyled>
          <Input
            id='email'
            type='email'
            name='username'
            placeholder='example@logitech.com'
          />
          <Input
            id='password'
            type='password'
            name='password'
            placeholder='********'
            marginTop='15px'
          />

          <CheckBoxWrapper>
            <CheckBox type='checkbox' name='rememberMe' id='rememberMe' />
            <Text>
              <FormattedMessage {...messages.rememberMe} />
            </Text>
            <Forgot
              onClick={() =>
                onRequestClose({ loginModal: false, resetPasswordModal: true })
              }
            >
              Forgot password?
            </Forgot>
          </CheckBoxWrapper>
          <Submit
            type='submit'
            value={intl.formatMessage(messages.login)}
            background={colors.lightBlue}
            color='black'
            disabled={loading}
          />
        </FormStyled>
      </Formik>
    </Modal>
  );
};

export default LoginModal;
