import React, { useMemo, useState } from 'react';
import { useStyles, SliderWrapper, StyledSlider, Label } from './styles';
import { Grid } from '@material-ui/core';
import ProposalSchema, { initialValues, SendProposalInterface } from './formik';
import { Form, Formik, FormikProps, Field } from 'formik';
import TextField from '../../FormFields/TextField';
import Button from '@material-ui/core/Button';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';
import useNavigate from '../../../utils/hooks/useNavigate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentArea } from '../../../layout/ContentArea';
import { useParams } from 'react-router-dom';
import { SEND_PROPOSAL } from '../../../utils/gql/proposals/sendProposalMutation';
import { useMutation } from '@apollo/client';
import { SendProposal } from '../../../utils/gql/proposals/__generated__/SendProposal';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

const SendProposalForm = () => {
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const [sendProposal, { data, loading: sendProposalLoading, error }] =
    useMutation<SendProposal>(SEND_PROPOSAL);

  const sleep = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  };

  const handleSubmit = (values: SendProposalInterface) => {
    setLoading(true);

    const input = {
      proposalId: id,
      informationConfidence: values.confidenceInfo,
      buyingGroupConfidence: values.confidenceBuying,
    };
    // submit and navigate to success screen
    sendProposal({ variables: { input } })
      .then((_) => navigate.proposalsSuccess({ params: { id } }))
      .catch((error) => console.log(error));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // @ts-ignore
      // validationSchema={ProposalSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        errors,
        touched,
        setFieldValue,
      }: FormikProps<SendProposalInterface>) => (
        <Form autoComplete='off' className={classes.formStyle}>
          <Label>
            <FormattedMessage {...messages.confidenceInfo} />
          </Label>
          <SliderWrapper>
            <Field
              component={StyledSlider}
              name='confidenceInfo'
              valueLabelDisplay={'off'}
              aria-label='Always visible'
              value={values.confidenceInfo}
              onChange={(e: any) =>
                setFieldValue('confidenceInfo', e.target.value)
              }
              step={null}
              marks={marks}
              max={4}
              min={1}
            />
          </SliderWrapper>

          <Label>
            <FormattedMessage {...messages.confidenceBuying} />
          </Label>
          <SliderWrapper>
            <Field
              component={StyledSlider}
              name='confidenceBuying'
              valueLabelDisplay={'off'}
              aria-label='Always visible'
              value={values.confidenceBuying}
              onChange={(e: any) =>
                setFieldValue('confidenceBuying', e.target.value)
              }
              step={1}
              marks={marks}
              max={4}
              min={1}
            />
          </SliderWrapper>
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              type='submit'
              disabled={!isValid}
              style={{ marginBottom: '88px', marginTop: '72px' }}
            >
              {loading ? (
                <CircularProgress size={24} color={'primary'} />
              ) : (
                <FormattedMessage {...messages.send} />
              )}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SendProposalForm;
