import { gql } from '@apollo/client';

export const SEARCH_EQUIPMENTS_TABLE = gql`
  query SearchEquipmentsTable($input: SearchEquipmentsRequestInput) {
    searchEquipments(input: $input) {
      id
      name
      imageUrl
      status
      equipmentCategoryId
      rooms {
        name
        id
      }
      collateral {
        id
        url
        fileUrl
        collateralTypeId
        name
        status
      }
    }
  }
`;

export const SEARCH_COLLATERALS = gql`
  query SearchCollateral($input: SearchEquipmentsRequestInput) {
    searchEquipments(input: $input) {
      collateral {
        id
        url
        fileUrl
        collateralTypeId
        name
      }
    }
  }
`;
