import { gql } from '@apollo/client';

export const WHO_AM_I = gql`
  query WhoAmI {
    permissions: userPermissions {
      roleName
      contentManagement {
        hasModule
        canViewSlides
        canAddSlides
        canEditSlides
        canPreviewSlides
        canViewBricks
        canAddBricks
        canEditBricks
        canDeleteBricks
        canReorderBricks
      }
      users {
        hasModule
        canCreateSuperAdminRole
        canCreateAdminRole
        canCreateSalesRole
        canCreateCustomerRole
        canEditUser
        canDeleteUser
        canResetUserPassword
      }
      clients {
        hasModule
        canCreateClient
        canEditClient
        canDeleteClient
        canResetPassword
      }
      organisations {
        hasModule
        canCreateOrganisation
        canEditOrganisation
        canDeleteOrganisation
      }
      proposals {
        hasModule
        canViewAllProposals
        canCreateProposal
        canReviewProposal
        canShareProposal
        canViewProposal
        canEditProposal
        canDeleteProposal
      }
      clientView {
        hasModule
        canCreateNewPassword
        canViewYourProposals
        canShareYourProposals
      }
    }
  }
`;
