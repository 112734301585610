/**
 *
 * AsyncSelectDropDown
 *
 */

import styled from 'styled-components';
import { fonts, colors } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 106px;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Label = styled.div<{ marginTop?: string }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop};
 `;

export const IndicatorContainer = styled.div`
  width: 56px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Icon = styled.div<{ imageUrl?: string }>`
  width: 56px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: cover;
  background-image: ${(props) => props.imageUrl};
`;

export const StyledOption = styled.div<{ borderRadius?: string }>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  margin: 0;
  border-radius: ${(props) => props.borderRadius || '0px'};

  &:hover {
    background: #e1e2e3;
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  color: red;
`;
