import * as Yup from 'yup'

export const createProposalSchema = Yup.object().shape({
    name: Yup.string().required('Required field').min(3, 'Must be 3 at least 3 characters long').nullable(),
    customerFirstName: Yup.string().required('Required field').nullable(),
    customerLastName: Yup.string().required('Required field').nullable(),
    emailPrefix: Yup.string().required('Required field').nullable(),
    emailSuffix: Yup.object().required('Required field').nullable(),
})

export interface SelectInterface {
    label: string;
    value: string;
}

export interface CreateProposalInterface {
    name: string | null;
    customerFirstName: string | null;
    customerLastName: string | null;
    clientEmails?: [{value: string}];
    emailPrefix: string | null;
    emailSuffix: {value: string, label: string} | null;
    organisation: any[];
}

export const initialValues: CreateProposalInterface = {
    name: null,
    customerFirstName: null,
    customerLastName: null,
    emailPrefix: null,
    emailSuffix: null,
    organisation: [],
}

export default createProposalSchema

