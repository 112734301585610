/**
 *
 * SentTo
 *
 */

import React from 'react';
import { SentToColumn, Recipient } from './styles';

const SentTo: React.FC<any> = ({ users }) => (
  <SentToColumn>
    {users?.map((el: any, id: number) => (
      <Recipient key={id}>{el?.firstName + ' ' + el?.lastName}</Recipient>
    ))}
  </SentToColumn>
);

export default SentTo;
