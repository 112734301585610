import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { GET_BRICKS_FROM_PROPOSAL } from '../../utils/gql/proposals/getBricksFromProposal';

const useVewProposalState = () => {
  const { id } = useParams<any>();

  const { data, refetch: refetchBricks } = useQuery<any>(
    GET_BRICKS_FROM_PROPOSAL,
    {
      variables: { id },
      fetchPolicy: 'network-only',
    }
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [addBrick, setAddBrick] = useState<boolean>(false);

  return {
    id,
    bricks: data?.proposal?.bricks,
    refetchBricks,
    editMode,
    addBrick,
    setEditMode,
    setAddBrick,
  };
};

export default useVewProposalState;
