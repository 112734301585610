import { gql } from '@apollo/client';

export const GET_PROPOSAL = gql`
  query GetProposal($id: Uuid!) {
    proposal(id: $id) {
      id
      name
      title
      intro
      description
      mainImageUrl
      footerImageUrl
      customerId
      customerFullName
      status
      type
      status
      createdDate
      updatedDate
      templateIntro
      templateDescription
      bricks {
        id
        name
        url
      }
      slides {
        id
        name
        intro
        version
        url
      }
      rooms {
        id
        name
        imageUrl
        sortOrder
        proposalId
        equipment {
          id
          name
          isSelected
          equipmentCategoryId
        }
      }
      customer {
        id
        firstName
        lastName
      }
      organisation {
        id
        logoUrl
        name
      }
      templateBricks {
        id
        name
        url
      }

      informationConfidence
      buyingGroupConfidence
    }
  }
`;
