import { gql } from '@apollo/client';

export const CREATE_BRICK = gql`
  mutation CreateBrick($input: CreateBrickRequestInput!) {
    createBrick(input: $input) {
      brick {
        name
        url
        id

        createdDate
        updatedBy
        updatedDate
        createdBy
        createdDate
      }
    }
  }
`;
