/**
 *
 * SummaryProposal
 *
 */

import { FC } from 'react';
import { Props } from './types';
import { StyleWrapper, Title, Logo } from './styles';
import Container from '../Container';

import { colors, fonts } from '../../utils/styledTheme';

const SummaryProposal: FC<Props> = ({ proposal, selectedTab }) => {
  return (
    <StyleWrapper>
      <Container background='white'>
        <Title marginTop={23} color={colors.brightBlue}>
          PROPOSAL REVIEW
        </Title>
        <Title marginTop={13} fontSize={20}>
          Proposal Title:
        </Title>
        <Title marginTop={0} fontSize={61}>
          {proposal?.title}
        </Title>

        <Title marginTop={43} fontSize={20}>
          Company Logo:
        </Title>
        <Logo src={proposal?.organisation?.logoUrl} />

        <Title marginTop={10} fontSize={20}>
          Customer name:
        </Title>

        <Title marginTop={10} fontSize={20}>
          {proposal?.customerFullName}
        </Title>

        <Title marginTop={37} fontSize={20}>
          Proposal intro:
        </Title>

        <Title
          marginTop={0}
          marginBottom={77}
          fontSize={20}
          fontFamily={fonts.normal}
        >
          {proposal?.intro?.replace(/<[^>]*>?/gm, '')}
        </Title>
      </Container>
    </StyleWrapper>
  );
};

export default SummaryProposal;
