/**
 *
 * Table
 *
 */
import { styled as s } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import { colors } from '../../utils/styledTheme';

export const Flex = styled.div`
  display: flex;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 109px;
  height: 66px;
  border-radius: 6px;
`;

export const logoBackground =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='109' height='66' viewBox='0 0 109 66'%3E%3Cg id='Rectangle_1305' data-name='Rectangle 1305' fill='%23707070' stroke='%23707070' stroke-width='1'%3E%3Crect width='109' height='66' rx='5' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='108' height='65' rx='4.5' fill='none'/%3E%3C/g%3E%3C/svg%3E%0A";

export const Svg = styled.img`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
`;

export const StyledTableCell = s(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.logitechBlack,
    color: 'white',
    height: '40px',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: 16,
    whiteSpace: 'nowrap',
    fontFamily: 'BrownProTT',
    '&:last-of-type': {
      color: 'transparent',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    height: '100px',
    color: colors.logitechBlack,
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'BrownProTT',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    '&:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

export const StyledTableRow = s(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F0F0F0',
  },
  // hide last border
  '&:td, &:th': {
    border: 0,
  },
}));

export const Container = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: scroll;
  border-radius: 6px;
`;
