// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { TextField, CircularProgress, SvgIcon } from '@material-ui/core';
import { Field } from 'formik';
import { useStyles, useChipStyles } from './styles';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import { ReactComponent as SearchIcon } from '../../../images/svg/SearchIcon.svg';
import { useFormikContext } from 'formik';

import CloseIcon from '@mui/icons-material/Close';

interface Option {
  value: string;
  label: string;
}

interface IProps {
  name: string;
  placeholder?: string;
  multiple?: boolean;
  isLoading?: boolean;
  options?: [];
  searchTermCallback?: (value: string) => Promise<any>;
  limit?: number;
  clearable?: boolean;
}

export const Asynchronous = ({
  name,
  placeholder,
  options = [],
  searchTermCallback,
  multiple,
  limit = 99,
  clearable = false,
  isLoading,
}: IProps) => {
  const { root, ...autoCompleteClasses } = useStyles();
  const chipStyles = useChipStyles();
  const [open, setOpen] = useState(false);
  const loading = isLoading;
  const { values } = useFormikContext();
  const [startAdornment, setStartAdornment] = useState<boolean>(true);

  useEffect(() => {
    // @ts-ignore
    if (values[name]?.length > 0) {
      setStartAdornment(false);
      return;
    }

    setStartAdornment(true);
    // do whatever you want with this if length > 0
  }, [values]);

  const displayAdornment = (params: AutocompleteRenderInputParams) => {
    return startAdornment ? (
      <React.Fragment>
        <SvgIcon component={SearchIcon} viewBox='0 0 32 32' />
        {params.InputProps.startAdornment}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <SvgIcon component={SearchIcon} viewBox='0 0 32 32' />
        {params.InputProps.startAdornment}
      </React.Fragment>
    );
  };

  return (
    <Field
      style={{ flex: 1, margin: 0 }}
      name={name}
      component={Autocomplete}
      // @ts-ignore
      getOptionDisabled={() => values[name]?.length === limit}
      multiple={multiple}
      classes={{ ...autoCompleteClasses }}
      // freeSolo
      ChipProps={{
        classes: chipStyles,
        deleteIcon: <CloseIcon />,
      }}
      options={options}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option: Option) => option.label}
      onInputChange={(
        event: React.SyntheticEvent,
        value: string,
        reason: string
      ) => {
        !!searchTermCallback && searchTermCallback(value);
      }}
      disableClearable={!clearable}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            variant='outlined'
            name={name}
            // @ts-ignore
            placeholder={values[name]?.length ? '' : placeholder}
            InputProps={{
              ...params.InputProps,
              className: root,
              startAdornment: displayAdornment(params),
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                  {loading && <CircularProgress size={20} color='primary' />}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default Asynchronous;
