import { gql } from '@apollo/client';

export const GET_BRICKS_FROM_PROPOSAL = gql`
  query GetBricksFromProposal($id: Uuid!) {
    proposal(id: $id) {
      id

      bricks {
        id
        name
        url
        text
        fileUrl
        status
      }
    }
  }
`;
