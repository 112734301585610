// @ts-nocheck
import { FC } from 'react';
import { MenuItemWrapper } from './styles';
import { matchPath } from 'react-router-dom';
import { MenuItemProps } from './types';

const MenuItem: FC<MenuItemProps> = ({ route, location, generator }) => {
  const gen = generator(route.path);

  const goToLink = () => {
    gen.next();
    gen.next();
  };
  return (
    <MenuItemWrapper
      matchedRoute={!!matchPath(location.pathname, route.path)}
      onClick={() => goToLink(route?.path)}
    >
      {route?.nav?.navLabel}
    </MenuItemWrapper>
  );
};

export default MenuItem;
