/**
 *
 * Carousel
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyleWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: -1rem;
  background-color: transparent;
`;

export const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 423px;
  height: 361px;
  box-sizing: border-box;
  margin-left: 7rem;

  @media (max-width: 768px) {
    width: 222px;
  }
`;
export const SlideBackground = styled.img`
  object-fit: contain;
  cursor: pointer;
  width: 80%;
  height: 80%;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
export const Title = styled.h4`
  text-align: center;
  font-size: 31px;
  color: #000000;
  font-family: 'BrownProTTBold' !important;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 0;
`;
export const StyledButton = styled.div<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? '#00B8FC' : 'transparent'};
  color: #000000;
  width: 240px;
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  border: none;
  font-family: 'BrownProTTBold' !important;
  font-size: 20px;
  text-transform: uppercase;

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? '#00B8FC' : 'transparent'};
    color: #000000;
  }
  @media (max-width: 768px) {
    width: 145px;
  }
`;
