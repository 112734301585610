/**
 *
 * SummaryTabs
 *
 */

import { FC, useState, Fragment } from 'react';
import { Props } from './types';
import { TabWrapper, Tabs, Tab } from './styles';

import { colors } from '../../utils/styledTheme';

const SummaryTabs: FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const { activity, preview, clickStats } = selectedTab;

  return (
    <TabWrapper>
      <Tabs>
        <Tab
          initial={false}
          animate={{ background: activity ? colors.brightBlue : '#fff' }}
          onClick={() =>
            setSelectedTab({
              activity: true,
              preview: false,
              clickStats: false,
            })
          }
        >
          ACTIVITY
        </Tab>
        <Tab
          initial={false}
          animate={{ background: preview ? colors.brightBlue : '#fff' }}
          onClick={() =>
            setSelectedTab({
              activity: false,
              preview: true,
              clickStats: false,
            })
          }
        >
          PREVIEW
        </Tab>
        <Tab
          initial={false}
          animate={{ background: clickStats ? colors.brightBlue : '#fff' }}
          onClick={() =>
            setSelectedTab({
              activity: false,
              preview: false,
              clickStats: true,
            })
          }
        >
          CLICK STATS
        </Tab>
      </Tabs>
    </TabWrapper>
  );
};

export default SummaryTabs;
