import { useEffect, useState } from "react";

/*
 const [mouse, setMouse] = useState([0, 0]);
    const delay = 15; milliseconds
     const debouncedMouse = useDebounce(mouse, delay); 
*/

const useDebounce = (value: any, timeout: any) => {
  // Save a local copy of `value` in this state which is local to our hook
  const [state, setState] = useState(value);

  useEffect(() => {
    // Set timeout to run after delay
    const handler = setTimeout(() => setState(value), timeout);

    // clear the setTimeout listener on unMount
    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

export default useDebounce;
