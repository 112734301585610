import { gql } from '@apollo/client';

export const REMOVE_EQUIPMENT_FROM_ROOM = gql`
  mutation RemoveEquipmentFromRoom(
    $input: RemoveEquipmentFromRoomRequestInput!
  ) {
    removeEquipmentFromRoom(input: $input) {
      room {
        id
        equipment {
          id
          name
          equipmentCategoryId
          isSelected
          collateral {
            id
            url
            fileUrl
            collateralTypeId
            name
            equipmentId
          }
        }
      }
    }
  }
`;
