/**
 *
 * MultiSelect
 *
 */

import styled from 'styled-components';
import { ArrowProps, OptionProps } from './types';
import { fonts } from '../../utils/styledTheme';
import { SvgIcon } from '@material-ui/core';

export const grey = '#E1E2E3';

export const StyleWrapper = styled.div<{
  width?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
}>`
  width: ${(props) => props.width || 49}%;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-left: ${(props) => props.marginLeft || 0}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ArrowContainer = styled.div`
  width: 56px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Arrow = styled.div<ArrowProps>`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='10' viewBox='0 0 18 10'%3E%3Cpath id='Polygon_1' data-name='Polygon 1' d='M9,0l9,10H0Z' transform='translate(18 10) rotate(180)'/%3E%3C/svg%3E%0A");
  width: 18px;
  height: 10px;
  cursor: pointer;
  transform: ${(props) => props.transform};
`;

export const StyledOption = styled.div<OptionProps>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  margin: 0;
  border-radius: ${(props) => props.borderRadius || '0px'};

  &:hover {
    background: ${grey};
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  color: red;
`;

export const Label = styled.div<{ marginTop?: string }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop};
   font-weight: bold;
 `;

export const IconContainer = styled(SvgIcon)`
  margin-top: 0.75rem;
  margin-right: 0.5rem;
`;

export const StyledSelected = styled.div<OptionProps>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: ${(props) => props.borderRadius || '0px'};
`;
