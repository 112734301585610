/**
 *
 * ShareProposal
 *
 */
// @ts-nocheck
import { useState, useEffect } from 'react';
import { Props } from './types';
import {
  customStyles,
  customStylesAsync,
  Text,
  Container,
  FlexRow,
  StyledOption,
} from './styles';

import Modal from 'react-modal';
import { fonts } from '../../utils/styledTheme';

import CreatableSelect from 'react-select/creatable';

import ButtonHover from '../ButtonHover';

const ShareProposal = ({
  isOpen,
  onRequestClose,
  options,
  setUserIds,
  mutation,
  sentTo,
  userIds,
}: Props) => {
  const CustomOption = (props) => {
    const { innerRef, innerProps, data } = props;

    return (
      <StyledOption ref={innerRef} {...innerProps}>
        {data?.label}
      </StyledOption>
    );
  };

  const handleChangeValues = (value: any, option: any) => {
    switch (option.action) {
      case 'remove-value':
      case 'pop-value':
        if (option.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = userIds.filter((v) => v.isFixed);
        break;
    }
    setUserIds(value);
  };
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => onRequestClose()}
    >
      <Container>
        <Text fontSize='61' marginTop='42'>
          INPUT EMAIL ADDRESSES TO SHARE THIS PROPOSAL
        </Text>

        <Text
          fontSize='18'
          marginTop='12'
          marginBottom='27'
          fontFamily={fonts.normal}
        >
          Removing an email address will revoke access for that user
        </Text>

        <CreatableSelect
          styles={customStylesAsync}
          components={{
            DropdownIndicator: () => null,
            IndicatorsContainer: () => null,
            IndicatorSeparator: () => null,
            Option: CustomOption,
          }}
          isMulti
          onChange={handleChangeValues}
          options={options}
          menuIsOpen={true}
          closeOnSelect={false}
          value={userIds}
          isClearable={userIds?.some((v) => !v?.isFixed)}
        />

        <FlexRow>
          <ButtonHover
            label='SAVE'
            color='black'
            marginLeft='20px'
            onClick={() => mutation()}
          />
          <ButtonHover
            label='CANCEL'
            color='white'
            onClick={() => onRequestClose()}
          />
        </FlexRow>
      </Container>
    </Modal>
  );
};

export default ShareProposal;
