// @ts-nocheck
import React from 'react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
import ProposalBricks from './ProposalGrid';
import { Preview, Title } from './styles';

let opts = {};

const MyPreview = () => {
  const { display, item, style } = usePreview();
  if (!display) {
    return null;
  }

  const { colorCode, name } = item;

  return (
    <Preview background={colorCode} style={style}>
      <Title>{name}</Title>
    </Preview>
  );
};

const TouchProvider: React.FC<ProposalGridProps> = ({
  bricks,
  refetchBricks,
  type,
  editMode,
  proposalId,
  setAddBrick,
}) => (
  <DndProvider backend={TouchBackend} options={opts}>
    {bricks && (
      <ProposalBricks
        bricks={bricks}
        refetchBricks={refetchBricks}
        type={type}
        editMode={editMode}
        proposalId={proposalId}
        setAddBrick={setAddBrick}
      />
    )}
    <MyPreview />
  </DndProvider>
);

export default TouchProvider;
