import { gql } from '@apollo/client';

export const ADD_ROOM_TO_PROPOSAL = gql`
  mutation AddRoomToProposal($input: AddRoomToProposalRequestInput!) {
    addRoomToProposal(input: $input) {
      proposal {
        id
        name
        rooms {
          id
          name
          imageUrl
          iconUrl
          equipment {
            id
            name
            imageUrl
          }
        }
      }
    }
  }
`;
