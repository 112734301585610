/**
 *
 * TechModal
 *
 */

import { FC, Fragment, useEffect, useState } from 'react';
import { T } from './types';
import { Container, Form, Title, Flex, dzStyles } from './styles';

import Modal from 'react-modal';
import Input from '../../components/Input';
import Dropzone from '../Dropzone';
import ButtonHover from '../ButtonHover';
import MultiSelect from '../MultiSelect';
import CollTable from '../CollTable';

import { useQuery } from '@apollo/client';
import { GET_TEMPLATE_ROOMS } from '../../utils/gql/templateRooms/templateRoomsQuery';
import { techTypes } from '../../constants/equipments';

const TechModal: FC<T> = ({
  isOpen,
  onRequestClose,
  formik,
  title,
  mutation,
  paddingBottom,
  setAddCollModal,
  selectedTechnology,
  collateralData,
  setEditCollateral,
  setDeleteCollateral,
}) => {
  const { data, loading, error } = useQuery(GET_TEMPLATE_ROOMS, {
    variables: { id: '3944048e6eeb3c347bf1382dac4f137a' },
  });

  const formatRoomOptions = () => {
    if (!data) return [];
    return data?.proposal?.templateRooms?.map((room: any) => ({
      label: room.name,
      value: room.id,
    }));
  };

  const isValid = (values: any) => {
    if (
      values?.name?.length &&
      values?.equipmentCategoryId?.value &&
      values?.roomIds?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const customStyles = {
    content: {
      width: '70%',
      paddingLeft: '55px',
      paddingRight: '55px',
      borderRadius: '6px',
      paddingBottom: paddingBottom,
      background: '#f7f7f7',
      onset: 0,
      margin: '0 auto',
      border: 0,
    },

    overlay: {
      backgroundColor: 'rgba(51, 51, 51, 0.84)',
      padding: 0,
      onset: 0,
      zIndex: 1,
    },
  };
  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
    >
      <Container>
        <Title>{title}</Title>

        <Form onSubmit={formik.handleSubmit}>
          <Flex marginTop={12} style={{ height: '230px' }}>
            <Flex direction='column' style={{ width: '69%' }}>
              <Input
                label='Name'
                id='name'
                name='name'
                type='text'
                value={formik.values.name}
                onChange={formik.handleChange}
                width='100%'
                padding='0 1.25%'
                formik={formik}
                backgroundColor='#FFF'
                noBorder={true}
                marginTop='0px'
              />

              <Flex>
                <MultiSelect
                  width={100}
                  options={techTypes}
                  setFieldValue={formik.setFieldValue}
                  name='equipmentCategoryId'
                  placeholder='Technology Type'
                  errors={formik.errors}
                  value={formik?.values?.equipmentCategoryId}
                  label='Technology Type'
                  marginRight={12}
                />
                <MultiSelect
                  isMulti
                  width={100}
                  options={formatRoomOptions()}
                  setFieldValue={formik.setFieldValue}
                  name='roomIds'
                  placeholder='Suitable Workspaces'
                  errors={formik.errors}
                  value={formik.values.roomIds}
                  label='Suitable Workspaces'
                  marginLeft={12}
                />
              </Flex>
            </Flex>
            <Dropzone
              baseStyle={dzStyles}
              formik={formik}
              name='imageName'
              file='imageUrl'
              fileName='imageUrl'
              editName='image'
              backgroundWidth='100%'
              label='Image'
              editMode={true}
              height='230px'
              width={29}
              marginTop={0}
            />
          </Flex>
          {title === 'EDIT ITEM' &&
            setAddCollModal &&
            selectedTechnology &&
            setEditCollateral &&
            setDeleteCollateral && (
              <Fragment>
                <CollTable
                  collateralData={collateralData}
                  setEditCollateral={setEditCollateral}
                  setDeleteCollateral={setDeleteCollateral}
                />
                <ButtonHover
                  label='ADD COLLATERAL'
                  color='black'
                  onClick={() => setAddCollModal(selectedTechnology)}
                  marginLeft='auto'
                  marginRight='auto'
                  marginTop='60px'
                />
              </Fragment>
            )}

          <Flex direction='row-reverse' justify='flex-start' marginTop={45}>
            <ButtonHover
              color='black'
              label='save'
              marginLeft='20px'
              onClick={() => mutation()}
              disabled={!isValid(formik.values)}
              type='submit'
            />
            <ButtonHover
              color='white'
              label='cancel'
              onClick={() => onRequestClose()}
            />
          </Flex>
        </Form>
      </Container>
    </Modal>
  );
};

export default TechModal;
