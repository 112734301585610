export const constants: Constants = {
  mediaQueries: {
    iPad: 740,
    iPadPro: 1024,
    desktop: 1314,
  },

  fonts: {
    light: 'BrownProTTLight',
    normal: 'BrownProTT',
    bold: 'BrownProTTBold',
  },

  colors: {
    grey: '#E1E2E3',
    fontColor: '#707070',
    blue: '#265FC5',
    lightBlue: '#4fb7f6',
    brightBlue: '#00B8FC',
    offWhite: '#F7F7F7',
    normalWhite: '#FFF',
    logitechBlack: '#2E3132',
  },
};

export const mediaQueries: MediaQueries = {
  iPad: `@media (min-width: ${constants.mediaQueries.iPad}px)`,
  iPadPro: `@media (min-width: ${constants.mediaQueries.iPadPro}px)`,
  desktop: `@media (min-width: ${constants.mediaQueries.desktop}px)`,
};

export const fonts: Fonts = {
  light: `${constants.fonts.light}`,
  normal: `${constants.fonts.normal}`,
  bold: `${constants.fonts.bold}`,
};

export const colors: Colors = {
  grey: `${constants.colors.grey}`,
  fontColor: `${constants.colors.fontColor}`,
  blue: `${constants.colors.blue}`,
  lightBlue: `${constants.colors.lightBlue}`,
  brightBlue: `${constants.colors.brightBlue}`,
  offWhite: `${constants.colors.offWhite}`,
  normalWhite: `${constants.colors.normalWhite}`,
  logitechBlack: `${constants.colors.logitechBlack}`,
};

interface MediaQueries {
  iPad: string;
  iPadPro: string;
  desktop: string;
}

interface Fonts {
  light: string;
  normal: string;
  bold: string;
}

interface Colors {
  grey: string;
  fontColor: string;
  blue: string;
  lightBlue: string;
  brightBlue: string;
  offWhite: string;
  normalWhite: string;
  logitechBlack: string;
}

interface Constants {
  mediaQueries: {
    readonly iPad: number;
    readonly iPadPro: number;
    readonly desktop: number;
  };

  fonts: {
    readonly light: string;
    readonly normal: string;
    readonly bold: string;
  };

  colors: {
    readonly grey: string;
    readonly fontColor: string;
    readonly blue: string;
    readonly lightBlue: string;
    readonly brightBlue: string;
    readonly offWhite: string;
    readonly normalWhite: string;
    readonly logitechBlack: string;
  };
}
