/**
 *
 * MultiSelect
 *
 */
// @ts-nocheck
import React, { useMemo, useState, useEffect } from 'react';
import { Props } from './types';
import {
  StyleWrapper,
  ArrowContainer,
  Arrow,
  StyledOption,
  Error,
  Label,
  IconContainer,
  StyledSelected,
} from './styles';

import { components, StylesConfig, OptionTypeBase } from 'react-select';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { colors } from '../../utils/styledTheme';

const MultiSelect = ({
  addNew = false,
  placeholder = '',
  options = [],
  name = 'multiSelect',
  isMulti = false,
  setFieldValue,
  errors,
  value,
  label,
  renderIcon,
  ...props
}: Props) => {
  const customStyles: StylesConfig<OptionTypeBase, false> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      height: '72px',
      flex: 1,
      borderColor: '#E1E2E3',
      border: `2px solid ${colors.grey}`,
      borderRadius: '5px',
      boxSizing: 'border-box',
    }),
    control: (base) => ({
      ...base,
      flex: 1,

      borderRadius: '5px',
      outline: 0,
      border: 0,
      backgroundColor: 'white',
      boxShadow: 'none',

      '&:hover': {
        borderColor: `${colors.blue}`,
      },
    }),
    input: (base) => ({
      ...base,
      border: 0,
      outline: 0,
      padding: 0,
    }),
    valueContainer: (base) => ({
      ...base,
      height: 72,
      maxHeight: 72,
      flexWrap: 'nowrap',
      paddingLeft: 30,
      paddingRight: 30,
      color: '#707070',
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#00B8FC',
      height: '42px',
      borderRadius: '22px',

      '& > div': {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
      },
      '& > div:first-child': {
        padding: '11px 16px',
      },
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#00B8FC',
      ':hover': {
        color: 'white',
      },
      paddingRight: '6px',

      '& > svg': {
        width: 25,
        height: 25,
      },
    }),

    menu: (base) => ({
      ...base,
      border: `2px solid ${colors.grey}`,
      boxShadow: 'none',
    }),

    menuList: (base) => ({
      ...base,
      marginTop: 0,
      padding: 0,
      color: '#707070',
      overflowX: 'hidden',
    }),
  };

  const [menu, setMenu] = useState<boolean>(false);

  const IndicatorsContainer = (props: any) => (
    <components.IndicatorsContainer {...props}>
      <ArrowContainer>
        <Arrow transform={menu ? 'rotate(180deg)' : 'rotate(0deg)'} />
      </ArrowContainer>
    </components.IndicatorsContainer>
  );

  const CustomOption = (props) => {
    const { innerRef, innerProps, data } = props;
    const { icon } = data;

    return (
      <StyledOption ref={innerRef} {...innerProps}>
        {icon && renderIcon ? <IconContainer component={icon} /> : null}
        {data?.label}
      </StyledOption>
    );
  };

  const SingleValue = (props) => {
    const { innerRef, innerProps, data } = props;

    const { label, icon } = data;

    return (
      <StyledSelected ref={innerRef} {...innerProps}>
        {icon && renderIcon ? (
          <IconContainer component={icon} viewBox='0 0 24 24' />
        ) : null}
        {label === 'Add New' ? '' : label}
      </StyledSelected>
    );
  };

  const menuOptions = useMemo(() => {
    if (addNew === true && isMulti === false) {
      return [...options, { label: 'Add New' }];
    }
    return options;
  }, [addNew, options]);

  return (
    <StyleWrapper {...props}>
      {label && <Label>{label}</Label>}
      {addNew ? (
        <React.Fragment>
          <CreatableSelect
            styles={customStyles}
            options={menuOptions}
            placeholder={placeholder}
            isMulti={isMulti}
            components={{
              IndicatorsContainer: () => !menuOptions.length && null,
              IndicatorSeparator: () => null,
              Option: CustomOption,
              SingleValue,
              DropdownIndicator: () => null,
            }}
            theme={(theme: any) => ({
              ...theme,
              borderRadius: '5px',
              outline: 0,
              colors: {
                ...theme.colors,
                primary25: '#F8F8F8',
              },
            })}
            onMenuOpen={() => setMenu(true)}
            onMenuClose={() => setMenu(false)}
            onChange={(value) => setFieldValue(name, value)}
            noOptionsMessage={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.inputValue ? '' : null
            }
            value={value}
          />
          {errors && name && (
            <Error>
              {errors[name]
                ? typeof errors[name] === 'object'
                  ? errors[name].value
                  : errors[name]
                : null}
            </Error>
          )}
        </React.Fragment>
      ) : (
        <Select
          styles={customStyles}
          options={menuOptions}
          placeholder={placeholder}
          isMulti={isMulti}
          isSearchable={false}
          components={{
            IndicatorsContainer,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue,
          }}
          theme={(theme: any) => ({
            ...theme,
            borderRadius: '5px',
            outline: 0,
            colors: {
              ...theme.colors,
              primary25: '#F8F8F8',
            },
          })}
          onMenuOpen={() => setMenu(true)}
          onMenuClose={() => setMenu(false)}
          onChange={(value) => setFieldValue(name, value)}
          value={value}
        />
      )}
      {errors && name && (
        <Error>
          {errors[name]
            ? typeof errors[name] === 'object'
              ? errors[name].value
              : errors[name]
            : null}
        </Error>
      )}
    </StyleWrapper>
  );
};

export default MultiSelect;
