/**
 *
 * MeetingSpaces
 *
 */

import React, { useContext, useEffect } from 'react';
import { T, MeetingSpaceProps } from './types';
import {
  StyleWrapper,
  Container,
  Header,
  MeetingSpaceWrapper,
  Title,
  IconBorder,
  Icon,
} from './styles';

// graphQl

import { GetTemplateRooms_proposal_templateRooms } from '../../utils/gql/templateRooms/__generated__/GetTemplateRooms';
import { colors } from '../../utils/styledTheme';

const MeetingSpace: React.FC<any> = ({ name, imageUrl, onClick, selected }) => (
  <MeetingSpaceWrapper>
    {name && <Title>{name.toLowerCase()}</Title>}

    {imageUrl && (
      <IconBorder
        animate={{ borderColor: selected ? '#00EACF' : colors.offWhite }}
        transition={{
          default: { duration: 0.2 },
        }}
      >
        <Icon onClick={onClick} src={imageUrl} />
      </IconBorder>
    )}
  </MeetingSpaceWrapper>
);

const MeetingSpaces: React.FC<T> = ({
  rooms,
  templateRooms,
  handleAddRoomToProposal,
  readOnly,
  type,
}) => {
  if (readOnly) return null;
  return (
    <StyleWrapper background={type === 'PREVIEW' ? 'white' : colors.offWhite}>
      {type !== 'PREVIEW' && (
        <Header>
          Please select the meeting spaces that are important to you
        </Header>
      )}
      <Container>
        {templateRooms?.map(
          ({ iconUrl, name, id }: GetTemplateRooms_proposal_templateRooms) => (
            <MeetingSpace
              key={id}
              onClick={() =>
                readOnly
                  ? null
                  : handleAddRoomToProposal && handleAddRoomToProposal(id)
              }
              imageUrl={iconUrl ? iconUrl : undefined}
              name={name ? name.toLowerCase() : undefined}
              selected={rooms?.map((room: any) => room?.name)?.includes(name)}
            />
          )
        )}
      </Container>
    </StyleWrapper>
  );
};

export default MeetingSpaces;
