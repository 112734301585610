/**
 *
 * UsersContainer
 *
 */
// @ts-nocheck
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import { ReactComponent as Edit } from '../../images/svg/EditIcon.svg';
import { ReactComponent as Delete } from '../../images/svg/DeleteIcon.svg';
import { ReactComponent as Lock } from '../../images/svg/LockIcon.svg';
import { Grid } from '@material-ui/core';

import SearchTermForm from '../../components/Forms/SearchTermForm';
import Table from '../../components/Table/index';
import ButtonHover from '../../components/ButtonHover/index';
import { Title, Row } from './styles';

import Container from '../../components/Container/index';
import DeleteModal from '../../components/DeleteModal';
import EditUserModal from '../../components/EditUserModal';
import ResetPasswordModal from '../../components/ResetPasswordModal';

import { SearchUsers_searchUsers } from '../../utils/gql/users/__generated__/SearchUsers';

// hooks
import useUserState from './useUserState';

const UsersContainer: FC = () => {
  const {
    setSearchTerm,
    searchUsers,
    loading,
    userRoles,

    selectedUser,
    selectedModal,
    setCreateModal,
    setEditModal,
    setDeleteModal,
    setResetPasswordModal,
    resetAllModals,

    // mutations
    formikEditUser,
    formikCreateUser,
    deleteUserMutation,
    editUserMutation,
    createUserMutation,
    resetPasswordMutation,
  } = useUserState();

  const { createModal, editModal, deleteModal, resetPasswordModal } =
    selectedModal;

  const returnRows = () => {
    let map = searchUsers
      ?.filter((el: any) => el?.status !== -1)
      .map((obj) => {
        return {
          ...obj,
          name: obj?.firstName + ' ' + obj?.lastName,
          role: obj?.userRole?.name,
        };
      });
    return map;
  };

  const icons = [
    {
      component: Edit,
      action: setEditModal,
    },
    {
      component: Delete,
      action: setDeleteModal,
    },

    {
      component: Lock,
      action: setResetPasswordModal,
    },
  ];

  return (
    <Container marginTop={50} paddingBottom={159}>
      <Row marginBottom='13px'>
        <Title>
          <FormattedMessage {...messages.header} />
        </Title>

        <ButtonHover
          color='black'
          label='CREATE USER'
          onClick={() => setCreateModal()}
        />
      </Row>

      {setSearchTerm && (
        <SearchTermForm changeCallback={(e) => setSearchTerm(e.target.value)} />
      )}

      <Table
        headers={[
          'Name',
          'Email address',
          'Role',
          'Phone number',
          'Job Title',
          'icons',
        ]}
        rows={returnRows()}
        colObjectKeys={[
          ['name'],
          ['email'],
          ['role'],
          ['phoneNumber'],
          ['jobTitle'],
          ['icons'],
        ]}
        icons={icons}
      />

      <Grid container alignItems='flex-end' justifyContent='flex-end'>
        <ButtonHover
          color='black'
          label='CREATE USER'
          onClick={() => setCreateModal()}
        />
      </Grid>

      <EditUserModal
        isOpen={createModal}
        onRequestClose={() => resetAllModals()}
        userRoles={userRoles}
        user={selectedUser}
        formik={formikCreateUser}
        mutation={() => createUserMutation()}
        type='USER'
        create={true}
      />

      <EditUserModal
        isOpen={editModal}
        onRequestClose={() => resetAllModals()}
        userRoles={userRoles}
        user={selectedUser}
        formik={formikEditUser}
        mutation={editUserMutation}
        type='USER'
      />

      <DeleteModal
        isOpen={deleteModal}
        onRequestClose={() => resetAllModals()}
        mutation={() => deleteUserMutation()}
        type='user'
      />

      <ResetPasswordModal
        isOpen={resetPasswordModal}
        onRequestClose={() => resetAllModals()}
        mutation={() => resetPasswordMutation()}
        type='user'
      />
    </Container>
  );
};

export default UsersContainer;
