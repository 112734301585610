/**
 *
 * FileDrop
 *
 */

import { Field } from 'formik';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core';
import { fonts } from '../../utils/styledTheme';

export const fontColor = '#393b3a';
export const grey = '#E1E2E3';

export const useStyles = makeStyles(() =>
  createStyles({
    fileInput: {
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
  })
);

export const Wrapper = styled.div<{
  width?: string;
  minHeight?: string;
  marginTop?: number;
}>`
  position: relative;
  width: ${(props) => props.width || '100%'};
  min-height: ${(props) => props.minHeight || '106px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop || 30}px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const FiledropWrapper = styled.button<{ height?: string }>`
    all: unset;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    width: 100%;
    height ${({ height }) => height || '100%'};
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    background-color: #fff;
    border: 2px solid ${grey};
    border-radius: 5px;
    position: relative;
    box-sizing: border-box;
`;

export const FiledropLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  flex-direction: column;
`;
export const FiledropLabel = styled.span`
  color: ${fontColor};
  font-size: 18px;
  text-align: center;
  margin-top: 7px;
`;

export const FileInput = styled(Field)`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const ImgPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const Label = styled.div<{ marginTop?: string }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop};
 `;
