import { gql } from '@apollo/client';

export const GET_EQUIPMENT = gql`
  query GetEquipment($id: Uuid!) {
    equipment(id: $id) {
      id
      name
      collateral {
        id
        url
        fileUrl
        collateralTypeId
        name
        status
        sortOrder
      }
    }
  }
`;
