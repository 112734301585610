/**
 *
 * EditModal
 *
 */
//@ts-nocheck
import React, { FC } from 'react';
import { CreateBrickProps, InputProps } from './types';

import Modal from 'react-modal';
import ButtonHover from '../ButtonHover';

import {
  Container,
  Title,
  Form,
  TextInput,
  TextArea,
  InputWrapper,
  Label,
  Flex,
} from './styles';

import MyDropzone from './MyDropZone';

const customStyles = {
  content: {
    maxWidth: '1340px',
    margin: 'auto',
    width: '70%',
    borderRadius: '6px',
    background: '#f7f7f7',
    onset: 0,
    paddingBottom: 70,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    onset: 0,
    zIndex: 999999999999,
  },
};

const InputHelper = ({ label, ...props }: InputProps) => (
  <InputWrapper {...props}>
    <Label>{label}</Label>
    {props.type === 'textarea' ? (
      <TextArea {...props} />
    ) : (
      <TextInput {...props} />
    )}
  </InputWrapper>
);

const EditModal: FC<CreateBrickProps> = ({
  isOpen,
  onRequestClose,
  title,
  formik,
  brick,
  mutation,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => onRequestClose()}
    >
      <Container>
        <Title>{title}</Title>

        <Form onSubmit={formik.handleSubmit}>
          <InputHelper
            label='Name'
            id='name'
            name='name'
            type='text'
            onChange={formik.handleChange}
            value={formik.values.name}
            padding='0 2.5%'
          />

          <Flex>
            <InputHelper
              label='URL'
              id='url'
              name='url'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.url}
              width='49%'
              padding='0 5%'
            />

            <MyDropzone formik={formik} brick={brick} />
          </Flex>

          <InputHelper
            label='Free Text'
            id='text'
            name='text'
            type='textarea'
            onChange={formik.handleChange}
            value={formik.values.text}
            minHeight='129px'
            padding='0 2.5%'
          />

          <Flex marginTop='51px' justify='flex-end'>
            <ButtonHover
              label='CANCEL'
              color='white'
              onClick={() => onRequestClose()}
            />
            <ButtonHover
              label='SAVE'
              color='black'
              marginLeft='15px'
              onClick={() => mutation()}
            />
          </Flex>
        </Form>
      </Container>
    </Modal>
  );
};

export default EditModal;
