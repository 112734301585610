/**
 *
 * EditUserModal
 *
 */
// @ts-nocheck
import { FC, useEffect } from 'react';
import { Props } from './types';
import _ from 'lodash';

import Modal from 'react-modal';

import TableHeader from '../TableHeader';
import MultiSelect from '../MultiSelect';

import {
  customStylesEditModal,
  Container,
  Form,
  InputWrapper,
  Label,
  FlexRow,
  LineBreak,
} from './styles';

import ButtonHover from '../ButtonHover';
import Input from '../Input';

import AsyncSelectDropDown from '../AsyncSelectDropDown';

const EditUserModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  user,
  formik,
  mutation,
  type,
  create,
  searchOrganisations,
  goToOrganisationModal,
  userRoles,
}) => {
  const returnUserRoles = (newArr: any) => {
    if (!newArr) return;
    let reformattedArray = newArr.map((obj) => {
      return { label: obj.name, value: obj.id };
    });

    return reformattedArray;
  };

  const isValid = (formik: any) => {
    const { firstName, lastName, email, role, organisation } = formik?.values;

    let checkValues =
      firstName?.length &&
      lastName?.length &&
      email?.length &&
      (_.keys(role).includes('value') || role?.length) &&
      !_.size(formik.errors) > 0;

    if (type === 'CLIENT') {
      checkValues =
        firstName?.length &&
        lastName?.length &&
        email?.length &&
        (_.keys(organisation).includes('value') || organisation?.length) &&
        !_.size(formik.errors) > 0;
    }

    if (checkValues) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      style={customStylesEditModal}
    >
      <Container>
        <TableHeader marginTop={79}>{`${
          create ? 'CREATE' : 'EDIT'
        } ${type}`}</TableHeader>
        <Form onSubmit={formik.handleSubmit}>
          {type === 'CLIENT' && create && (
            <FlexRow alignItems='flex-end'>
              <AsyncSelectDropDown
                options={searchOrganisations}
                setFieldValue={formik.setFieldValue}
                label='Organisation'
                name='organisation'
                errors={formik.errors}
              />
              <ButtonHover
                color='black'
                label='create organisation'
                type='submit'
                marginLeft='29px'
                marginBottom='6px'
                onClick={() => goToOrganisationModal()}
              />
            </FlexRow>
          )}

          {type === 'CLIENT' && !create && (
            <FlexRow alignItems='flex-end'>
              <Input
                label='Organisation'
                id='organisation'
                name='organisation'
                type='text'
                value={formik.values.organisation}
                width='100%'
                padding='0 1.25%'
                formik={formik}
                backgroundColor='#F0F0F0'
                noBorder={true}
                cursor='not-allowed'
              />
            </FlexRow>
          )}
          <FlexRow>
            <Input
              label='First Name'
              id='firstName'
              name='firstName'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.firstName}
              padding='0 2.5%'
              width='49%'
              formik={formik}
            />

            <Input
              label='Surname'
              id='lastName'
              name='lastName'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.lastName}
              padding='0 2.5%'
              width='49%'
              formik={formik}
            />
          </FlexRow>
          <FlexRow>
            {!create ? (
              <Input
                label='Email address'
                id='email'
                name='email'
                type='email'
                onChange={null}
                value={formik.values.email}
                padding='0 2.5%'
                width='49%'
                defaultValue={user?.email}
                formik={formik}
                cursor='not-allowed'
                backgroundColor='#F0F0F0'
                noBorder={true}
              />
            ) : (
              <Input
                label='Email address'
                id='email'
                name='email'
                type='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                padding='0 2.5%'
                width='49%'
                defaultValue={user?.email}
                formik={formik}
              />
            )}

            {type === 'USER' && (
              <InputWrapper width='49%'>
                <MultiSelect
                  width={100}
                  options={returnUserRoles(userRoles)}
                  setFieldValue={formik.setFieldValue}
                  name='role'
                  placeholder={user?.userRole?.name}
                  errors={formik.errors}
                  label='Role'
                />
              </InputWrapper>
            )}
          </FlexRow>
          <Label marginTop='50px'>Optional Fields:</Label>
          <LineBreak />
          <FlexRow>
            <Input
              label='Phone'
              id='phoneNumber'
              name='phoneNumber'
              type='tel'
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              padding='0 9%'
              width='25%'
              formik={formik}
            />

            <Input
              label='Job Title'
              id='jobTitle'
              name='jobTitle'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.jobTitle}
              padding='0 2.5%'
              width='72%'
              formik={formik}
            />
          </FlexRow>
          <FlexRow
            flexDirection='row-reverse'
            justify='end'
            marginTop={52}
            style={{ marginBottom: '70px' }}
          >
            <ButtonHover
              color='black'
              label='save'
              marginLeft='20px'
              onClick={() => mutation()}
              disabled={!isValid(formik)}
              type='submit'
            />
            <ButtonHover
              color='white'
              label='cancel'
              onClick={() => onRequestClose()}
            />
          </FlexRow>
        </Form>
      </Container>
    </Modal>
  );
};

export default EditUserModal;
