/*
 * YourRoomSolutions Messages
 *
 * This contains all the text for the YourRoomSolutions component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.YourRoomSolutions.header',
    defaultMessage: 'This is the YourRoomSolutions component !',
  },
  yourRoomSolutions: {
    id: 'app.components.YourRoomSolutions.yourRoomSolutions',
    defaultMessage: 'My solutions',
  },
});
