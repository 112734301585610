import { gql } from '@apollo/client';

export const GET_ROOM = gql`
  query GetRoom(
    $id: Uuid!
    $proposalId: Uuid
    $includeNonSelectedItems: Boolean
  ) {
    room(
      id: $id
      proposalId: $proposalId
      includeNonSelectedItems: $includeNonSelectedItems
    ) {
      id
      name
      proposalId
      equipment {
        id
        status
        name
        imageUrl
        equipmentCategoryId
        isSelected
        collateral {
          id
          collateralTypeId
          name
          fileUrl
          url
          status
        }
      }
    }
  }
`;
