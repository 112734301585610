import { useState, FC } from 'react';
import UserContext from './userContext';
import { User } from './types';

const UserState: FC = ({ children }) => {
  const [userState, setUserState] = useState<User>({
    permissions: [],
    currentRouteSchema: [],
    userId: null,
  });

  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
