/**
 *
 * DropzoneNoBackground
 *
 */
//@ts-nocheck
import { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { InputWrapper, Label, Placeholder } from './styles';

const { innerWidth } = window;

const MyDropzone: FC<{ formik: any; item?: any }> = ({ formik, item }) => {
  const [label, setLabel] = useState({
    bold: 'Add file',
    normal: 'or drop files here',
    path: item?.fileUrl,
  });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        if (binaryStr) {
          formik.setFieldValue('file', binaryStr.split(',')[1]);
          formik.setFieldValue('fileName', acceptedFiles[0].path);

          console.log(formik.values);

          setLabel({
            ...label,
            bold: 'File uploaded',
            normal: '',
            path: acceptedFiles[0].path,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  let baseStyle = {
    all: 'unset',
    backgroundImage: `url("data:image/svg+xml,%3Csvg id='Group_24750' data-name='Group 24750' xmlns='http://www.w3.org/2000/svg' width='29.15' height='28.396' viewBox='0 0 29.15 28.396'%3E%3Cpath id='Path_30922' data-name='Path 30922' d='M32.676,964.375a7.067,7.067,0,0,0-5.01,2.079l-15.334,14.96A4.9,4.9,0,0,0,11,984.93a5.225,5.225,0,0,0,5.15,5.232,4.926,4.926,0,0,0,3.6-1.343L34.72,973.846a2.926,2.926,0,0,0,.946-2,2.978,2.978,0,0,0-2.99-2.99,2.917,2.917,0,0,0-2,.934l-9.355,8.981a.748.748,0,1,0,1.028,1.086l9.343-8.969c.4-.365.539-.537.981-.537a1.373,1.373,0,0,1,1.495,1.495,1.808,1.808,0,0,1-.549.981L18.7,987.756a3.374,3.374,0,0,1-2.546.911,3.732,3.732,0,0,1-3.655-3.737,3.3,3.3,0,0,1,.9-2.464l15.323-14.949a5.609,5.609,0,0,1,3.959-1.647,5.96,5.96,0,0,1,5.98,5.979,5.156,5.156,0,0,1-1.647,3.948l-15.7,15.7a.748.748,0,1,0,1.051,1.063l15.7-15.7a6.806,6.806,0,0,0,2.091-5.01A7.48,7.48,0,0,0,32.676,964.375Z' transform='translate(-11 -964.375)' fill='%23707070'/%3E%3C/svg%3E%0A")`,
    backgroundPosition: `left 22px top 50%`,
    backgroundRepeat: `no-repeat`,
    border: `2px solid #E1E2E3`,
    borderRadius: '5px',
    height: '72px',
    color: `transparent`,
    borderColor: `#E1E2E3`,
  };

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (item?.fileUrl) {
      setLabel({
        ...label,
        bold: 'File uploaded',
        normal: '',
        path: item?.fileUrl,
      });
    }
  }, [item]);

  return (
    <InputWrapper width='49%' padding='0 5%' {...getRootProps()}>
      <Label>File</Label>
      {!isDragAccept && (
        <Placeholder>
          <span>{label?.bold}</span> {innerWidth > 768 ? label?.normal : null}
        </Placeholder>
      )}
      <input
        id='file'
        name='file'
        type='file'
        title={label?.path}
        {...getInputProps({ style })}
      />
    </InputWrapper>
  );
};

export default MyDropzone;
