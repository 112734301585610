import styled from 'styled-components';
import Button from '../../components/Button';

import { mediaQueries, fonts } from '../../utils/styledTheme';

export const Title = styled.div`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  text-transform: uppercase;
  white-space: nowrap;

  ${mediaQueries.iPadPro} {
    font-size: 61px;
  }
`;

export const Row = styled.div<{
  height?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const Heading = styled('h1')`
  font-size: 61px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const TopWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const SentToColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Recipient = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap !important;
`;

export const FlexRow = styled.div`
  width: auto:
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
