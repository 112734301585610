import { gql } from "@apollo/client";

export const GET_REGIONS = gql`
    query GetRegions {
        regions {
            value: id
            label: name
        }
    }
`;


