import { useState, useEffect } from 'react';
import { colorArray } from './styles';
import { GetBricksFromProposal_proposal_bricks } from '../../utils/gql/proposals/__generated__/GetBricksFromProposal';

const move = (array: any, oldIndex: number, newIndex: number) => {
  if (newIndex >= array.length) {
    newIndex = array.length - 1;
  }

  let newArr = [...array];

  let oldItem = { ...array[oldIndex] };
  let newItem = { ...array[newIndex] };

  oldItem.colorCode = array[newIndex].colorCode;
  newItem.colorCode = array[oldIndex].colorCode;

  newArr[oldIndex] = newItem;
  newArr[newIndex] = oldItem;

  return newArr;
};

const moveElement = (array: any, index: number, offset: number) => {
  const newIndex = index + offset;
  return move(array, index, newIndex);
};

const useGridState = () => {
  const [gridState, setState] = useState<GridState>({
    items: [],
  });

  const [menu, setMenu] = useState<MenuState>({
    rowPosition: 0,
    isOpen: false,
    brick: null,
  });

  const initGrid = (bricks: any) => {
    setState({ items: bricks.filter((brick: any) => brick.status !== -1) });
  };

  const moveItem = (sourceId: number, destinationId: number) => {
    const sourceIndex = gridState.items?.findIndex(
      (item: any) => item.id === sourceId
    );

    const destinationIndex = gridState.items?.findIndex(
      (item: any) => item.id === destinationId
    );

    // If source/destination is unknown, do nothing.
    if (sourceId === -1 || destinationId === -1) {
      return;
    }

    const offset = destinationIndex - sourceIndex;

    setState((prevState) => ({
      items: moveElement(prevState.items, sourceIndex, offset),
    }));
  };

  const returnSortOrder = () =>
    gridState?.items?.map((el, index) => {
      return { id: el.id, sortOrder: index };
    });

  const returnBricksWithColors = (newArr: any) => {
    if (!newArr) return;
    let counter = 0;

    // dont show deleted bricks
    const result = newArr.filter((el: any) => el.status !== -1);

    let reformattedArray = result.map(
      (obj: GetBricksFromProposal_proposal_bricks, index: number) => {
        if (index === 0 || index % 8 === 0) {
          counter = 0;
        } else {
          counter = counter + 1;
        }
        return { ...obj, colorCode: colorArray[counter] };
      }
    );

    return reformattedArray;
  };

  const setMenuHelper = (index: number, width: number, brick: any) => {
    const colNum = width < 740 ? 3 : 4;

    // + 2 to the row position menu is always in front of selected brick
    setMenu({
      ...menu,
      index: index,
      rowPosition: Math.floor(index / colNum) + 2,
      isOpen: !menu.isOpen,
      brick: brick,
    });
  };

  useEffect(() => {
    console.log(menu);
  }, [menu]);

  return {
    gridState,
    menu,
    initGrid,
    setState,
    moveItem,
    returnBricksWithColors,
    returnSortOrder,
    setMenu,
    setMenuHelper,
  };
};

export default useGridState;
