// MetaProvider.tsx
import { contextComposer } from './contextComposer';
import SubMenuState from './submenu/SubMenuState';
import UserState from './user/UserState';
import ProposalState from './proposal/ProposalState';

const providers = [SubMenuState, UserState, ProposalState];

// @ts-ignore
export const MetaProvider = contextComposer(...providers);
