import { gql } from '@apollo/client';

export const EDIT_COLLATERAL = gql`
  mutation EditCollateral($input: EditCollateralRequestInput!) {
    editCollateral(input: $input) {
      collateral {
        id
        name
        url
        fileUrl
        equipmentId
        status
        deleteReason
        equipmentId
        collateralTypeId
      }
    }
  }
`;
