/**
 *
 * HardwareComponents
 *
 */

import React, { useEffect, useState } from 'react';
import { Props } from './types';
import { StyleWrapper, Title } from './styles';

import { ShopDisplay } from './ShopDisplay';
import { useQuery } from '@apollo/client';
import { GetRoom } from '../../utils/gql/rooms/__generated__/GetRoom';
import { GET_ROOM } from '../../utils/gql/rooms/getRoomQuery';
import { GET_PROPOSAL } from '../../utils/gql/proposals/proposalsQuery';
import { useParams } from 'react-router';

function HardwareComponents({
  selectedRoom,
  meetingSpaces,
  readOnly,
  setSelectedRoom,
  includeNonSelectedItems,
}: Props) {
  const { id: proposalId }: any = useParams();

  const { data, refetch } = useQuery<GetRoom>(GET_ROOM, {
    variables: {
      id: selectedRoom?.id,
      proposalId,
      includeNonSelectedItems: includeNonSelectedItems,
    },
    skip: !selectedRoom,
    fetchPolicy: 'network-only',
  });

  const { data: proposalData } = useQuery(GET_PROPOSAL, {
    variables: { id: proposalId },
  });

  const [videoState, setVideoState] = useState<any>([]);
  const [byoState, setByoState] = useState<any>([]);
  const [audioState, setAudioState] = useState<any>([]);
  const [accessoriesState, setAccessoriesState] = useState<any>([]);

  useEffect(() => {
    selectedRoom &&
      refetch({ id: selectedRoom?.id, proposalId })?.then(({ data }) => {
        const video = data?.room?.equipment
          ?.filter((eq: any) => eq.equipmentCategoryId === 0)
          .filter((eq: any) => eq.status !== -1)
          .map((equipment) => ({
            ...equipment,
            roomId: data?.room?.id,
            proposalId: data?.room?.proposalId,
          }));
        const byo = data?.room?.equipment
          ?.filter((eq: any) => eq.equipmentCategoryId === 1)
          .filter((eq: any) => eq.status !== -1)
          .map((equipment) => ({
            ...equipment,
            roomId: data?.room?.id,
            proposalId: data?.room?.proposalId,
          }));
        const audio = data?.room?.equipment
          ?.filter((eq: any) => eq.equipmentCategoryId === 2)
          .filter((eq: any) => eq.status !== -1)
          .map((equipment) => ({
            ...equipment,
            roomId: data?.room?.id,
            proposalId: data?.room?.proposalId,
          }));
        const accessories = data?.room?.equipment
          ?.filter((eq: any) => eq.equipmentCategoryId === 3)
          .filter((eq: any) => eq.status !== -1)
          .map((equipment) => ({
            ...equipment,
            roomId: data?.room?.id,
            proposalId: data?.room?.proposalId,
          }));
        setVideoState(video);
        setByoState(byo);
        setAudioState(audio);
        setAccessoriesState(accessories);
      });
  }, [selectedRoom]);

  const video = data?.room?.equipment
    ?.filter((eq: any) => eq.equipmentCategoryId === 0)
    .filter((eq: any) => eq.status !== -1)
    .map((equipment) => ({
      ...equipment,
      roomId: data?.room?.id,
      proposalId: data?.room?.proposalId,
    }));
  const byo = data?.room?.equipment
    ?.filter((eq: any) => eq.equipmentCategoryId === 1)
    .filter((eq: any) => eq.status !== -1)
    .map((equipment) => ({
      ...equipment,
      roomId: data?.room?.id,
      proposalId: data?.room?.proposalId,
    }));
  const audio = data?.room?.equipment
    ?.filter((eq: any) => eq.equipmentCategoryId === 2)
    .filter((eq: any) => eq.status !== -1)
    .map((equipment) => ({
      ...equipment,
      roomId: data?.room?.id,
      proposalId: data?.room?.proposalId,
    }));

  const accessories = data?.room?.equipment
    ?.filter((eq: any) => eq.equipmentCategoryId === 3)
    .filter((eq: any) => eq.status !== -1)
    .map((equipment) => ({
      ...equipment,
      roomId: data?.room?.id,
      proposalId: data?.room?.proposalId,
    }));

  if (!meetingSpaces || !selectedRoom) {
    return null;
  }

  const readOnlyDisplay = () => {
    if (readOnly) {
      return (
        <React.Fragment>
          {videoState?.length ? (
            <ShopDisplay
              query={() => {
                setSelectedRoom({
                  name: selectedRoom?.name,
                  id: selectedRoom?.id,
                });
                refetch({ id: selectedRoom?.id, proposalId });
              }}
              type='Video solutions'
              shopItems={video}
              readOnly={readOnly}
            />
          ) : null}
          {byoState?.length ? (
            <ShopDisplay
              query={() => refetch({ id: selectedRoom?.id, proposalId })}
              type='BYO'
              shopItems={byo}
              readOnly={readOnly}
            />
          ) : null}
          {audioState?.length ? (
            <ShopDisplay
              query={() => refetch({ id: selectedRoom?.id, proposalId })}
              type='Audio'
              shopItems={audio}
              readOnly={readOnly}
            />
          ) : null}
          {accessoriesState?.length ? (
            <ShopDisplay
              query={() => refetch({ id: selectedRoom?.id, proposalId })}
              type='Accessories'
              shopItems={accessories}
              readOnly={readOnly}
            />
          ) : null}
        </React.Fragment>
      );
    }
  };

  return (
    <StyleWrapper>
      {selectedRoom && data && (
        <React.Fragment>
          {selectedRoom && (
            <Title>
              {data?.room?.name} spaces{' '}
              <span style={{ color: '#000' }}>AT</span>
              {' ' + proposalData?.proposal?.organisation?.name}
            </Title>
          )}
          {readOnly ? (
            readOnlyDisplay()
          ) : (
            <React.Fragment>
              {video?.length ? (
                <ShopDisplay
                  query={() => {
                    setSelectedRoom({
                      name: selectedRoom?.name,
                      id: selectedRoom?.id,
                    });
                    refetch({ id: selectedRoom?.id, proposalId });
                  }}
                  type='Video solutions'
                  shopItems={video}
                  readOnly={readOnly}
                />
              ) : null}
              {byo?.length ? (
                <ShopDisplay
                  query={() => refetch({ id: selectedRoom?.id, proposalId })}
                  type='BYO'
                  shopItems={byo}
                  readOnly={readOnly}
                />
              ) : null}
              {audio?.length ? (
                <ShopDisplay
                  query={() => refetch({ id: selectedRoom?.id, proposalId })}
                  type='Audio'
                  shopItems={audio}
                  readOnly={readOnly}
                />
              ) : null}
              {accessories?.length ? (
                <ShopDisplay
                  query={() => refetch({ id: selectedRoom?.id, proposalId })}
                  type='Accessories'
                  shopItems={accessories}
                  readOnly={readOnly}
                />
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </StyleWrapper>
  );
}

export default HardwareComponents;
