// @ts-nocheck
import React, { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DragItem: React.FC<DragItemProps> = memo(
  ({ id, onMoveItem, children, name, colorCode, loading, type, editMode }) => {
    const ref = useRef(null);

    const returnCanDrag = (loading: boolean, type: BRICKTYPE) => {
      if (type === 'PREVIEW' || type === 'CLICK_STATS' || !editMode) {
        return false;
      }
      return !loading;
    };

    const [{ isDragging }, connectDrag] = useDrag(
      {
        item: { id, type: 'IMG', name, colorCode },
        canDrag: returnCanDrag(loading, type),
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      },
      [loading]
    );

    const [, connectDrop] = useDrop({
      accept: 'IMG',
      hover(hoveredOverItem) {
        if (hoveredOverItem.id !== id) {
          onMoveItem(hoveredOverItem.id, id);
        }
      },
    });

    connectDrag(ref);
    connectDrop(ref);

    const opacity = isDragging ? 0.5 : 1;
    const containerStyle = { opacity, transform: 'translateZ(0)' };

    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        forwardedRef: ref,
        style: containerStyle,
      })
    );
  }
);

export default DragItem;
