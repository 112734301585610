import React from 'react';
import { SlideWrapper, SlideBackground, StyledButton } from './styles';

export const Slide = ({ image, name, onClick, isSelected }: any) => {
  return (
    <SlideWrapper onClick={onClick}>
      <SlideBackground src={image} />
      <StyledButton isSelected={isSelected}>{name}</StyledButton>
    </SlideWrapper>
  );
};
