import { GlobalStyle } from './utils/globalStyle';
import Header from './components/header';
import Footer from './components/Footer';
import AppWrapper from './AppWrapper';
import routes from './routes';
import SubHeader from './components/SubHeader';
import RouteWrapper from './components/RouteWrapper';
import Modal from 'react-modal';
import IdleTimer from 'react-idle-timer';
import { useState, createRef } from 'react';
import { isLoggedInVar } from './apollo/cache';

Modal.setAppElement('#root');

const App = () => {
  const [timedOut, setTimedOut] = useState<boolean>(false);
  const idleTimer: any = createRef();
  const onAction = (event: any) => setTimedOut(false);
  const onIdle = (event: any) => {
    if (!isLoggedInVar()) return;
    if (timedOut) {
      isLoggedInVar(false);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      return;
    }
    idleTimer.current.reset();
    setTimedOut(true);
  };
  return (
    <AppWrapper>
      <IdleTimer
        key='idleTimer'
        startOnMount
        ref={idleTimer}
        element={document}
        onActive={onAction}
        onIdle={onIdle}
        timeout={1000 * 60 * 30}
      />

      <GlobalStyle />
      <Header />
      <SubHeader />
      <RouteWrapper>{routes}</RouteWrapper>
      <Footer />
    </AppWrapper>
  );
};

export default App;
