/**
 *
 * Slider
 *
 */

import styled from 'styled-components';

import { colors, fonts, mediaQueries } from '../../utils/styledTheme';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  position: relative;

  width: 740px;
  height: auto;
  margin: 0 auto;

  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid ${colors.brightBlue};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -18px;
  }

  /* All the same stuff for Firefox */
  input[type='range']::-moz-range-thumb {
    border: 2px solid ${colors.brightBlue};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -18px;
  }

  /* All the same stuff for IE */
  input[type='range']::-ms-thumb {
    border: 2px solid ${colors.brightBlue};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -18px;
  }

  /* Track */
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: ${colors.brightBlue};
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: ${colors.brightBlue};
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: ${colors.brightBlue};
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: ${colors.brightBlue};
  }
  input[type='range']::-ms-fill-lower {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: ${colors.brightBlue};
  }
  input[type='range']:focus::-ms-fill-lower {
    background: ${colors.brightBlue};
  }
  input[type='range']::-ms-fill-upper {
    background: ${colors.brightBlue};
  }
  input[type='range']:focus::-ms-fill-upper {
    background: ${colors.brightBlue};
  }

  ${mediaQueries.iPadPro} {
    width: 861px;
  }
`;

export const StyledSlider = styled.input`
  width: 100%;
  height: 36px;
`;

export const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled(motion.div)`
  color: ${colors.fontColor};
  font-family: ${fonts.bold}
  font-size: 20px;
`;

export const Question = styled.div<{ marginTop?: number }>`
  margin-top: ${(props) => props.marginTop}px;
  color: ${colors.fontColor};
  font-family: ${fonts.normal};
  font-size: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 38px;
  max-height: 64px;
  text-align: center;
`;
