import { gql } from '@apollo/client';

export const REMOVE_ROOM_FROM_PROPOSAL = gql`
  mutation RemoveRoomFromProposal($input: RemoveRoomFromProposalRequestInput!) {
    removeRoomFromProposal(input: $input) {
      proposal {
        id
        name
        rooms {
          id
          name
          imageUrl
          iconUrl
          equipment {
            id
            name
            imageUrl
          }
        }
      }
    }
  }
`;
