import { useState } from 'react';
import { DELETE_ORGANISATION } from '../../utils/gql/organisations/deleteOrganisation';
import { EDIT_ORGANISATION } from '../../utils/gql/organisations/editOrganisationMutation';
import { SearchOrganisastionsTable } from '../../utils/gql/organisations/__generated__/SearchOrganisastionsTable';
import { SEARCH_ORGANISATIONS_TABLE } from '../../utils/gql/organisations/searchOrganisationsQuery';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect, useReducer } from 'react';
import organisationReducer from './organisationTableReducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getImage } from '../../utils/helperFunctions/imageToBase64';
import { CREATE_ORGANISATION } from '../../utils/gql/organisations/createOrganisationMutation';
import useDebounce from '../../utils/hooks/useDebounce';

export const organisationSchema = Yup.object().shape({
  organisationName: Yup.string()
    .min(3, 'Must be at least 3 characters long')
    .required('Required field'),
  regionId: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string().required('Required field'),
    })
    .required('Required field'),
  organisationDomains: Yup.array()
    .min(1, 'Please make sure to provide at least one domain')
    .of(
      Yup.object()
        .shape({
          value: Yup.string()
            .matches(
              /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/g,
              { message: 'Invalid domain' }
            )
            .required('No value entered'),
          label: Yup.string(),
        })
        .required('Please make sure to provide at least one domain')
    ),
});

const OrganisationState = () => {
  const initialState = {
    selectedOrganisation: {},
    selectedModal: {
      editModal: false,
      deleteModal: false,
      createModal: false,
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const refetchHelper = () => {
    if (searchTerm === '' || searchTerm === null) {
      refetch({
        input: { name: null, skip: null, take: null },
      });
    } else if (debouncedSearchTerm) {
      refetch({
        input: { name: debouncedSearchTerm, skip: null, take: null },
      });
    }
  };

  useEffect(() => {
    refetchHelper();

    // eslint-disable-next-line
  }, [searchTerm, debouncedSearchTerm]);

  const { loading, error, data, refetch } = useQuery<SearchOrganisastionsTable>(
    SEARCH_ORGANISATIONS_TABLE,
    { variables: { input: { name: '', skip: 0, take: null } } }
  );

  const [state, dispatch] = useReducer(organisationReducer, initialState);

  const { selectedOrganisation, selectedModal } = state;

  const setCreateModal = () => {
    dispatch({
      type: 'SET_CREATE_MODAL',
    });
  };

  const setEditModal = (selectedOrganisation: any) => {
    dispatch({
      type: 'SET_EDIT_MODAL',
      payload: { selectedOrganisation },
    });
  };

  const setDeleteModal = (selectedOrganisation: any) => {
    dispatch({
      type: 'SET_DELETE_MODAL',
      payload: { selectedOrganisation },
    });
  };

  const resetAllModals = () => {
    dispatch({
      type: 'RESET_ALL_MODALS',
    });
  };

  // FORMIK
  const createOrganisationFormik = useFormik({
    initialValues: {
      organisationName: '',
      regionId: {
        label: '',
        value: '',
      },
      organisationDomains: [],
      organisationLogo: undefined,
      imgPreview: undefined,
      imageUrl: '',
      logoImageName: '',
    },
    validationSchema: organisationSchema,
    enableReinitialize: true,
    onSubmit: (values) => console.log(values),
  });

  const editOrganisationFormik = useFormik({
    initialValues: {
      organisationName: selectedOrganisation?.organisationName,
      regionId: {
        label: selectedOrganisation?.region,
        value: selectedOrganisation?.regionId,
      },
      organisationDomains: selectedOrganisation?.organisationDomains?.map(
        (domain: string) => ({
          label: domain?.replace(',', '').replace(' ', '').replace(', ', ''),
          value: domain?.replace(',', '').replace(' ', '').replace(', ', ''),
        })
      ),
      organisationLogo: undefined,
      imgPreview: selectedOrganisation?.logo?.props?.src,
      imageUrl: selectedOrganisation?.logoUrl,
      logoImageName: selectedOrganisation?.logoUrl,
      id: selectedOrganisation?.id,
      imageName: '',
    },
    enableReinitialize: true,
    validationSchema: organisationSchema,
    onSubmit: (values) => console.log(values),
  });

  // MUTATIONS
  const [deleteOrganisation] = useMutation(DELETE_ORGANISATION);

  const [editOrganisation] = useMutation(EDIT_ORGANISATION);

  //   EDIT MUTATION
  const editOrganisationMutation = async () => {
    const base64: any = await getImage(selectedOrganisation.imageUrl);
    const replaceLogo: any = editOrganisationFormik.values.organisationLogo;
    let input: any = {
      id: editOrganisationFormik.values.id,
      name: editOrganisationFormik.values.organisationName,
      regionId: editOrganisationFormik.values.regionId.value,
      status: 0,
      domains: editOrganisationFormik.values.organisationDomains.map(
        (domain: { value: string; label: string }) => domain.value
      ),
    };
    if (
      editOrganisationFormik.values?.imageName &&
      editOrganisationFormik.values?.imageUrl
    ) {
      input.logo = editOrganisationFormik.values.imageUrl;
      input.logoImageName = editOrganisationFormik.values?.imageName;
    }
    editOrganisation({
      variables: { input },
    })
      .then((res) => {
        enqueueSnackbar('Organisation updated successfully!', {
          variant: 'success',
        });
        resetAllModals();
        refetchHelper();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  // DELETE MUTATION
  const deleteOrganisationMutation = () => {
    let input = { id: selectedOrganisation.id };
    deleteOrganisation({
      variables: { input },
      refetchQueries: [
        {
          query: SEARCH_ORGANISATIONS_TABLE,
          variables: { input: { name: null, skip: null, take: null } },
        },
      ],
    })
      .then((response) => {
        resetAllModals();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  // CREATE MUTATION

  const [createOrganisation] = useMutation(CREATE_ORGANISATION);

  const createOrganisationMutation = (searchTerm: string) => {
    let input = {
      name: createOrganisationFormik.values.organisationName,
      regionId: createOrganisationFormik?.values?.regionId?.value,
      domains: createOrganisationFormik.values.organisationDomains.map(
        (domain: any) => domain.value
      ),
      logo: createOrganisationFormik.values.imageUrl,
      logoImageName: createOrganisationFormik.values.logoImageName,
    };
    createOrganisation({
      variables: { input },
    })
      .then((response) => {
        enqueueSnackbar('Organisation successfully created!', {
          variant: 'success',
        });
        resetAllModals();
        createOrganisationFormik.resetForm({});
        refetchHelper();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    console.log(selectedOrganisation);
  }, [selectedOrganisation]);

  return {
    // mutations
    deleteOrganisationMutation,
    editOrganisationMutation,
    createOrganisationMutation,

    // forms
    createOrganisationFormik,
    editOrganisationFormik,

    // modals
    setCreateModal,
    setEditModal,
    setDeleteModal,
    selectedModal,
    resetAllModals,

    // selected organisation
    selectedOrganisation,

    // search term
    searchTerm,
    setSearchTerm,
    data,
  };
};

export default OrganisationState;
