import { gql } from '@apollo/client';

export const DELETE_COLLATERAL = gql`
  mutation DeleteCollateral($input: DeleteCollateralRequestInput!) {
    deleteCollateral(input: $input) {
      collateral {
        id
        name
        url
        fileUrl
        equipmentId
        status
        collateralTypeId
        equipmentId
      }
    }
  }
`;
