// @ts-nocheck
import React, { useEffect } from 'react';
import {
  useStyles,
  customStylesEditModal,
  InputWrapper,
  Form,
  FlexRow,
} from './styles';
import { Grid, Typography, InputLabel } from '@material-ui/core';
import { Field, FormikProvider } from 'formik';
import ButtonHover from '../../ButtonHover';
import FileDrop from '../../FileDrop';
import { useQuery } from '@apollo/client';
import { GetRegions } from '../../../utils/gql/regions/__generated__/GetRegions';
import { GET_REGIONS } from '../../../utils/gql/regions/regionsQuery';
import MultiSelect from '../../MultiSelect';
import Modal from 'react-modal';
import TextField from '../../FormFields/TextField';
import { Props } from './types';
import _ from 'lodash';

const CreateOrganisationForm = ({
  onRequestClose,
  create,
  isOpen,
  formik,
  type,
  organisation,
  mutation,
}: Props) => {
  const classes = useStyles();

  const { data: regionsData } = useQuery<GetRegions>(GET_REGIONS);

  const formatErrors = (name: string, errors: any) => {
    if (typeof errors[name] === 'string') {
      return {
        [name]: {
          value: errors[name],
        },
      };
    }

    return {
      [name]: {
        value: _.isArray(errors[name])
          ? errors[name]?.find((e) => e?.value)?.value
          : errors[name]?.value,
      },
    };
  };

  // @ts-ignore
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      style={customStylesEditModal}
    >
      <Grid component={Form} container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.formTitle}>
            {`${create ? 'CREATE' : 'EDIT'} ${type}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormikProvider value={formik}>
            <InputLabel className={classes.inputLabel}>
              Organisation name *
            </InputLabel>
            <TextField
              name='organisationName'
              variant='outlined'
              onChange={formik.handleChange}
              value={formik.values.organisationName}
              marginTop='10px'
              helperText={formik.errors.organisationName}
              error={formik.errors.organisationName}
            />
          </FormikProvider>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputWrapper width='100%'>
                <InputLabel className={classes.inputLabel}>
                  Organisation Email Domain(s) *
                </InputLabel>
                <MultiSelect
                  name='organisationDomains'
                  addNew={true}
                  // @ts-ignore
                  options={[]}
                  isMulti={true}
                  setFieldValue={formik.setFieldValue}
                  width={100}
                  errors={formatErrors('organisationDomains', formik.errors)}
                  value={formik.values?.organisationDomains}
                  placeholder='For example, logitech.com'
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper width='100%'>
                <InputLabel className={classes.inputLabel}>Region</InputLabel>
                <MultiSelect
                  name='regionId'
                  addNew={false}
                  // @ts-ignore
                  options={regionsData?.regions}
                  setFieldValue={formik.setFieldValue}
                  width={100}
                  value={formik.values?.regionId}
                  errors={formatErrors('regionId', formik.errors)}
                />
              </InputWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            alignItems='center'
            justifyContent='center'
            container
            spacing={3}
          >
            <Grid item xs={12}>
              <FormikProvider value={formik}>
                <Field
                  marginTop={'0'}
                  imagePlaceholder='upload your logo or a PNG file'
                  height='199px'
                  enablePreview
                  label='Logo'
                  as={FileDrop}
                  name='organisationLogo'
                  id='organisationLogo'
                  htmlFor='organisationLogo'
                  value={formik.values.organisationLogo}
                  onChange={(event: any) => {
                    formik.setFieldValue(
                      'organisationLogo',
                      event.target.files[0]
                    );
                  }}
                />
              </FormikProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FlexRow justify='flex-end'>
            <ButtonHover
              color='white'
              label='cancel'
              onClick={() => onRequestClose()}
            />
            <ButtonHover
              color='black'
              label={`${create ? `CREATE ${type}` : 'SAVE'} `}
              onClick={mutation}
              marginLeft='20px'
              disabled={!formik.isValid}
            />
          </FlexRow>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateOrganisationForm;
