/**
 *
 * Main container element for app
 *
 */

import styled from 'styled-components';
import { mediaQueries, constants } from '../../utils/styledTheme';

const { iPad, iPadPro, desktop } = constants.mediaQueries;

const Container = styled.div<{
  ipadWidth?: number;
  ipadProWidth?: number;
  desktopWidth?: number;
  marginTop?: number;
  marginBottom?: number;
  background?: string;
  paddingBottom?: number;
}>`
  margin-left: auto;
  margin-right: auto;
  height: auto;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginTop}px;
  width: ${(props) => props.ipadWidth || iPad}px;
  background: ${(props) => props.background || `#F7F7F7`};
  padding-bottom: ${(props) => props.paddingBottom}px;

  ${mediaQueries.iPadPro} {
    width: ${(props) => props.ipadProWidth || iPadPro - 28}px;
  }

  ${mediaQueries.desktop} {
    width: ${(props) => props.desktopWidth || desktop}px;
  }
`;

export default Container;
