/**
 *
 * ButtonHover
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonProps } from './types';
import { fonts } from '../../utils/styledTheme';

export const Button = styled(motion.button)<ButtonProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  padding-left: ${(props) => props.paddingLeft || '70px'};
  padding-right: ${(props) => props.paddingRight || '70px'};
  background: ${(props) => props.background || '#2e3132'};
  color: ${(props) => props.color || '#fff'};
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;

  height: ${(props) => props.height || '64px'};
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  border: 2px solid #2e3132;
  font-family: ${fonts.bold};
  font-size: ${(props) => props.fontSize || '20px'};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  white-space: nowrap;
`;
