/**
 *
 * WorkspaceModal
 *
 */

import styled from 'styled-components';
import { fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  margin: 50px auto 0;
  max-width: 1249px;
`;

export const Title = styled.div`
  font-size: 61px;
  color: #393b3a;
  text-transform: uppercase;
  font-family: ${fonts.bold};
`;

export const Flex = styled.div<{ justify?: string; marginTop?: number }>`
  margin-top: ${(props) => props.marginTop}px;
  display: flex;
  justify-content: ${(props) => props.justify || 'space-between'};
`;

export const Label = styled.div`
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-family: ${fonts.bold};
  color: black;
  margin-bottom: 10px;
`;
