import React, { useEffect, useRef } from 'react';
import DragItem from './DragItem';
import AddProposal from './AddProposal';
import { Grid } from './styles';
import GridItem from './GridItem';
import Menu from './Menu';
import useGridState from './useGridState';

// graphQl

import { UPDATE_BRICKS_SORT_ORDER } from '../../utils/gql/updateBricksSortOrder/updateBricksSortOrderMutation';
import { useMutation } from '@apollo/client';

const ProposalGrid: React.FC<ProposalGridProps> = ({
  bricks,
  refetchBricks,
  type,
  editMode,
  proposalId,
  setAddBrick,
}) => {
  const {
    gridState,
    menu,
    moveItem,
    initGrid,
    returnBricksWithColors,
    returnSortOrder,
    setMenu,
    setMenuHelper,
  } = useGridState();
  const { items } = gridState;

  useEffect(() => {
    initGrid(returnBricksWithColors(bricks));
    setMenu({ ...menu, isOpen: false });

    // eslint-disable-next-line
  }, [bricks]);

  const [updateBricksSortOrder, { loading }] = useMutation<any>(
    UPDATE_BRICKS_SORT_ORDER
  );

  const updateBricksSortOrderHelper = () => {
    let input = {
      bricks: returnSortOrder(),
    };

    updateBricksSortOrder({
      variables: { input },
    })
      .then((res) => {
        refetchBricks({
          input: { variables: { id: proposalId } },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateBricksSortOrderHelper();
    // eslint-disable-next-line
  }, [gridState.items]);

  // needed to calculate colNum for menu pos
  const width = useRef<HTMLDivElement>(null);

  const returnAddProposal = () => {
    if (type === 'DEFAULT' || type === 'CMS') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    console.log('editMode', editMode);
  }, [editMode]);

  return (
    <Grid ref={width}>
      {items?.map((brick, index) => (
        <DragItem
          key={brick.id}
          onMoveItem={moveItem}
          id={brick.id}
          name={brick.name}
          colorCode={brick.colorCode}
          loading={loading}
          type={type}
          editMode={editMode}
        >
          {width?.current && (
            <GridItem
              sortOrder={index}
              brick={brick}
              setMenuHelper={setMenuHelper}
              menu={menu}
              width={width.current}
              refetchBricks={refetchBricks}
              editMode={editMode}
              proposalId={proposalId}
              type={type}
            />
          )}
        </DragItem>
      ))}

      {returnAddProposal() && editMode && (
        <AddProposal setAddBrick={setAddBrick} />
      )}

      <Menu
        gridRow={menu.rowPosition}
        isOpen={menu.isOpen}
        onClose={() => setMenu({ ...menu, isOpen: false })}
        brick={menu?.brick}
        editMode={editMode}
        type={type}
      />
    </Grid>
  );
};

export default ProposalGrid;
