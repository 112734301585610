import { gql } from '@apollo/client';

export const CREATE_COLLATERAL = gql`
  mutation CreateCollateral($input: CreateCollateralRequestInput!) {
    createCollateral(input: $input) {
      collateral {
        id
        name
        url
        fileUrl
        equipmentId
        status
        collateralTypeId
        equipmentId
      }
    }
  }
`;
