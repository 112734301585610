/**
 *
 * InlineWysiwygField
 *
 */

import React, { useEffect, useState } from 'react';
import { Props } from './types';
import { StyleWrapper } from './styles';

import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import DOMPurify from 'dompurify';
import { useFormikContext } from 'formik';

const InlineWysiwygField = ({ editMode, value, name }: Props) => {
  const [editState, setEditState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState<ContentState>(
    ContentState.createFromText('')
  );
  const { values, setFieldValue, initialValues } = useFormikContext();

  function htmlDecode(input: string) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  const onEditorStateChange = (editorState: any) => {
    // @ts-ignore
    setEditState(editorState);
  };

  const onContentStateChange = (contentState: any) => {
    setFieldValue(name, draftToHtml(contentState));
  };

  useEffect(() => {
    // @ts-ignore
    if (!!initialValues?.[name]) {
      // @ts-ignore
      const blocksFromHTML = convertFromHTML(initialValues?.[name]);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      setEditState(EditorState.createWithContent(state));
    }
  }, [initialValues]);

  if (!!editMode) {
    return (
      <StyleWrapper>
        <Editor
          editorState={editState}
          onEditorStateChange={onEditorStateChange}
          onContentStateChange={onContentStateChange}
          // @ts-ignore
          contentState={contentState}
          toolbar={{
            options: ['inline', 'list', 'link'],
            inline: {
              options: ['bold', 'italic'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
        />
      </StyleWrapper>
    );
  }

  // use Dompurify to sanitize the inner html
  return (
    <StyleWrapper>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            draftToHtml(convertToRaw(editState.getCurrentContent()))
          ),
        }}
      />
    </StyleWrapper>
  );
};

export default InlineWysiwygField;
