/**
 *
 * TableHeader
 *
 */

import styled from 'styled-components';

import { mediaQueries, fonts } from '../../utils/styledTheme';

const TableHeader = styled.div<{ marginTop?: number }>`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: ${(props) => props.marginTop}px;

  ${mediaQueries.iPadPro} {
    font-size: 61px;
  }
`;

export default TableHeader;
