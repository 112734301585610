/**
 *
 * CmsTabs
 *
 */

import { FC } from 'react';
import { Props } from './types';
import { Tabs, Tab } from './styles';

import Container from '../../components/Container';
import { colors } from '../../utils/styledTheme';

const CmsTabs: FC<Props> = ({ tabs, changeTab }) => {
  return (
    <Container>
      <Tabs>
        {tabs?.map((tab: any, index: number) => (
          <Tab
            key={index}
            initial={false}
            animate={{
              color: tab?.toggle ? colors.brightBlue : colors.logitechBlack,
              borderColor: tab?.toggle
                ? colors.brightBlue
                : colors.logitechBlack,
            }}
            borderBottom={tab?.toggle}
            onClick={() => changeTab(index)}
          >
            {tab?.label}
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default CmsTabs;
