// @ts-nocheck
import { FC, useEffect, useState } from 'react';

// libraries
import Modal from 'react-modal';
import { Formik } from 'formik';

// styles
import {
  FormStyled,
  Label,
  Input,
  CheckBoxWrapper,
  CheckBox,
  Text,
  Submit,
  customStylesLogin,
} from './styles';

// comps
import Logo from '../Logo';
import { colors } from '../../utils/styledTheme';

// graphql
import { useMutation } from '@apollo/client';
import { SET_PASSWORD } from '../../utils/gql/users/setPasswordMutation';
import useQueryParams from '../../utils/hooks/useQuery';

const initialValues: {
  password: string;
  password2: string;
  checked: Array<any>;
} = {
  password: '',
  password2: '',
  checked: [],
};

const CreateNewPasswordModal: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const [displayModal, setDisplayModal] = useState<boolean>(true);
  const [setPassword, { data, loading, error }] =
    useMutation<any>(SET_PASSWORD);

  const returnSubmitColor = (
    password: string | undefined,
    password2: string | undefined,
    checked?: Array<any>
  ) => {
    if (password === '' || password2 === '' || checked[0] !== 'true') {
      return colors.grey;
    }

    if (password === password2 && checked[0] === 'true') {
      return colors.lightBlue;
    }

    return colors.grey;
  };

  const validate = ({ password, password2, checked }) => {
    if (password === '' || password2 === '' || checked[0] !== 'true') {
      return false;
    }

    if (password === password2 && checked[0] === 'true') {
      return true;
    }
    return false;
  };

  const returnAccessKey = () => {
    return query.get('accessKey');
  };

  let query = useQueryParams();

  if (!displayModal) {
    return false;
  }

  return (
    <Modal isOpen={isOpen} style={customStylesLogin}>
      <Logo marginTop='90px' />
      <Label>
        Create a new password, to access your Proposal. You will then be asked
        to login
      </Label>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (values.password !== values.password2) return;

          if (values.checked[0] !== 'true') return;

          setPassword({
            variables: {
              input: {
                userId: null,
                password: values.password,
                accessKey: returnAccessKey(),
              },
            },
          })
            .then((data) => {
              setDisplayModal(false);
            })
            .catch((error) => console.log(error));
        }}
      >
        {({ values }) => (
          <FormStyled>
            <Input
              id='password'
              type='password'
              name='password'
              placeholder='Create new Password'
            />
            <Input
              id='password2'
              type='password'
              name='password2'
              marginTop='15px'
              placeholder='Confirm password'
            />
            <CheckBoxWrapper>
              <CheckBox type='checkbox' name='checked' value='true' />
              <Text>
                I agree to the{' '}
                <span>
                  <a
                    href='https://www.logitech.com/en-gb/tos/terms.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Terms & Conditions
                  </a>
                </span>
              </Text>
            </CheckBoxWrapper>
            <Submit
              value='SUBMIT'
              type='submit'
              color='white'
              animate={{
                background: returnSubmitColor(
                  values.password,
                  values.password2,
                  values.checked
                ),
              }}
              initial={false}
              transition={{ duration: 0.5 }}
              disabled={!validate(values)}
            />
          </FormStyled>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateNewPasswordModal;
