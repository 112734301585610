// @ts-nocheck
import { useReducer, useEffect } from 'react';
import ProposalTableReducer from './proposalTableReducer';
import { useHistory } from 'react-router';

import { useFormik } from 'formik';

// graphQl
import { useMutation, useQuery } from '@apollo/client';
import { EDIT_PROPOSAL } from '../../utils/gql/proposals/editProposalMutation';
import { EditProposal } from '../../utils/gql/proposals/__generated__/EditProposal';
import { SEARCH_USERS } from '../../utils/gql/users/searchUsersQuery';
import { SearchUsers } from '../../utils/gql/users/__generated__/SearchUsers';

import { SHARE_PROPOSAL } from '../../utils/gql/proposals/shareProposalMutation';
import { ShareProposal } from '../../utils/gql/proposals/__generated__/ShareProposal';
import { MY_PROPOSALS } from '../../utils/gql/proposals/myProposalQuery';

const useProposalTableState = () => {
  // state for the search term and drop down
  const formikSearch = useFormik({
    initialValues: {
      searchTerm: null,
      status: 2,
    },
  });

  const { data: proposalData, refetch: refetchProposals } =
    useQuery<MyProposals>(MY_PROPOSALS, {
      variables: {
        input: {
          searchTerm: formikSearch.values.searchTerm,
        },
      },
      fetchPolicy: 'network-only',
    });

  const initialState = {
    selectedProposal: {
      id: null,
    },

    selectedModal: {
      deleteModal: false,
      snapShotModal: false,
      shareModal: false,
    },

    goToEditProposalPage: false,
    goToSummaryPage: false,
    startTime: null,
    endTime: null,
    userIds: [],
    sentTo: [],
  };

  const [state, dispatch] = useReducer(ProposalTableReducer, initialState);

  const {
    selectedModal,
    selectedProposal,
    goToEditProposalPage,
    goToSummaryPage,
    startTime,
    endTime,
  } = state;

  const setSnapShotModal = (selectedProposal: any) => {
    dispatch({
      type: 'SET_SNAPSHOT_MODAL',
      payload: { selectedProposal },
    });
  };

  const goToEditProposal = (selectedProposal: any) => {
    dispatch({
      type: 'GO_TO_EDIT_PROPOSAL',
      payload: { selectedProposal },
    });
  };

  const setDeleteModal = (selectedProposal: any) => {
    dispatch({
      type: 'SET_DELETE_MODAL',
      payload: { selectedProposal },
    });
  };

  const setShareModal = (selectedProposal: any) => {
    const { sentTo }: any = selectedProposal;
    const payload = sentTo.map((u) => ({
      label: u.email,
      value: u.id,
      isFixed: u.id === selectedProposal.customerId,
    }));
    dispatch({
      type: 'SET_USER_IDS',
      payload,
    });
    dispatch({
      type: 'SET_SHARE_MODAL',
      payload: { selectedProposal },
    });
  };

  const goToSummary = (selectedProposal: any) => {
    dispatch({
      type: 'GO_TO_SUMMARY',
      payload: { selectedProposal },
    });
  };

  const resetAllModals = () => {
    dispatch({
      type: 'RESET_ALL_MODALS',
    });
  };

  let history = useHistory();

  const goToProposal = (proposalId: string) => [
    history.push(`/proposals/view/${proposalId}`),
  ];

  useEffect(() => {
    if (goToEditProposalPage) {
      goToProposal(selectedProposal.id);
    }
    // eslint-disable-next-line
  }, [goToEditProposalPage]);

  const goToSummaryHelper = (proposalId: string) => [
    history.push(`/proposals/summary-of-activity/${proposalId}`),
  ];

  useEffect(() => {
    if (goToSummaryPage) {
      goToSummaryHelper(selectedProposal.id);
    }

    // eslint-disable-next-line
  }, [goToSummaryPage]);

  // MUTATIONS
  const [editProposal, editProposalRes] =
    useMutation<EditProposal>(EDIT_PROPOSAL);

  const deleteProposalMutation = () => {
    editProposal({
      variables: {
        input: { id: selectedProposal?.id, status: -1 },
      },
    })
      .then((res) => {
        resetAllModals();
        refetchProposals({
          variables: { input: { searchTerm: formikSearch.values.searchTerm } },
        });
      })
      .catch((err) => {
        resetAllModals();
      });
  };

  const { data, refetch } = useQuery<SearchUsers>(SEARCH_USERS, {
    variables: { input: { searchTerm: null, skip: null, take: null } },
  });

  useEffect(() => {
    if (selectedModal?.shareModal) {
      refetch();
    }
    // eslint-disable-next-line
  }, [selectedModal]);

  const returnUsersForDropDown = (arr: any) => {
    let reformattedArray = arr?.map((obj, index) => {
      return {
        ...obj,
        value: obj?.id,
        label: obj?.email,
        isFixed: selectedProposal?.customerId === obj?.id,
      };
    });

    return reformattedArray?.filter(
      (el) => el?.organisation?.id === selectedProposal?.organisation?.id
    );
  };

  const setUserIds = (value: any) => {
    const map = value.map(({ id, isFixed, email, label, value }) => ({
      value: id || value,
      isFixed,
      label: email || label,
    }));

    dispatch({
      type: 'SET_USER_IDS',
      payload: map,
    });
  };

  const [shareProposal] = useMutation<ShareProposal>(SHARE_PROPOSAL);

  const shareProposalMutation = () => {
    let input = {
      proposalId: selectedProposal?.id,
      userIds: state.userIds.map((v) => v.value),
    };

    shareProposal({
      variables: {
        input,
      },
    })
      .then((res) => {
        resetAllModals();
        refetchProposals({
          searchTerm: formikSearch.values.searchTerm,
        });
      })
      .catch((err) => {
        resetAllModals();
      });
  };
  console.log(state.userIds);

  const returnSentToForDropDown = (arr: any) => {
    let reformattedArray = arr?.map((obj, index) => {
      return {
        ...obj,
        value: obj?.id,
        label: obj?.email,
        isFixed: obj?.id === selectedProposal?.customerId,
      };
    });

    return reformattedArray;
  };

  return {
    formikSearch,
    myProposals: proposalData?.myProposals.filter((el) => el.status !== -1),
    selectedModal,
    selectedProposal,

    goToEditProposalPage,
    setShareModal,
    setSnapShotModal,
    resetAllModals,
    goToSummary,
    goToEditProposal,
    setDeleteModal,
    deleteProposalMutation,
    editProposalRes,
    users: returnUsersForDropDown(data?.searchUsers),
    setUserIds,
    shareProposalMutation,
    userIds: state.userIds,
    sentTo: returnSentToForDropDown(selectedProposal?.sentTo),
    startTime,
    endTime,
  };
};

export default useProposalTableState;
