import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { colors, fonts } from '../../../utils/styledTheme';

export const fontColor = '#393b3a';
export const grey = '#E1E2E3';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    formTitle: {
      color: fontColor,
      fontSize: 32,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    inputLabel: {
      color: fontColor,
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    button: {
      width: '100%',
      height: '72px',
      padding: '10px',
      cursor: 'pointer',
    },
    noHoverButton: {
      width: '100%',
      height: '72px',
      color: '#333333',
      backgroundColor: '#f7f7f7',
      padding: '10px',
      cursor: 'pointer',
    },
    formHelperText: {
      color: 'red',
      marginTop: 0,
      marginLeft: '0.25em',
    },
  })
);

export const customStylesEditModal = {
  content: {
    maxWidth: 1401,
    margin: 'auto',
    borderRadius: 6,
    background: '#F7F7F7',

    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
    paddingbottom: 70,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,

    onset: 0,
    zIndex: 1,
  },
};

export const Form = styled.div`
  maxWidth: '1193px',
  display: 'flex',
  flex-direction: 'row',
  flex-wrap: 'wrap',
  padding-left: '1em',
  padding-right: '1em',
`;

export const InputWrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${(props) => props.width || '100%'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Label = styled.div<{ marginTop?: string }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop};
 `;

export const FlexRow = styled.div<{
  flexDirection?: string;
  justify?: string;
  marginTop?: number;
  alignItems?: string;
}>`
  width: 100%;
  display: flex;

  height: auto;
  justify-content: ${(props) => props.justify || 'space-between'};
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems || 'center'};
  margin-top: ${(props) => props.marginTop}px;
`;

export const LineBreak = styled.div`
  margin-top: 21px;
  width: 100%;
  height: 2px;
  border-bottom: 2px solid ${colors.grey};
`;

export const Text = styled.div<{ marginTop?: number; fontSize?: number }>`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  white-space: nowrap;
  margin-top: ${(props) => props.marginTop}px;
  font-size: ${(props) => props.fontSize}px;
`;
