// @ts-nocheck
import { FC, useContext, Fragment } from 'react';
import { MenuWrapper } from './styles';
import MenuItem from './MenuItem';
import SubItems from './SubItems';

import SubMenuContext from '../../context/submenu/subMenuContext';
import { SubMenuI } from '../../context/submenu/types';
import { useHistory, useLocation } from 'react-router-dom';
const Menu: FC<MenuProps> = ({ authorizedRoutes }) => {
  const location = useLocation();
  const history = useHistory();

  const subMenuContext = useContext(SubMenuContext) as SubMenuI;
  const { menuState, setMenuState } = subMenuContext;

  function* generator(path: string) {
    yield history.push(path);
    yield setMenuState(false);
  }

  if (authorizedRoutes.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {menuState && (
        <MenuWrapper
          onClose={() => setMenuState(false)}
          animate={{ opacity: menuState ? 1 : 0 }}
          initial={false}
          transition={{ duration: 0.5 }}
        >
          {authorizedRoutes.map((route, index) =>
            route?.nav?.subItems ? (
              <SubItems
                key={index}
                route={route}
                location={location}
                generator={generator}
              />
            ) : (
              <MenuItem
                key={index}
                route={route}
                location={location}
                generator={generator}
              />
            )
          )}
        </MenuWrapper>
      )}
    </Fragment>
  );
};

export default Menu;
