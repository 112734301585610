/**
 *
 * AsyncSelectDropDown
 *
 */
// @ts-nocheck
import { Component, useEffect, FC, Fragment } from 'react';
import { Props } from './types';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import {
  StyleWrapper,
  ColourOption,
  IndicatorContainer,
  StyledOption,
  Icon,
  Label,
  Error,
} from './styles';

export const SearchLogo = `url("data:image/svg+xml,%3Csvg id='Group_17' data-name='Group 17' xmlns='http://www.w3.org/2000/svg' width='19.707' height='18.707' viewBox='0 0 19.707 18.707'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23393b3a' stroke-width='2'%3E%3Ccircle cx='7' cy='7' r='7' stroke='none'/%3E%3Ccircle cx='7' cy='7' r='6' fill='none'/%3E%3C/g%3E%3Cline id='Line_2' data-name='Line 2' x2='6' y2='6' transform='translate(13 12)' fill='none' stroke='%23393b3a' stroke-width='2'/%3E%3C/svg%3E%0A")`;
const customStyles = {
  container: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: '100%',
    height: '72px',
    flex: 1,
    borderColor: '#E1E2E3',
  }),

  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    height: '100%',
  }),

  menuList: (base) => ({
    ...base,
    marginTop: 0,
    padding: 0,
    color: '#707070',
    overflowX: 'hidden',
  }),
};

const AsyncSelectDropDown: FC<Props> = ({
  options,
  name,
  setFieldValue,
  label,
  errors,
}) => {
  const filterColors = (inputValue: string) => {
    return options?.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<ColourOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 250);
    });

  const IndicatorsContainer = (props: any) => (
    <components.IndicatorsContainer {...props}>
      <IndicatorContainer>
        <Icon imageUrl={SearchLogo} />
      </IndicatorContainer>
    </components.IndicatorsContainer>
  );

  const CustomOption = (props) => {
    const { innerRef, innerProps, data } = props;

    return (
      <StyledOption ref={innerRef} {...innerProps}>
        {data?.label}
      </StyledOption>
    );
  };

  return (
    <StyleWrapper>
      {label && <Label>{label}</Label>}
      <AsyncSelect
        styles={customStyles}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        components={{
          IndicatorsContainer,
          Option: CustomOption,
        }}
        onChange={(value) => setFieldValue(name, value)}
      />

      <Error>{errors[name] ? errors[name].value : null}</Error>
    </StyleWrapper>
  );
};

export default AsyncSelectDropDown;
