/**
 *
 * OrgModal
 *
 */

import styled from 'styled-components';
import { fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  margin: 21px auto 0;
  max-width: 1249px;
`;

export const Title = styled.div`
  font-size: 61px;
  color: #393b3a;
  text-transform: uppercase;
  font-family: ${fonts.bold};
`;

export const Flex = styled.div<{
  justify?: string;
  marginTop?: number;
  height?: number;
  flexDirection?: string;
}>`
  margin-top: ${(props) => props.marginTop}px;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: ${(props) => props.justify || 'space-between'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
`;

export const Label = styled.div`
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-family: ${fonts.bold};
  color: black;
  margin-bottom: 10px;
`;
