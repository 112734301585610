import { gql } from '@apollo/client';

export const GET_TEMPLATE_BRICKS = gql`
  query GetTemplateBricks($id: Uuid!) {
    proposal(id: $id) {
      id
      templateBricks {
        id
        name
        url
        text
        fileUrl
        status
      }
      bricks {
        id
        name
        status
      }
    }
  }
`;
