/**
 *
 * TableProofContainer
 *
 */

// @ts-nocheck
import React from 'react';
import { Grid } from '@material-ui/core';
import { Title, Row } from './styles';

import Table from '../../components/Table';

import { ReactComponent as Edit } from '../../images/svg/EditIcon.svg';
import { ReactComponent as Delete } from '../../images/svg/DeleteIcon.svg';
import { ReactComponent as Lock } from '../../images/svg/LockIcon.svg';

import { SearchClients_searchCustomers } from '../../utils/gql/clients/__generated__/SearchClients';

import SearchTermForm from '../../components/Forms/SearchTermForm';

import ButtonHover from '../../components/ButtonHover';
import Container from '../../components/Container';

// hooks
import useClientState from './useClientState';
import DeleteModal from '../../components/DeleteModal';
import EditUserModal from '../../components/EditUserModal';
import ResetPasswordModal from '../../components/ResetPasswordModal';

const ClientsContainer = ({}) => {
  const {
    searchCustomers,
    setSearchTerm,
    loading,

    selectedClient,
    selectedModal,
    setCreateModal,
    setDeleteModal,
    setEditModal,
    resetAllModals,
    setResetPasswordModal,

    // mutations
    formikEditClient,
    formikCreateClient,
    createClientMutation,
    deleteClientMutation,
    editClientMutation,
    resetPasswordMutation,

    // for drop down on create modal
    searchOrganisations,
    // for create org button
    goToOrganisationModal,
  } = useClientState();

  const returnRows = () => {
    let map = searchCustomers
      ?.filter((el: any) => el?.status !== -1)
      .map((obj) => {
        return {
          ...obj,
          role: 'Customer',
          name: obj?.firstName + ' ' + obj?.lastName,
          org: obj?.organisation?.name || '',
        };
      });
    return map;
  };

  const icons = [
    {
      component: Edit,
      action: setEditModal,
    },
    {
      component: Delete,
      action: setDeleteModal,
    },

    {
      component: Lock,
      action: setResetPasswordModal,
    },
  ];

  return (
    <Container marginTop={50} paddingBottom={159}>
      <Row marginBottom='13px'>
        <Title>Client management</Title>

        <ButtonHover
          color='black'
          label='Create client'
          onClick={() => setCreateModal()}
        />
      </Row>

      {setSearchTerm && (
        <SearchTermForm changeCallback={(e) => setSearchTerm(e.target.value)} />
      )}

      <Table
        headers={[
          'Name',
          'Email address',
          'Organisation',
          'Role',
          'Phone number',
          'Job Title',
          'icons',
        ]}
        rows={returnRows()}
        colObjectKeys={[
          ['name'],
          ['email'],
          ['org'],
          ['role'],
          ['phoneNumber'],
          ['jobTitle'],
          ['icons'],
        ]}
        icons={icons}
      />
      <Grid container alignItems='flex-end' justifyContent='flex-end'>
        <ButtonHover
          color='black'
          label='Create client'
          onClick={() => setCreateModal()}
        />
      </Grid>

      <DeleteModal
        isOpen={selectedModal?.deleteModal}
        onRequestClose={() => resetAllModals()}
        mutation={() => deleteClientMutation()}
        type='client'
      />

      <EditUserModal
        isOpen={selectedModal?.editModal}
        onRequestClose={() => resetAllModals()}
        user={selectedClient}
        formik={formikEditClient}
        mutation={editClientMutation}
        type='CLIENT'
        create={false}
      />

      <EditUserModal
        isOpen={selectedModal?.createModal}
        onRequestClose={() => resetAllModals()}
        user={selectedClient}
        formik={formikCreateClient}
        mutation={createClientMutation}
        type='CLIENT'
        create={true}
        searchOrganisations={searchOrganisations}
        goToOrganisationModal={goToOrganisationModal}
      />

      <ResetPasswordModal
        isOpen={selectedModal?.resetPasswordModal}
        onRequestClose={() => resetAllModals()}
        mutation={() => resetPasswordMutation()}
        type='client'
      />
    </Container>
  );
};

export default ClientsContainer;
