import { gql } from '@apollo/client';

export const GET_STATS = gql`
  query GetProposalStats($id: Uuid!) {
    proposalStats(id: $id) {
      proposalStats {
        uniqueVisitors
        averageViewTime
        averageLinksClicked
        componentStats {
          entityTableId
          entityId
          userInteractionTypeId
          interactions
        }
        userInteractions {
          id
          userInteractionTypeId
          entityId
          entityTableId
          proposalId
          userId
          userRoleId
          createdDate
        }
      }
    }
  }
`;
