/**
 *
 * TableProof
 *
 */

import styled from 'styled-components';
import {
  TableCell,
  Theme,
  withStyles,
  createStyles,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { mediaQueries, fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.white,
      overflow: 'visible',
    },

    root: {
      border: 'none',
      color: '#2E3132',
      height: 68,
      fontSize: 16,
    },
  })
)(TableCell);

export const useStyles = makeStyles({
  table: {},
  tableHead: {
    background: '#333333',
    height: '40px',
    paddingTop: '0px',
    paddingBottom: '0px',
    color: 'white',
    whiteSpace: 'nowrap',
  },
  tableBody: {
    backgroundColor: 'white',
    marginTop: '16px',
    marginBottom: '31px',
    overflow: 'scroll',
    whiteSpace: 'nowrap',
    borderRadius: 6,
  },
  loading: {
    width: '100%',
    paddingLeft: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 'none',
      backgroundColor: theme.palette.common.white,
      '&:nth-of-type(even)': {
        backgroundColor: '#F0F0F0',
      },
    },
  })
)(TableRow);

export const Title = styled.div`
  color: black;

  font-family: ${fonts.bold};
  text-transform: uppercase;

  font-size: 28px;

  ${mediaQueries.iPadPro} {
    font-size: 40px;
  }

  ${mediaQueries.desktop} {
    font-size: 61px;
  }
`;

export const Row = styled.div<{
  height?: number;
  marginTop?: number;
  marginBottom?: number;
}>`
  width: 100%;
  height: ${(props) => props.height || 70}px;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  object-fit: contain;
  width: 109px;
  height: 66px;
  border-radius: 6px;
`;

export const logoBackground =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='109' height='66' viewBox='0 0 109 66'%3E%3Cg id='Rectangle_1305' data-name='Rectangle 1305' fill='%23707070' stroke='%23707070' stroke-width='1'%3E%3Crect width='109' height='66' rx='5' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='108' height='65' rx='4.5' fill='none'/%3E%3C/g%3E%3C/svg%3E%0A";
