import { FC } from 'react';
import { GridItemWrapper, Container, Add, Title } from './styles';

const AddProposal: FC<AddProposalProps> = ({ setAddBrick }) => {
  const onClick = () => {
    if (setAddBrick) {
      setAddBrick((prevState: boolean) => !prevState);
    } else return null;
  };

  return (
    <GridItemWrapper border='3px solid grey'>
      <Container>
        <Add onClick={() => onClick()} />
        <Title color='#707070' style={{ maxWidth: '100%' }}>
          ADD NEW PROPOSAL BRICK
        </Title>
      </Container>
    </GridItemWrapper>
  );
};

export default AddProposal;
