/**
 *
 * ShareProposal
 *
 */
// @ts-nocheck
import styled from 'styled-components';
import { colors, fonts } from '../../utils/styledTheme';

export const customStyles = {
  content: {
    maxWidth: 1152,
    minWidth: 630,
    margin: 'auto',
    height: 660,
    borderRadius: 6,
    background: '#ffff',
    padding: 0,
    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 1,
  },
};

export const Container = styled.div`
  margin: 0 auto;
  width: 92%;
  height: auto;
`;

export const Text = styled.div<{
  marginTop?: number;
  marginBottom?: number;
  fontSize?: number;
  upperCase?: boolean;
  fontFamily?: string;
}>`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  font-size: ${(props) => props.fontSize}px;
  text-transform: ${(props) => (props.upperCase ? 'uppercase' : null)};
`;

export const FlexRow = styled.div`
  margin-top: 12px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
`;

export const customStylesAsync = {
  multiValue: (provided, { data }) => ({
    ...provided,

    color: 'white',
    background: data?.isFixed ? colors.grey : colors.brightBlue,
    borderRadius: 22,
    fontSize: 18,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: data?.isFixed ? 16 : 67,
    position: 'relative',
  }),

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
    fontSize: 18,
  }),

  multiValueRemove: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
      display: data?.isFixed ? 'none' : 'block',
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
      position: 'absolute',
      right: 14,
      top: 14,
      bottom: 14,
    };
  },

  control: (styles, { data }) => ({
    border: '1px solid #707070',
    borderRadius: 5,
    height: 223,
  }),

  valueContainer: (styles, { data }) => ({
    ...styles,
    marginTop: 22,
  }),
  menu: (styles, { data }) => ({
    overflow: 'auto',
  }),
  menuList: (styles, { data }) => ({
    display: 'flex',
    flexDirection: 'row',
  }),
};

export const StyledOption = styled.div<OptionProps>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  margin-top: 12px;
  border-radius: ${(props) => props.borderRadius || '6px'};
  white-space: nowrap;

  &:hover {
    background: #e1e2e3;
  }
`;
