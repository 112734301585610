import * as Yup from 'yup';

export const searchTermSchema = Yup.object().shape({});

export interface SelectInterface {
  label: string;
  value: string;
}

export interface SearchTermInterface {
  searchTerm: string;
}

export const initialValues: SearchTermInterface = {
  searchTerm: '',
};

export default searchTermSchema;
