import { FC } from 'react';
import {
  StyleWrapper,
  Container,
  Text,
  FlexRow,
  Flag,
  FlagImg,
  Country,
  Line,
} from './styles';

import MyAccount from './MyAccount';
import useRedirect from './useRedirect';

const Header: FC = () => {
  const { isLoggedIn } = useRedirect();

  return (
    <StyleWrapper>
      <Container>
        <FlexRow>
          <Text color='#999999' fontFamily='BrownProTT'>
            Sales
          </Text>

          <Text color='#999999' fontFamily='BrownProTTLight'>
            View
          </Text>
        </FlexRow>

        <FlexRow width='20.875rem'>
          <Flag imageUrl={FlagImg} />

          <Country whileHover={{ color: '#fff' }}>EN</Country>

          <Line />

          <MyAccount />
        </FlexRow>
      </Container>
    </StyleWrapper>
  );
};

export default Header;
