/**
 *
 * TextField
 *
 */

import styled from 'styled-components';
import { Field } from 'formik';
import { CustomProps } from './types';
import { FormHelperText } from '@material-ui/core';

export const grey = '#E1E2E3';
export const fontColor = '#707070';
export const blue = '#265FC5';
export const lightBlue = '#4fb7f6';

export const SearchLogo = `url("data:image/svg+xml,%3Csvg id='Group_1968' data-name='Group 1968' xmlns='http://www.w3.org/2000/svg' width='27.114' height='26.858' viewBox='0 0 27.114 26.858'%3E%3Cg id='Ellipse_22' data-name='Ellipse 22' fill='%23fff' stroke='%23707070' stroke-width='3'%3E%3Ccircle cx='11.5' cy='11.5' r='11.5' stroke='none'/%3E%3Ccircle cx='11.5' cy='11.5' r='10' fill='none'/%3E%3C/g%3E%3Cg id='Group_1967' data-name='Group 1967' transform='matrix(0.695, -0.719, 0.719, 0.695, 17.862, 20.606)'%3E%3Cg id='Rectangle_972' data-name='Rectangle 972' transform='translate(0 0)' fill='%23707070' stroke='%23707070' stroke-width='1'%3E%3Crect width='4' height='9' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='3' height='8' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")`;

export const StyleWrapper = styled.div`
  display: flex;
`;

export const Input = styled(Field)<CustomProps>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  margin-top: ${(props) => props.marginTop};
  border-radius: 5px;
  border: 2px solid ${grey};
  padding: ${(props) => props.padding || '0 2.5%'};
  width: ${(props) => props.width || '100%'};
  color: ${fontColor};

  background-image: ${(props) => props.background};

  background-position: left 18px top 50%;
  background-repeat: no-repeat;
  background-color: white;
`;

export const HelperText = styled(FormHelperText)`
  margin-top: 0 !important;
  color: red !important;
`;
