/**
 *
 * Dropzone
 *
 */
// @ts-nocheck
import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { T } from './types';
import { useDropzone } from 'react-dropzone';
import {
  StyleWrapper,
  InputWrapper,
  Background,
  Label,
  UploadIcon,
  BackgroundLabel,
} from './styles';

const Dropzone: FC<T> = ({
  baseStyle,
  formik,
  name,
  file,
  fileName,
  backgroundWidth,
  label,
  editMode,
  editName,
  height,
  width,
  marginTop,
  backgroundLabel,
}) => {
  const [imageData, setImageData] = useState({
    backgroundImage: null,
  });

  const { backgroundImage } = imageData;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((f: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        setImageData({ backgroundImage: binaryStr });

        formik.setFieldValue(file, binaryStr.split(',')[1]);
        formik.setFieldValue(name, acceptedFiles[0].path);
        editName && formik.setFieldValue(editName, acceptedFiles[0].path);
      };
      reader.readAsDataURL(f);
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  useEffect(() => {
    if (editMode) {
      setImageData({ backgroundImage: formik?.values?.[fileName] });
    }
  }, [editMode]);

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const returnBackgroundLabel = (backgroundImg: any) => {
    if (!backgroundLabel) return null;
    console.log(backgroundImage, 'background');
    return (
      <BackgroundLabel opacity={backgroundImg ? '0' : '1'}>
        {backgroundLabel}
      </BackgroundLabel>
    );
  };

  return (
    <StyleWrapper
      style={{ height: height }}
      marginTop={marginTop}
      width={width}
    >
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <Background
          src={
            backgroundImage ||
            'http://upload.wikimedia.org/wikipedia/commons/c/ce/Transparent.gif'
          }
          width={backgroundWidth}
        />
        {returnBackgroundLabel(backgroundImage)}
        <div {...getRootProps({ style })}>
          {!backgroundImage ? <UploadIcon /> : null}
          <input {...getInputProps()} />
        </div>
      </InputWrapper>
    </StyleWrapper>
  );
};

export default Dropzone;
