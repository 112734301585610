import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';

// apollo
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../apollo/cache';

import useQueryParams from '../../utils/hooks/useQuery';

const useRedirect = () => {
  let location = useLocation();
  let history = useHistory();
  let query = useQueryParams();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const checkPath = () => {
    if (
      location.pathname.substring(0, 15) === '/proposals/view' ||
      location.pathname.substring(0, 15) === '/password-reset'
    ) {
      return false;
    }
    return true;
  };

  const returnAccessKey = () => {
    return query.get('accessKey');
  };

  const redirectUser = () => {
    if (!isLoggedIn && checkPath() && !returnAccessKey()) {
      history.push('/login-modal');
    }
  };

  useEffect(() => {
    redirectUser();
  }, [location.pathname, isLoggedIn]);

  return {
    isLoggedIn,
  };
};

export default useRedirect;
