import React, { useEffect } from 'react';
import { DisplayTitle } from './styles';
import { Grid, Box } from '@material-ui/core';
import { ShopDisplayT, ShopItem as ShopItemT } from './types';
import { ShopItem } from './ShopItem';
import { useMutation } from '@apollo/client';
import { ADD_EQUIPMENT_TO_ROOM } from '../../utils/gql/equipments/addEquipmentToRoom';
import { AddEquipmentToRoom } from '../../utils/gql/equipments/__generated__/AddEquipmentToRoom';
import { REMOVE_EQUIPMENT_FROM_ROOM } from '../../utils/gql/equipments/removeEquipmentFromRoom';
import { RemoveEquipmentFromRoom } from '../../utils/gql/equipments/__generated__/RemoveEquipmentFromRoom';
import { useParams } from 'react-router';

export const ShopDisplay = ({
  type,
  shopItems,
  query,
  readOnly,
}: ShopDisplayT) => {
  const [addEquipmentToRoom, { loading, error, data }] =
    useMutation<AddEquipmentToRoom>(ADD_EQUIPMENT_TO_ROOM);

  const [
    removeEquipmentFromRoom,
    { loading: removeLoading, error: removeError, data: removeData },
  ] = useMutation<RemoveEquipmentFromRoom>(REMOVE_EQUIPMENT_FROM_ROOM);

  const { id: proposalId }: any = useParams();

  const handleAddEquipmentToRoom = (
    equipmentId: string,
    roomId: string | undefined
  ) => {
    const input = {
      equipmentId,
      roomId,
      proposalId,
    };
    addEquipmentToRoom({ variables: { input } })
      .then((response) => {
        query();
      })
      .catch((error) => console.log(error));
  };

  const handleRemoveEquipmentFromRoom = (
    equipmentId: string,
    roomId: string | undefined
  ) => {
    const input = {
      equipmentId,
      roomId,
      proposalId,
    };
    removeEquipmentFromRoom({ variables: { input } })
      .then(() => {
        query();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Grid component={Box} sx={{ mb: '2rem' }} container spacing={2}>
      <Grid item xs={12}>
        <DisplayTitle>{type}</DisplayTitle>
        <hr />
      </Grid>
      <Grid item container spacing={2} xs={12}>
        {shopItems?.map((shopItem: ShopItemT, index) => (
          <ShopItem
            key={`shopdisplayitem-${index}`}
            imageUrl={shopItem.imageUrl}
            name={shopItem.name}
            collateral={shopItem.collateral}
            id={shopItem.id}
            iconUrl={shopItem.iconUrl}
            isSelected={shopItem.isSelected}
            readOnly={readOnly}
            onChange={() =>
              readOnly
                ? null
                : !shopItem.isSelected
                ? handleAddEquipmentToRoom(shopItem.id, shopItem.roomId)
                : handleRemoveEquipmentFromRoom(shopItem.id, shopItem.roomId)
            }
          />
        ))}
      </Grid>
    </Grid>
  );
};
