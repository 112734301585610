import { useState, FC, useContext } from 'react';
import { AccountWrapper, DropDown, Logout } from './styles';
import { useApolloClient } from '@apollo/client';
import { isLoggedInVar } from '../../apollo/cache';

import SubMenuContext from '../../context/submenu/subMenuContext';
import { UserI } from '../../context/user/types';
import { SubMenuI } from '../../context/submenu/types';
import { GET_USER } from '../../utils/gql/user/getUser';
import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';

const MyAccount: FC = () => {
  const subMenuContext = useContext(SubMenuContext) as SubMenuI;
  const { setMenuState } = subMenuContext;

  const [isHovered, setHovered] = useState<boolean>(false);
  const client = useApolloClient();
  let history = useHistory();

  const LogOut = () => {
    // Evict and garbage-collect the cached user object
    client.cache.evict({ fieldName: 'me' });
    client.cache.gc();
    // Remove user details from localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // Set the logged-in status to false
    isLoggedInVar(false);

    setMenuState(false);
    history.push('/login-modal');
  };

  const token = localStorage.getItem('accessToken');

  // const { data, loading } = useQuery(GET_USER, { variables: { id: token } });
  // const user = data && data?.user;

  // const firstName = user?.firstName;
  // const lastName = user?.lastName;

  return (
    <AccountWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <svg
        id='My_Account'
        data-name='My Account'
        xmlns='http://www.w3.org/2000/svg'
        width='148'
        height='24.426'
        viewBox='0 0 180 24.426'
      >
        <g
          id='Group_24735'
          data-name='Group 24735'
          transform='translate(-1773 -456)'
        >
          <g
            id='Ellipse_69'
            data-name='Ellipse 69'
            transform='translate(1778 456)'
            fill='none'
            stroke={isHovered ? 'white' : '#999'}
            strokeWidth='2'
          >
            <circle cx='5' cy='5' r='5' stroke='none' />
            <circle cx='5' cy='5' r='4' fill='none' />
          </g>
          <g
            id='Subtraction_7'
            data-name='Subtraction 7'
            transform='translate(6367 -15393)'
            fill='none'
          >
            <path
              d='M-4574.2,15873h-19.6a10.082,10.082,0,0,1-.2-2,9.934,9.934,0,0,1,2.929-7.072A9.936,9.936,0,0,1-4584,15861a10.011,10.011,0,0,1,10,10,10.079,10.079,0,0,1-.2,2Z'
              stroke='none'
            />
            <path
              d='M -4576.0009765625 15870.998046875 C -4576.0029296875 15866.587890625 -4579.58984375 15863 -4583.99853515625 15863 C -4586.13671875 15863 -4588.14599609375 15863.83203125 -4589.6572265625 15865.3427734375 C -4591.16748046875 15866.853515625 -4592 15868.861328125 -4592.00048828125 15870.998046875 L -4576.0009765625 15870.998046875 M -4574.20068359375 15872.998046875 L -4593.80078125 15872.998046875 C -4593.93359375 15872.341796875 -4594.00048828125 15871.6708984375 -4594.00048828125 15871.0009765625 C -4594.00048828125 15868.3291015625 -4592.96044921875 15865.8173828125 -4591.0712890625 15863.9287109375 C -4589.1826171875 15862.0400390625 -4586.6708984375 15861 -4583.99853515625 15861 C -4578.48583984375 15861 -4574.0009765625 15865.486328125 -4574.0009765625 15871.0009765625 C -4574.0009765625 15871.671875 -4574.068359375 15872.34375 -4574.20068359375 15872.9970703125 L -4574.20068359375 15872.998046875 Z'
              stroke='none'
              fill={isHovered ? 'white' : '#999'}
            />
          </g>
          <path
            id='Path_31058'
            data-name='Path 31058'
            d='M6375.321-15384.074h20'
            transform='translate(-4602.321 15863)'
            fill='none'
            stroke='#333'
            strokeWidth='3'
          />
        </g>
        <text
          id='MY_ACCOUNT-2'
          data-name='MY ACCOUNT'
          transform='translate(29 17)'
          fill={isHovered ? 'white' : '#999'}
          fontSize='17'
          fontFamily='Helvetica-Bold, Helvetica'
          fontWeight='700'
          letterSpacing='0.028em'
        >
          <tspan x='0' y='0'>
            MY ACCOUNT
          </tspan>
        </text>
      </svg>
      {isHovered && (
        <DropDown>
          <Logout whileHover={{ color: '#00B8FC' }} onClick={() => LogOut()}>
            Logout
          </Logout>
        </DropDown>
      )}
    </AccountWrapper>
  );
};

export default MyAccount;
