import { gql } from '@apollo/client';

export const EDIT_ORGANISATION = gql`
  mutation EditOrganisation($input: EditOrganisationRequestInput!) {
    editOrganisation(input: $input) {
      organisation {
        name
        id
        description
        regionId
        logoUrl
        domains {
          id
          name
        }
      }
    }
  }
`;
