/**
 *
 * * CreateProposal Messages
 *
 */

import { defineMessages } from 'react-intl';

export const scope = 'Components.CreateProposal';

export default defineMessages({
  proposalProjectName: {
    id: `${scope}.proposalProjectName`,
    defaultMessage: 'Proposal / Project Name',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'Customer First Name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Customer Last Name',
  },
  step1: {
    id: `${scope}.step1`,
    defaultMessage: 'Step 1',
  },
  clientDetails: {
    id: `${scope}.clientDetails`,
    defaultMessage: 'Client Details',
  },
  nextStep: {
    id: `${scope}.nextStep`,
    defaultMessage: 'Next Step',
  },
  proposalTitle: {
    id: `${scope}.proposalTitle`,
    defaultMessage: 'Proposal Title:',
  },
  customerName: {
    id: `${scope}.customerName`,
    defaultMessage: 'Customer Name:',
  },
  companyLogo: {
    id: `${scope}.companyLogo`,
    defaultMessage: 'Company Logo:',
  },
  proposalIntro: {
    id: `${scope}.proposalIntro`,
    defaultMessage: 'Proposal Intro:',
  },
  proposalContent: {
    id: `${scope}.proposalContent`,
    defaultMessage: 'Proposal Content:',
  },
  proposalSaved: {
    id: `${scope}.proposalSaved`,
    defaultMessage: 'Proposal Saved!',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'EDIT',
  },
  doneEdit: {
    id: `${scope}.doneEdit`,
    defaultMessage: 'CLOSE',
  },
});
