/*
 * ProposalContent Messages
 *
 * This contains all the text for the ProposalContent component.
 */

import { defineMessages } from 'react-intl';

const scope = 'app.components.ProposalContent';

export default defineMessages({
  header: {
    id: 'app.components.ProposalContent.header',
    defaultMessage: 'Proposal Content:',
  },

  enterPrize: {
    id: 'app.components.ProposalContent.enterPrize',
    defaultMessage: 'ENTERPRISE READY',
  },

  serviceProviders: {
    id: 'app.components.ProposalContent.serviceProviders',
    defaultMessage: 'SUPPORTED BY THE MAJOR SERVICE PROVIDERS',
  },

  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'EDIT',
  },
  doneEdit: {
    id: `${scope}.doneEdit`,
    defaultMessage: 'CLOSE',
  },
});
