import { gql } from '@apollo/client';

export const USER_ROLES = gql`
  query UserRoles {
    userRoles {
      id
      name
    }
  }
`;
