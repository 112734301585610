import { FC, useContext } from 'react';
import { MenuToggleProps } from './types';
import { motion } from 'framer-motion';
import { Button } from './styles';

import SubMenuContext from '../../context/submenu/subMenuContext';
import { SubMenuI } from '../../context/submenu/types';

const Path = (props: any) => (
  <motion.path
    fill='transparent'
    strokeWidth='3'
    stroke='hsl(0, 0%, 18%)'
    strokeLinecap='round'
    {...props}
  />
);

export const MenuToggle: FC<MenuToggleProps> = ({ authorizedRoutes }) => {
  const subMenuContext = useContext(SubMenuContext) as SubMenuI;
  const { menuState, setMenuState } = subMenuContext;

  if (authorizedRoutes.length === 0) {
    return null;
  }

  return (
    <Button
      animate={menuState ? 'open' : 'closed'}
      onClick={() => setMenuState(!menuState)}
    >
      <svg width='23' height='23' viewBox='0 0 23 23'>
        <Path
          variants={{
            closed: { d: 'M 2 2.5 L 20 2.5' },
            open: { d: 'M 3 16.5 L 17 2.5' },
          }}
        />
        <Path
          d='M 2 9.423 L 20 9.423'
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 16.346 L 20 16.346' },
            open: { d: 'M 3 2.5 L 17 16.346' },
          }}
        />
      </svg>
    </Button>
  );
};
