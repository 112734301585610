/**
 *
 * * SearchTerm Messages
 *
 */

import { defineMessages } from "react-intl";

export const scope = "Components.SearchTerm";

export default defineMessages({
    search: {
        id: `${scope}.search`,
        defaultMessage: "Search",
    },
});
