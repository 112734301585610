/**
 *
 * MeetingSpaces
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled(motion.div)<{ background?: string }>`
  padding-top: 55px;
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.background};
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 740px;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Header = styled.div`
  font-size: 30px;
  font-family: 'BrownProTTLight';
  height: auto;
  display: flex;
  align-items: center;
  width: 740px;
  margin: 0 auto;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const MeetingSpaceWrapper = styled.div`
  margin-top: 55px;
  width: 156px;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  &:not(:first-of-type):not(:last-of-type) {
    margin-left: 32px;
    margin-right: 32px;
  }

  &:first-of-type {
    margin-right: 32px;
    margin-left: 3px;
  }

  &:last-of-type {
    margin-left: 32px;
    margin-right: 3px;
  }
`;

export const Title = styled.div`
  width: fit-content;
  height: auto;
  color: #000000;
  font-family: 'BrownProTTBold';
  text-transform: capitalize;
  text-align: center;
`;

export const Icon = styled.img`
  cursor: pointer;
  width: 90%;
  height: 90%;
  object-fit: scale-down;
  border-radius: 10px;
`;

export const IconBorder = styled(motion.div)`
  margin-top: 11px;
  cursor: pointer;
  width: 156px;
  height: 113px;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: lightGrey;
  }
`;
