/**
 *
 * ProposalsContainer
 *
 */
// @ts-nocheck
import { FC, useContext } from 'react';

import useNavigate from '../../utils/hooks/useNavigate';
import { Grid } from '@material-ui/core';
import {
  StyledTableRow,
  StyledTableCell,
} from '../../components/TableProof/styles';
import Table from '../../components/TableProof';
import { Title, Row } from './styles';

import ButtonHover from '../../components/ButtonHover';
import Container from '../../components/Container';
import MultiSelect from '../../components/MultiSelect';
import DeleteModal from '../../components/DeleteModal';
import SentTo from '../../components/SentTo';

import SearchTermForm from '../../components/Forms/SearchTermForm';

import useProposalTableState from './useProposalTableState';
import ShareProposal from '../../components/ShareProposal';
import moment from 'moment';

// Icons
import Icon from '../../components/Icon';
import ViewImg from '../../images/svg/ViewIcon.svg';
import ShareImg from '../../images/svg/ShareIcon.svg';
import SettingsImg from '../../images/svg/SettingsIcon.svg';
import SnapshotImg from '../../images/svg/SnapshotIcon.svg';
import EditImg from '../../images/svg/EditIcon.svg';
import DeleteImg from '../../images/svg/DeleteIcon.svg';

import usePermissions from '../../utils/hooks/usePermissions';

const options = [
  { value: 0, label: 'Draft' },
  { value: 1, label: 'Sent' },
  { value: 2, label: 'Both' },
];

const ProposalsContainer: FC = () => {
  const {
    formikSearch,
    myProposals,
    selectedModal,
    selectedProposal,
    setShareModal,
    setSnapShotModal,
    resetAllModals,
    goToEditProposal,
    setDeleteModal,
    deleteProposalMutation,
    goToSummary,
    editProposalRes,
    users,
    setUserIds,
    userIds,
    shareProposalMutation,
    sentTo,
  } = useProposalTableState();

  const navigate = useNavigate();

  const { roleName } = usePermissions();

  const generateHeaders = () => [
    { title: 'Proposal Name' },
    { title: 'Status' },
    { title: 'Created by' },
    { title: 'Date Sent' },
    { title: 'Sent to' },
    { title: 'Company' },
    { title: '' },
  ];

  const roles = ['Super Admin', 'Admin', 'Sales'];

  const returnProposals = (status: number) => {
    if (!myProposals) return;

    switch (status) {
      case 0:
        return myProposals.filter((el) => el.status === 0);

      case 1:
        return myProposals.filter((el) => el.status === 1);

      case 2:
        return myProposals;

      default:
        return myProposals;
    }
  };

  const formatDate = (date: string) => {
    const formatedDate = moment(date);
    return formatedDate.format('DD-MM-YYYY');
  };

  const returnCreatedBy = (firstName: string, lastName: string) => {
    return firstName + ' ' + lastName;
  };

  const generateRows = (status: number) => {
    const data = returnProposals(status);

    if (data?.length === 0) return [];

    const results = data
      ?.map((el) => {
        return {
          proposalName: `${el?.name}`,
          status: el?.status === 0 ? 'Draft' : 'Sent',
          createdBy: returnCreatedBy(
            el?.createdBy?.firstName,
            el?.createdBy?.lastName
          ),
          dateSent:
            el?.status === 0
              ? null
              : formatDate(el.proposalAudience?.createdDate),
          sentTo: <SentTo users={el?.sentTo} />,
          company: el?.organisation?.name,
          actions: [
            {
              action: goToSummary,
              imageUrl: ViewImg,
              label: 'Review Proposal',
              id: 0,
            },
            {
              action: setShareModal,
              imageUrl: ShareImg,
              label: 'Sharing',
              id: 1,
            },
            {
              action: () => console.log('settings'),
              imageUrl: SettingsImg,
              label: 'Proposal Settings',
              id: 2,
            },
            {
              action: setSnapShotModal,
              imageUrl: SnapshotImg,
              label: 'Snapshot',
              id: 3,
            },
            {
              action: goToEditProposal,
              imageUrl: EditImg,
              label: 'Edit Proposal',
              id: 4,
            },
            {
              action: setDeleteModal,
              imageUrl: DeleteImg,
              label: 'Delete Proposal',
              id: 5,
            },
          ],

          proposal: el,
        };
      })
      .filter((el) => el.status !== -1);

    if (!!results && results?.length > 0) return results;

    return [];
  };

  // return admin & sales icons
  const returnAdminIcons = (row: any) => {
    return row?.actions.map(({ id, imageUrl, label, action }) => (
      <Icon
        key={id}
        imageUrl={imageUrl}
        label={label}
        action={() => action(row.proposal)}
        row={row}
        selectedModal={selectedModal}
        selectedProposal={selectedProposal}
        resetAllModals={resetAllModals}
        status={row?.status}
      />
    ));
  };

  // return customer icons
  const returnCustomerIcons = (row: any) => {
    let array = [];

    const sharing = row?.actions.find((element) => element.label === 'Sharing');
    const review = row?.actions.find(
      (element) => element.label === 'Review Proposal'
    );

    array[0] = sharing;
    array[1] = review;

    return array?.map(({ id, imageUrl, label, action }) => (
      <Icon
        key={id}
        imageUrl={imageUrl}
        label={label}
        action={() => action(row.proposal)}
        row={row}
        selectedModal={selectedModal}
        selectedProposal={selectedProposal}
        resetAllModals={resetAllModals}
        userRole={roleName}
      />
    ));
  };

  const returnIcons = (row: any, roleName: any) => {
    if (
      roleName === 'Admin' ||
      roleName === 'Sales' ||
      roleName === 'Super Admin'
    ) {
      return returnAdminIcons(row);
    }

    if (roleName === 'Customer') {
      return returnCustomerIcons(row);
    }
  };

  return (
    <Container marginTop={50} paddingBottom={159}>
      <Row marginBottom='13px'>
        <Title onClick={() => resetAllModals()}>Your proposals</Title>

        {roles.includes(roleName) && (
          <ButtonHover
            color='black'
            label='Create proposal'
            onClick={() => navigate.proposalsCreate()}
          />
        )}
      </Row>

      <Row>
        <SearchTermForm
          placeHolder='Search by Proposal, Client or Company Name'
          changeCallback={(e) =>
            formikSearch.setFieldValue('searchTerm', e.target.value)
          }
        />
        <MultiSelect
          placeholder='Status'
          options={options}
          width={25}
          marginLeft={25}
          setFieldValue={formikSearch.setFieldValue}
          name='status'
          height={72}
        />
      </Row>

      <Grid item xs={12}>
        <Table
          headers={generateHeaders()}
          tableData={generateRows(formikSearch.values.status.value)}
          renderBody={(row, index) => (
            <StyledTableRow key={index}>
              {Object.keys(row)
                .filter((key) => key !== 'actions')
                .filter((key) => key !== 'proposal')
                .filter((key) => key !== 'customerActions')
                .map((rowProperty, index) => (
                  <>
                    <StyledTableCell key={index}>
                      {row[rowProperty]}
                    </StyledTableCell>
                  </>
                ))}

              <StyledTableCell
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {returnIcons(row, roleName)}
              </StyledTableCell>
            </StyledTableRow>
          )}
        />
      </Grid>

      <Grid container alignItems='flex-end' justifyContent='flex-end'>
        {roles.includes(roleName) && (
          <ButtonHover
            color='black'
            label='Create proposal'
            onClick={() => navigate.proposalsCreate()}
          />
        )}
      </Grid>

      <DeleteModal
        isOpen={selectedModal?.deleteModal}
        onRequestClose={() => resetAllModals()}
        type='proposal'
        mutation={() => deleteProposalMutation()}
        loading={editProposalRes?.loading}
      />

      {users && selectedProposal?.status === 1 && (
        <ShareProposal
          isOpen={selectedModal?.shareModal}
          onRequestClose={() => resetAllModals()}
          options={users}
          setUserIds={setUserIds}
          userIds={userIds}
          mutation={() => shareProposalMutation()}
          sentTo={sentTo}
        />
      )}
    </Container>
  );
};

export default ProposalsContainer;
