import { gql } from '@apollo/client';

export const CREATE_ORGANISATION = gql`
    mutation CreateOrganisation($input: CreateOrganisationRequestInput!) {
        createOrganisation(input: $input) {
            organisation{
                name
                id
                description
                regionId
                logoUrl
                domains{
                  id
                  name
                }
            }
        }
      }
`;
