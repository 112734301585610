/**
 *
 * EditModal
 *
 */

import React, { useEffect } from 'react';
import { ModalProps, InputProps } from './types';

import { useFormik } from 'formik';

import Modal from 'react-modal';
import ButtonHover from '../ButtonHover';

import {
  Container,
  Title,
  Form,
  TextInput,
  TextArea,
  InputWrapper,
  Label,
  Flex,
} from './styles';

import MyDropzone from './MyDropZone';

import { useMutation } from '@apollo/client';
import { EDIT_BRICK } from '../../utils/gql/bricks/editBrickMutation';
import { EditBrick } from '../../utils/gql/bricks/__generated__/EditBrick';

const customStyles = {
  content: {
    maxWidth: '1340px',
    margin: 'auto',
    width: '70%',
    borderRadius: '6px',
    background: '#f7f7f7',
    onset: 0,
    paddingBottom: 70,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    onset: 0,
    zIndex: 999999999999,
  },
};

const InputHelper = ({ label, ...props }: InputProps) => (
  <InputWrapper {...props}>
    <Label>{label}</Label>
    {props.type === 'textarea' ? (
      <TextArea {...props} />
    ) : (
      <TextInput {...props} />
    )}
  </InputWrapper>
);

const EditModal: React.FC<ModalProps> = ({
  isOpen,
  modals,
  setModals,
  id,
  sortOrder,
  brick,
  refetchBricks,
  proposalId,
}) => {
  const formik = useFormik({
    initialValues: {
      name: brick?.name,
      url: brick?.url,
      file: brick?.file,
      text: brick?.text,
      fileName: brick?.fileName,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [editBrick] = useMutation<EditBrick>(EDIT_BRICK);

  const editBrickMutation = () => {
    let input = {
      id: id,
      name: formik.values?.name,
      url: formik.values?.url,
      file: formik.values?.file,
      text: formik.values?.text,
      fileName: formik.values?.fileName,
      proposalId: proposalId,
      sortOrder: sortOrder,
      status: 0,
    };

    editBrick({
      variables: { input },
    })
      .then((res) => {
        refetchBricks({
          input: { variables: { id: proposalId } },
        });
        setModals({ ...modals, editModal: false });
        formik.resetForm();
      })
      .catch((err) => {
        setModals({ ...modals, editModal: false });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setModals({ ...modals, editModal: false })}
    >
      <Container>
        <Title>edit proposal brick</Title>

        <Form onSubmit={formik.handleSubmit}>
          <InputHelper
            label='Name'
            id='name'
            name='name'
            type='text'
            onChange={formik.handleChange}
            value={formik.values.name}
            padding='0 2.5%'
          />

          <Flex>
            <InputHelper
              label='URL'
              id='url'
              name='url'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.url}
              width='49%'
              padding='0 5%'
            />

            <MyDropzone formik={formik} brick={brick} />
          </Flex>

          <InputHelper
            label='Free Text'
            id='text'
            name='text'
            type='textarea'
            onChange={formik.handleChange}
            value={formik.values.text}
            minHeight='129px'
            padding='0 2.5%'
          />

          <Flex marginTop='51px' justify='flex-end'>
            <ButtonHover
              label='CANCEL'
              color='white'
              onClick={() => setModals({ ...modals, editModal: false })}
            />
            <ButtonHover
              label='SAVE'
              color='black'
              marginLeft='15px'
              onClick={() => editBrickMutation()}
            />
          </Flex>
        </Form>
      </Container>
    </Modal>
  );
};

export default EditModal;
