/**
 *
 * DeleteModal
 *
 */
// @ts-nocheck
import { FC } from 'react';
import { Props } from './types';
import Modal from 'react-modal';
import { customStylesDeleteModal, Container, Text, FlexRow } from './styles';
import ButtonHover from '../ButtonHover';

const DeleteModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  mutation,
  type,
  loading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      style={customStylesDeleteModal}
    >
      <Container width='556px'>
        <Text marginTop={50} fontSize={45} upperCase={true}>
          {`DELETE ${type}?`}
        </Text>
        <Text marginTop={15} fontSize={18}>
          {`Are you sure you want to delete this ${type}?`}
        </Text>

        <FlexRow marginTop={42}>
          <ButtonHover
            color='white'
            label='cancel'
            onClick={() => onRequestClose()}
            type='submit'
          />
          <ButtonHover
            color='black'
            label="yes i'm sure"
            onClick={() => mutation()}
            loading={loading}
          />
        </FlexRow>
      </Container>
    </Modal>
  );
};

export default DeleteModal;
