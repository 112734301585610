/**
 *
 * InlineNameField
 *
 */

import React, {useState} from 'react';
import {Props} from "./types";
import {Input} from './styles'
import {TextFieldProps} from '@material-ui/core/TextField'
import {ReactComponent as Edit} from "../../../images/svg/EditIcon.svg";
import styled from "styled-components";

const InlineNameField: React.FC<Props & TextFieldProps> = (props) => {
    const { readMode = true, editMode = false } = props;


    return (
        <Wrapper>
            <Input {...props} disabled={!editMode}/>
            {/*{!readMode && <Edit onClick={() => setEdit(!edit)}/>}*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  
  > svg {
    position: relative;
    width: 30px;
    height: 30px;
    right: 34px;
    top: 2px;
  }
`;

export default InlineNameField;
