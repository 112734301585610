import styled from 'styled-components';
import { mediaQueries, fonts } from '../../utils/styledTheme';

export const Title = styled.div`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  text-transform: uppercase;
  white-space: nowrap;

  ${mediaQueries.iPadPro} {
    font-size: 61px;
  }
`;

export const Row = styled.div<{
  height?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
