import { gql } from '@apollo/client';

export const SEARCH_ROOMS = gql`
  query SearchRooms($input: SearchRoomsRequestInput) {
    searchRooms(input: $input) {
      id
      name
      imageUrl
      iconUrl
      minCapacity
      maxCapacity
      proposalId
      status
    }
  }
`;
