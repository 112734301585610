/**
 *
 * SaveExit
 *
 */

import { FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import ProposalContext from '../../context/proposal/proposalContext';
import { ProposalI } from '../../context/proposal/types';

import messages from './messages';
import ButtonHover from '../ButtonHover';
import { useQuery } from '@apollo/client';
import { WhoAmI } from '../../utils/gql/login/__generated__/WhoAmI';
import { WHO_AM_I } from '../../utils/gql/login/whoAmIQuery';

const SaveExit: FC = () => {
  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { sendEditProposal, setSendEditProposal } = proposalContext;

  const { data: userData, loading: userLoading } = useQuery<WhoAmI>(WHO_AM_I, {
    fetchPolicy: 'cache-only',
  });

  const readMode =
    userData?.permissions?.roleName === 'Customer' ||
    !!!userData?.permissions?.roleName;

  if (readMode) return null;

  return (
    <ButtonHover
      label='SAVE & EXIT'
      color='white'
      width='254px'
      onClick={() => setSendEditProposal(!sendEditProposal)}
    />
  );
};

export default SaveExit;
