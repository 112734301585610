/**
 *
 * OrgModal
 *
 */

import { FC, Fragment, useEffect, useState } from 'react';
import { T } from './types';
import { StyleWrapper, Container, Title, Flex } from './styles';

import Modal from 'react-modal';
import Input from '../../components/Input';
import MultiSelect from '../MultiSelect';
import Dropzone from '../Dropzone';
import ButtonHover from '../ButtonHover';

//
import { useQuery } from '@apollo/client';
import { GetRegions } from '../../utils/gql/regions/__generated__/GetRegions';
import { GET_REGIONS } from '../../utils/gql/regions/regionsQuery';

import _ from 'lodash';

const customStyles = {
  content: {
    width: '62%',
    paddingLeft: '45px',
    paddingRight: '45px',
    borderRadius: '6px',
    paddingBottom: '56px',
    background: '#f7f7f7',
    onset: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    onset: 0,
    zIndex: 1,
  },
};

const style = {
  position: 'relative',
  zIndex: '1',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  borderRadius: '6px',
  border: '2px solid #e1e2e3',
  backgroundSize: '45px 45px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const OrgModal: FC<T> = ({
  isOpen,
  onRequestClose,
  formik,
  title,
  mutation,
}) => {
  useEffect(() => {
    console.log('formik values', formik.values);
  }, [formik.values]);

  const formatErrors = (name: string, errors: any) => {
    if (typeof errors[name] === 'string') {
      return {
        [name]: {
          value: errors[name],
        },
      };
    }

    return {
      [name]: {
        value: _.isArray(errors[name])
          ? errors[name]?.find((e: any) => e?.value)?.value
          : errors[name]?.value,
      },
    };
  };

  const { data: regionsData } = useQuery<GetRegions>(GET_REGIONS);

  if (!regionsData) {
    return null;
  }

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
    >
      <Container>
        <Title>{title}</Title>

        <form onSubmit={formik.handleSubmit}>
          <Input
            label='Organisation name'
            id='organisationName'
            name='organisationName'
            type='text'
            onChange={formik.handleChange}
            value={formik.values.organisationName}
            padding='0 2.5%'
            formik={formik}
          />

          <Flex marginTop={26} height={233}>
            <Flex
              style={{
                width: '50%',
                height: '100%',
              }}
              flexDirection='column'
            >
              <MultiSelect
                name='organisationDomains'
                addNew={true}
                // @ts-ignore
                label='Organisation Domain(s)'
                options={[]}
                isMulti={true}
                setFieldValue={formik.setFieldValue}
                width={100}
                errors={formatErrors('organisationDomains', formik.errors)}
                value={formik.values?.organisationDomains}
                placeholder='For example, logitech.com'
              />

              <MultiSelect
                name='regionId'
                addNew={false}
                label='Region'
                // @ts-ignore
                options={regionsData?.regions}
                setFieldValue={formik.setFieldValue}
                width={100}
                value={formik.values?.regionId}
                errors={formatErrors('regionId', formik.errors)}
              />
            </Flex>

            <Dropzone
              baseStyle={style}
              formik={formik}
              name='imageName'
              file='imageUrl'
              fileName='imageUrl'
              editName='image'
              backgroundWidth='100%'
              label='Logo'
              editMode={true}
              height='233px'
              marginTop={0}
              backgroundLabel='upload your logo or PNG file'
            />
          </Flex>

          <Flex marginTop={51} justify='flex-end'>
            <ButtonHover
              label='CANCEL'
              color='white'
              onClick={() => onRequestClose()}
              marginLeft='15px'
            />
            <ButtonHover
              label='SAVE'
              color='black'
              disabled={!formik.values.organisationName}
              marginLeft='15px'
              onClick={() => mutation()}
            />
          </Flex>
        </form>
      </Container>
    </Modal>
  );
};

export default OrgModal;
