import { gql } from '@apollo/client';

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment($input: CreateEquipmentRequestInput!) {
    createEquipment(input: $input) {
      equipment {
        id
        name
        imageUrl
        equipmentCategoryId
        collateral {
          id
          name
          collateralTypeId
        }
      }
    }
  }
`;
