/**
 *
 * ViewProposalContainer
 *
 */
import { FC, Fragment } from 'react';
import { isLoggedInVar } from '../../apollo/cache';
import ViewProposalPage from './page';
import LoginModal from '../../components/AuthModal/loginModal';
import { useReactiveVar } from '@apollo/client';

import CreateNewPasswordModal from '../../components/AuthModal/createNewPasswordModal';

import useQueryParams from '../../utils/hooks/useQuery';

const ViewProposalContainer: FC = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  let query = useQueryParams();

  const returnAccessKey = () => {
    return query.get('accessKey');
  };

  const returnCreatePassModal = () => {
    if (!isLoggedIn && returnAccessKey()) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      {isLoggedIn && <ViewProposalPage />}

      <LoginModal
        isOpen={isLoggedIn ? false : true}
        onRequestClose={() => console.log('close')}
        type='VIEW_PROPOSAL_EMAIL'
      />

      <CreateNewPasswordModal isOpen={returnCreatePassModal()} />
    </Fragment>
  );
};

export default ViewProposalContainer;
