/**
 *
 * Icon
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fonts } from '../../utils/styledTheme';

export const Wrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledSvg = styled.div<{ imageUrl?: string; opacity?: number }>`
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  opacity: ${(props) => props.opacity || 1};
`;

export const ToolTip = styled.div`
  position: absolute;
  top: 20px;
  left: 25px;
  transform: translateX(-100%);
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 108px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='108.002' height='39.004' viewBox='0 0 108.002 39.004'%3E%3Cpath id='Union_13' data-name='Union 13' d='M21248,19048v-33h92.787l4.717-6,4.715,6H21356v33Z' transform='translate(-21247.998 -19008.998)' fill='%23333'/%3E%3C/svg%3E");
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
`;

export const Btn = styled(motion.div)`
  width: 136px;
  height: 37px;
  background: #2e3132;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.normal};
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
`;
