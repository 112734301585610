/**
 *
 * EditInputHelper
 *
 */

import { FC } from 'react';
import { EditWrapper, EditIcon, CloseIcon, EditText } from './styles';
import { EditToggleProps, EditHelperProps } from './types';

const EditToggle: FC<EditToggleProps> = ({ open }) => (
  <EditWrapper>
    <EditIcon animate={{ opacity: open ? 0.2 : 1 }} initial={false} />
    <CloseIcon animate={{ opacity: open ? 1 : 0 }} initial={false}>
      x
    </CloseIcon>
  </EditWrapper>
);

const EditInputHelper: FC<EditHelperProps> = ({
  onClick,
  open,
  edit,
  close,
  readMode,
}) => {
  if (readMode) {
    return null;
  }
  return (
    <EditWrapper onClick={() => onClick()} marginLeft="0.5rem">
      <EditToggle open={open} />
      <EditText>{open ? close : edit}</EditText>
    </EditWrapper>
  );
};
export default EditInputHelper;
