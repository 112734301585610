import { gql } from '@apollo/client';

export const EDIT_BRICK = gql`
  mutation EditBrick($input: EditBrickRequestInput!) {
    editBrick(input: $input) {
      brick {
        name
        url
        id

        createdDate
        updatedBy
        updatedDate
        createdBy
        createdDate
      }
    }
  }
`;
