/**
 *
 * AutoSave
 *
 */

import React, { useEffect, useContext } from 'react';
import { Props } from './types';
import { StyleWrapper } from './styles';
import { useFormikContext } from 'formik';
import ProposalContext from '../../context/proposal/proposalContext';
import { ProposalI } from '../../context/proposal/types';

import useDebounce from '../../utils/hooks/useDebounce';

const AutoSave = ({ timeout = 2000, save }: Props) => {
  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { sendEditProposal } = proposalContext;
  const { values } = useFormikContext();

  const debouncedValues = useDebounce(values, timeout);

  // Effect for autosave callback
  useEffect(
    () => {
      if (debouncedValues) {
        save(values, false);
      }
    },
    [debouncedValues] // Only call effect if debounced value changes
  );

  useEffect(() => {
    if (sendEditProposal) {
      save(values, true);
    }
  }, [sendEditProposal]);

  return null;
};

export default AutoSave;
