/**
 *
 * SummaryData
 *
 */

import styled from 'styled-components';
import { fonts, colors, mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled.div``;

export const Header = styled.div`
  margin-top: 30px;
  font-family: ${fonts.bold};
  font-size: 31px;
  color: black;
  text-transform: uppercase;
`;

export const FlexRow = styled.div<{
  marginTop?: number;
  justifyContent?: string;
  alignItems?: string;
}>`
  margin-top: ${(props) => props.marginTop}px;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  display: flex;
  width: 100%;
  height: 173px;

  ${mediaQueries.iPadPro} {
    width: 897px;
  }
`;

export const FlexItem = styled.div`
  width: 32%;
  border: 1px solid #707070;
  border-radius: 6px;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.iPadPro} {
    width: 285px;
  }
`;

export const Title = styled.div`
  margin-top: 15px;
  font-family: ${fonts.bold};
  font-size: 18px;
  color: ${colors.brightBlue};
  white-space: nowrap;

  ${mediaQueries.iPadPro} {
    font-size: 21px;
  }
`;

export const Value = styled.div`
  margin-top: 10px;
  font-family: ${fonts.bold};
  font-size: 73px;
  color: black;
`;
