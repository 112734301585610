import { useState, Fragment } from 'react';

import {
  GridItemWrapper,
  GridImage,
  Title,
  Drag,
  Edit,
  Delete,
  Overlay,
} from './styles';

import Eye from './Eye';

import EditModal from '../BrickModal/EditModal';
import DeleteModal from '../BrickModal/DeleteModal';

import { colors } from '../../utils/styledTheme';

import { useInteractionState } from '../../utils/hooks/useInteraction';
import { WHO_AM_I } from '../../utils/gql/login/whoAmIQuery';
import { useQuery } from '@apollo/client';
import { GET_STATS } from '../../utils/gql/clickstats/getProposalStats';
import { useParams } from 'react-router';

const GridItem = ({
  forwardedRef,
  style,
  sortOrder,
  brick,
  setMenuHelper,
  menu,
  width,
  refetchBricks,
  editMode,
  proposalId,
  type,
}: GridItemProps) => {
  const [modals, setModals] = useState<ModalI>({
    editModal: false,
    deleteModal: false,
  });

  const { data: userData } = useQuery(WHO_AM_I);
  const { id: paramsId }: any = useParams();

  const { data } = useQuery(GET_STATS, { variables: { id: paramsId } });

  const stats = data && data?.proposalStats;

  const propStats = stats && stats?.proposalStats;

  const componentStats = propStats?.componentStats;

  const canCreateInteraction = userData?.permissions?.roleName === 'Customer';

  const { editModal, deleteModal } = modals;

  const { name, colorCode, id } = brick;

  const returnBackground = () => {
    if (id === menu?.brick?.id && menu?.isOpen && !editMode) {
      return '#fff';
    } else return colorCode;
  };

  const returnColor = () => {
    if (id === menu?.brick?.id && menu?.isOpen && !editMode) {
      return colors.lightBlue;
    } else return '#fff';
  };

  const returnBorder = () => {
    if (id === menu?.brick?.id && menu?.isOpen && !editMode) {
      return colors.lightBlue;
    } else return colorCode;
  };

  const returnDuration = () => {
    if (id === menu?.brick?.id) {
      return 0.3;
    } else return 1;
  };

  const openInNewTab = (link: string) => {
    const win = window.open(link, '_blank');
    win && win.focus();
  };

  const { createBrickInteractionMutation } = useInteractionState(brick);

  const openMenu = () => {
    setMenuHelper(sortOrder, width.clientWidth, brick);

    canCreateInteraction && createBrickInteractionMutation();

    if (!brick.fileUrl && !editMode && brick?.url) {
      openInNewTab(brick?.url);
    }
  };

  const openCmsMenu = () => {
    setMenuHelper(sortOrder, width.clientWidth, brick);

    canCreateInteraction && createBrickInteractionMutation();

    if (!brick.fileUrl && brick?.url) {
      openInNewTab(brick?.url);
    }
  };

  const EditIcons = () => (
    <Fragment>
      <Edit onClick={() => setModals({ ...modals, editModal: !editModal })} />

      <Delete
        onClick={() => setModals({ ...modals, deleteModal: !deleteModal })}
      />
    </Fragment>
  );

  const hasInteractions = () => {
    if (
      componentStats &&
      componentStats?.find((component: any) => id === component?.entityTableId)
        ?.interactions > 0
    )
      return true;
    return false;
  };

  return (
    <GridItemWrapper
      ref={forwardedRef}
      style={style}
      onClick={type !== 'CMS' ? () => openMenu() : () => console.log(null)}
    >
      <GridImage
        animate={{
          background: returnBackground(),
          borderColor: returnBorder(),
        }}
        transition={{ duration: returnDuration() }}
        initial={false}
      >
        {type === 'CLICK_STATS' && hasInteractions() && (
          <Overlay>
            {`${
              componentStats &&
              componentStats?.find(
                (component: any) => id === component?.entityTableId
              )?.interactions
            } CLICK(S)`}
          </Overlay>
        )}
        {name && <Title color={returnColor()}>{name}</Title>}
        {editMode && <Drag />}
        {type === 'CMS' && (
          <Eye
            openMenu={() => openCmsMenu()}
            colorCode={colorCode}
            isOpen={menu?.isOpen}
          />
        )}

        {editMode && <EditIcons />}

        <EditModal
          isOpen={editModal}
          modals={modals}
          setModals={setModals}
          id={id}
          sortOrder={sortOrder}
          brick={brick}
          refetchBricks={refetchBricks}
          proposalId={proposalId}
        />
        <DeleteModal
          isOpen={deleteModal}
          modals={modals}
          setModals={setModals}
          id={id}
          refetchBricks={refetchBricks}
          proposalId={proposalId}
        />
      </GridImage>
    </GridItemWrapper>
  );
};

export default GridItem;
