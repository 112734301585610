import { FC } from 'react';
import { ModalProps } from './types';
import Modal from 'react-modal';

import {
  Title,
  DeleteContainer,
  Text,
  TextArea,
  FlexRow,
  StyledHoverButton,
} from './styles';

import { useMutation } from '@apollo/client';
import { DELETE_BRICK } from '../../utils/gql/bricks/deleteBrickMutation';
import { DeleteBrick } from '../../utils/gql/bricks/__generated__/DeleteBrick';

const customStyles = {
  content: {
    maxWidth: 973,
    minHeight: 330,
    margin: 'auto',
    height: 330,
    borderRadius: '6px',
    background: '#f7f7f7',
    padding: 0,
    onset: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 999999999999,
  },
};

const DeleteModal: FC<ModalProps> = ({
  isOpen,
  modals,
  setModals,
  id,
  refetchBricks,
  proposalId,
}) => {
  const [deleteBrick] = useMutation<DeleteBrick>(DELETE_BRICK);

  const deleteBrickMutation = () => {
    let input = {
      id: id,
      reason: 'no reason',
    };

    deleteBrick({
      variables: { input },
    })
      .then((res) => {
        refetchBricks({
          input: { variables: { id: proposalId } },
        });
        setModals({ ...modals, editModal: false, deleteModal: false });
      })
      .catch((err) => {
        setModals({ ...modals, editModal: false, deleteModal: false });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setModals({ ...modals, deleteModal: false })}
    >
      <DeleteContainer>
        <Title marginTop='49px' marginBottom='21px'>
          DELETE PROPOSAL BRICK
        </Title>
        <Text>Are you sure you want to delete this proposal brick?</Text>

        <FlexRow>
          <StyledHoverButton
            label='YES, I WANT TO DELETE THIS PROPOSAL BRICK'
            color='black'
            width='586px'
            onClick={() => deleteBrickMutation()}
          />

          <StyledHoverButton
            label='CANCEL'
            color='white'
            width='220px'
            onClick={() => setModals({ ...modals, deleteModal: false })}
          />
        </FlexRow>
      </DeleteContainer>
    </Modal>
  );
};

export default DeleteModal;
