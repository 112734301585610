/**
 *
 * ProposalContent
 *
 */

import React, { FC } from 'react';
import { Props } from './types';
import { StyleWrapper, FlexRow, Text } from './styles';
import EditInputHelper from '../EditInputHelper';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

import { useQuery } from '@apollo/client';
import { WhoAmI } from '../../utils/gql/login/__generated__/WhoAmI';
import { WHO_AM_I } from '../../utils/gql/login/whoAmIQuery';

const ProposalContent: FC<Props> = ({ editMode, setEditMode }) => {
  const { data: userData, loading: userLoading } = useQuery<WhoAmI>(WHO_AM_I, {
    fetchPolicy: 'cache-only',
  });

  const readMode =
    userData?.permissions?.roleName === 'Customer' ||
    !!!userData?.permissions?.roleName;

  const intl = useIntl();

  return (
    <StyleWrapper>
      <FlexRow height="29px">
        <Text>
          <FormattedMessage {...messages.header} />
        </Text>

        <EditInputHelper
          onClick={() => setEditMode(!editMode)}
          open={editMode}
          edit={intl.formatMessage(messages.edit)}
          close={intl.formatMessage(messages.doneEdit)}
          readMode={readMode}
        />
      </FlexRow>
    </StyleWrapper>
  );
};

export default ProposalContent;
