/**
 *
 * CreateNewPassword
 *
 */
import { Fragment } from 'react';
import CreateNewPasswordModal from '../../components/AuthModal/createNewPasswordModal';
import Background from '../../components/Background/index';
import imageUrl from '../../images/salesBackground/salesBackground.jpg';

const CreateNewPassword = () => (
  <Fragment>
    <Background imageUrl={imageUrl} />
    <CreateNewPasswordModal isOpen={true} />
  </Fragment>
);

export default CreateNewPassword;
