import { useContext, useState, useEffect } from 'react';
import { LABEL } from './types';

import { useQuery, useMutation } from '@apollo/client';
import { GET_TEMPLATE_BRICKS } from '../../utils/gql/templateBricks/templateBricksQuery';
import { CREATE_BRICK } from '../../utils/gql/bricks/createBrickMutation';
import { CreateBrick } from '../../utils/gql/bricks/__generated__/CreateBrick';

import { useFormik } from 'formik';
import usePermissions from '../../utils/hooks/usePermissions';

const useCmsState = () => {
  const { currentRouteSchema } = usePermissions();

  let subItems = currentRouteSchema[0]?.nav?.subItems;

  const returnSubItemsWithToggles = (arr: any) => {
    if (!arr) return;

    let reformattedArray = arr?.map((obj: any, index: number) => {
      return { ...obj, toggle: index === 0 ? true : false };
    });

    return reformattedArray;
  };

  const [tabs, setTabs] = useState<any>([]);

  const changeTab = (index: number) => {
    // reset toggles
    let newArr = tabs?.map((obj: any) => {
      return { ...obj, toggle: false };
    });

    let newTab = { ...newArr[index], toggle: true };

    newArr[index] = newTab;

    setTabs(newArr);
  };

  useEffect(() => {
    setTabs(returnSubItemsWithToggles(subItems));
  }, [subItems]);

  const returnComponent = (label: LABEL) => {
    const found = tabs?.find((tab: any) => tab?.label === label);
    return found?.toggle as boolean;
  };

  const templateId = '3944048e6eeb3c347bf1382dac4f137a';

  const { data: templateBricks, refetch: refetchTemplate } = useQuery<any>(
    GET_TEMPLATE_BRICKS,
    {
      variables: { id: templateId },
      fetchPolicy: 'network-only',
    }
  );

  const [addBrick, setAddBrick] = useState<boolean>(false);

  const formikCreateBrick = useFormik({
    initialValues: {
      name: '',
      url: '',
      file: '',
      text: '',
      fileName: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [createBrick] = useMutation<CreateBrick>(CREATE_BRICK);

  const createBrickMutation = () => {
    let input = {
      name: formikCreateBrick.values?.name,
      url: formikCreateBrick.values?.url,
      file: formikCreateBrick.values?.file,
      fileName: formikCreateBrick.values?.fileName,
      text: formikCreateBrick.values?.text,
      sortOrder: 0,
      proposalId: templateId,
    };

    createBrick({
      variables: { input },
    })
      .then((res) => {
        refetchTemplate();
        setAddBrick(false);
        formikCreateBrick.resetForm();
      })
      .catch((err) => {
        formikCreateBrick.resetForm();
      });
  };

  return {
    tabs,
    changeTab,
    returnComponent,
    templateBricks: templateBricks?.proposal?.templateBricks,
    refetchTemplate,
    templateId,
    addBrick,
    setAddBrick,
    formikCreateBrick,
    createBrickMutation,
  };
};

export default useCmsState;
