/**
 *
 * Slider
 *
 */

import React, { FC, Fragment, useEffect } from 'react';
import { Props } from './types';
import { Wrapper, StyledSlider, Labels, Label, Question } from './styles';
import { colors } from '../../utils/styledTheme';

const Slider: FC<Props> = ({ value, question, marginTop }) => {
  return (
    <Fragment>
      {question && <Question marginTop={marginTop}>{question}</Question>}

      <Wrapper>
        <StyledSlider
          type='range'
          list='tickmarks'
          step={1}
          max={4}
          min={1}
          value={value}
        />
        <datalist id='tickmarks'>
          <option value='1'></option>
          <option value='2'></option>
          <option value='3'></option>
          <option value='4'></option>
        </datalist>

        <Labels>
          <Label
            animate={{
              color: value === 1 ? colors.brightBlue : colors.fontColor,
            }}
          >
            1
          </Label>

          <Label
            animate={{
              color: value === 2 ? colors.brightBlue : colors.fontColor,
            }}
          >
            2
          </Label>

          <Label
            animate={{
              color: value === 3 ? colors.brightBlue : colors.fontColor,
            }}
          >
            3
          </Label>

          <Label
            animate={{
              color: value === 4 ? colors.brightBlue : colors.fontColor,
            }}
          >
            4
          </Label>
        </Labels>
      </Wrapper>
    </Fragment>
  );
};

export default Slider;
