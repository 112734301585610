import { FC } from 'react';
import DefaultProvider from './DefaultProvider';
import TouchProvider from './TouchProvider';

import { Wrapper } from './styles';
import { colors } from '../../utils/styledTheme';

const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const Provider: FC<ProviderProps> = ({
  refetchBricks,
  bricks,
  type,
  editMode,
  proposalId,
  setAddBrick,
}) => {
  const returnBackgroundColor = (type: BRICKTYPE) => {
    if (type === 'DEFAULT') return colors.offWhite;
    if (type === 'CMS') return colors.offWhite;
    if (type === 'PREVIEW') return colors.normalWhite;
    if (type === 'CLICK_STATS') return colors.normalWhite;
  };

  if (!bricks) {
    return null;
  }

  return (
    <Wrapper background={returnBackgroundColor(type)}>
      {isTouchDevice() && (
        <TouchProvider
          bricks={bricks}
          refetchBricks={refetchBricks}
          type={type}
          editMode={editMode}
          proposalId={proposalId}
          setAddBrick={setAddBrick}
        />
      )}
      {!isTouchDevice() && (
        <DefaultProvider
          bricks={bricks}
          refetchBricks={refetchBricks}
          type={type}
          editMode={editMode}
          proposalId={proposalId}
          setAddBrick={setAddBrick}
        />
      )}
    </Wrapper>
  );
};

export default Provider;
