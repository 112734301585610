export const getImage = async (url: string) => {
  const response = await fetch(url, { mode: 'no-cors' });
  const blob = await response.blob();
  const fileReader: any = new FileReader();
  fileReader.readAsDataURL(blob);

  const transformToBase64 = new Promise((resolve, reject) => {
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = () => reject(fileReader.error);
  });

  const base64 = await transformToBase64;
  return base64;
};
