import { gql } from '@apollo/client';

export const MY_PROPOSALS = gql`
  query MyProposals($input: MyProposalsRequestInput) {
    myProposals(input: $input) {
      id
      name
      customerId
      updatedBy {
        id
        firstName
        lastName
        email
      }
      version
      status
      organisation {
        id
        name
        logoUrl
      }
      createdBy {
        id
        firstName
        lastName
        email
      }
      proposalAudience {
        id
        createdDate
      }
      sentTo {
        id
        firstName
        lastName
        email
        status
      }
      createdDate
      updatedDate
    }
  }
`;
