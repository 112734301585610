/**
 *
 * ProposalSnapShot
 *
 */

import { FC } from 'react';
import { Props } from './types';
import {
  StyleWrapper,
  Container,
  ProposalName,
  Org,
  FlexRow,
  RowHeader,
  Item,
  Close,
} from './styles';
import SentTo from '../SentTo';
import moment from 'moment';

const ProposalSnapShot: FC<Props> = ({ proposal, sentTo, onRequestClose }) => {
  const formatDate = (date: string) => {
    const formatedDate = moment(date);
    return formatedDate.format('DD-MM-YYYY');
  };

  const returnSentTo = () => {
    const customerId = proposal?.customerId;
    const sentTo = proposal?.sentTo;
    const person = sentTo?.filter((el: any) => el.id === customerId);

    console.log('person', person);

    return (
      person && person[0] && person[0].firstName + ' ' + person[0].lastName
    );
  };

  const onClick = (e: any) => {
    e.stopPropagation();
    onRequestClose();
  };

  return (
    <StyleWrapper>
      <Container onClick={(e) => onClick(e)}>
        <Close>x</Close>
        <ProposalName>{proposal?.name}</ProposalName>

        <Org>{proposal?.organisation?.name}</Org>

        <FlexRow marginTop='18px'>
          <RowHeader width='22%'>Date Created:</RowHeader>
          <Item width='26%'>{formatDate(proposal?.createdDate)}</Item>
          <RowHeader width='6%'>By:</RowHeader>
          <Item>
            {proposal?.createdBy?.firstName + proposal?.createdBy?.lastName}
          </Item>
        </FlexRow>

        <FlexRow marginTop='10px'>
          <RowHeader width='22%'>Last Edited:</RowHeader>
          <Item width='26%'>{formatDate(proposal?.updatedDate)}</Item>
          <RowHeader width='6%'>By:</RowHeader>
          <Item>
            {proposal?.updatedBy?.firstName + proposal?.updatedBy?.lastName}
          </Item>
        </FlexRow>

        <FlexRow marginTop='10px' borderBottom={true} paddingBottom='22px'>
          <RowHeader width='22%'>Version:</RowHeader>
          <Item width='26%'>{proposal?.version}</Item>
        </FlexRow>

        {/* ask about date sent */}
        <FlexRow marginTop='22px'>
          <RowHeader width='22%'>Sent to:</RowHeader>

          <Item>{returnSentTo()}</Item>
        </FlexRow>

        <FlexRow marginTop='10px' borderBottom={true} paddingBottom='22px'>
          <RowHeader width='22%'>Date Sent:</RowHeader>
          <Item>{formatDate(proposal?.proposalAudience?.createdDate)}</Item>
        </FlexRow>

        {/* ask if proposal audience has a name  */}
        <FlexRow marginTop='22px' paddingBottom='32px'>
          <RowHeader width='22%'>Shared with:</RowHeader>
          <SentTo users={sentTo} />
        </FlexRow>
      </Container>
    </StyleWrapper>
  );
};

export default ProposalSnapShot;
