// @ts-nocheck
import jwtDecode from 'jwt-decode';
import { isLoggedInVar } from './cache';
const isDev = process.env.NODE_ENV !== 'production';
const uri = isDev ? process.env.REACT_APP_API_ENDPOINT : '#{API_URL}';

export const setAccessToken = (s: string) => {
  localStorage.removeItem('accessToken');
  localStorage.setItem('accessToken', s);
};

export const setRefreshToken = (s: string | null) => {
  localStorage.removeItem('refreshToken');
  localStorage.setItem('refreshToken', s);
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken;
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return refreshToken;
};
// prettier-ignore
export const fetchAccessToken = () => {
  const refreshToken = getRefreshToken();

  return fetch(uri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${refreshToken}`,
      'credentials': 'include',
    },
    body: JSON.stringify({
      query: `
      query RefreshAuthTokens($input: RefreshAuthTokensInput!) {
        refreshAuthTokens(input: $input) {
          accessToken
          refreshToken
        }
      }
        `,
      variables: {
        input: { refreshToken },
      },
    }),
  }).then((res) => res.json()).then((result) => {
      const accessToken = result?.data?.refreshAuthTokens?.accessToken;
      const refreshToken = result?.data?.refreshAuthTokens?.refreshToken;

  
      if (accessToken) {
        console.log('access', accessToken);
        setAccessToken(accessToken);
      }

      if (refreshToken) {
        console.log('refresh', refreshToken);
        setRefreshToken(refreshToken);
        const { exp } = jwtDecode(refreshToken);
        /* if current time is greater token is false invalid */
        if (Date.now() >= exp * 1000) {
          console.log('refresh token invalid');
          setRefreshToken(null);
          isLoggedInVar(false);
        }
      }

   
    }).then((result) => result?.data?.refreshAuthTokens);
};
// prettier-ignore
