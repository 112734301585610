/**
 *
 * * CreateProposal Messages
 *
 */

import { defineMessages } from 'react-intl';

export const scope = 'Components.CreateProposal';

export default defineMessages({
  proposalProjectName: {
    id: `${scope}.proposalProjectName`,
    defaultMessage: 'Proposal / Project Name *',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'Customer First Name *',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Customer Last Name *',
  },
  step1: {
    id: `${scope}.step1`,
    defaultMessage: 'Step 1',
  },
  clientDetails: {
    id: `${scope}.clientDetails`,
    defaultMessage: ' - Client Details',
  },
  customerEmail: {
    id: `${scope}.customerEmail`,
    defaultMessage: 'For example, john.smith',
  },
  emailDomain: {
    id: `${scope}.emailDomain`,
    defaultMessage: 'Email Domain *',
  },
  nextStep: {
    id: `${scope}.nextStep`,
    defaultMessage: 'Next Step',
  },
  createNew: {
    id: `${scope}.createNew`,
    defaultMessage: 'Create New',
  },
  pleaseSelectOrganisation: {
    id: `${scope}.pleaseSelectOrganisation`,
    defaultMessage: 'Please select the organisation *',
  },
  noResultsFound: {
    id: `${scope}.noResultsFound`,
    defaultMessage: 'No Results Found',
  },
});
