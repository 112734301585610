/**
 *
 * CmsTabs
 *
 */

import styled from 'styled-components';
import { fonts, colors } from '../../utils/styledTheme';
import { motion } from 'framer-motion';

export const Tabs = styled.div`
  margin: 50px auto 73px;
  display: flex;
  height: 54px;
  width: fit-content;
  border-bottom: 2px solid black;
`;

export const Tab = styled(motion.div)<{ borderBottom?: boolean }>`
  position: relative;
  font-family: ${fonts.bold};
  font-size: 24px;
  text-transform: uppercase;
  color: ${colors.logitechBlack};
  height: 54px;
  cursor: pointer;
  border-style: solid;

  border-color: ${colors.logitechBlack};
  border-width: 0px 0px 2px 0px;

  &:not(:first-of-type):not(:last-of-type) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:first-of-type {
    padding-right: 20px;
  }

  &:last-of-type {
    padding-left: 20px;
  }
`;
// border-bottom: ${(props) =>
//   props.borderBottom ? `3px solid ${colors.brightBlue}` : 'palevioletred'};
