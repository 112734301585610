/**
 *
 * CollTable
 *
 */

import styled from 'styled-components';
import { fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  margin-top: 90px;
  height: 45px;
  width: 100%;
  border-bottom: 1px solid black;
`;

export const Text = styled.div`
  font-size: 20px;
  font-family: ${fonts.bold};
  color: black;
  margin-left: 30px;
  display: block;
  margin-top: auto;
`;

export const FlexRev = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

export const Pages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 87px;
  width: 100%;
  height: 24px;
`;
