import { gql } from '@apollo/client';

export const DELETE_BRICK = gql`
  mutation DeleteBrick($input: DeleteBrickRequestInput!) {
    deleteBrick(input: $input) {
      brick {
        id
      }
    }
  }
`;
