import { useEffect, useState, useReducer } from 'react';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { SEARCH_EQUIPMENTS_TABLE } from '../../utils/gql/equipments/searchEquipments';
import { CREATE_EQUIPMENT } from '../../utils/gql/equipments/createEquipment';
import { DELETE_EQUIPMENT } from '../../utils/gql/equipments/deleteEquipment';
import { EDIT_EQUIPMENT } from '../../utils/gql/equipments/editEquipment';
import useDebounce from '../../utils/hooks/useDebounce';
import TechReducer from './technologyTableReducer';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  techTypes,
  collateralTypes,
  returnCollateralType,
} from '../../constants/equipments';
import { GET_EQUIPMENT } from '../../utils/gql/equipments/getEquipment';
import { CREATE_COLLATERAL } from '../../utils/gql/equipments/createCollateral';
import { EDIT_COLLATERAL } from '../../utils/gql/equipments/editCollateral';
import { DELETE_COLLATERAL } from '../../utils/gql/equipments/deleteCollateral';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required field'),
  equipmentCategoryId: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required('Required field'),
  }),
  roomIds: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required('Required field'),
        label: Yup.string(),
      })
    )
    .min(1, 'Required field')
    .required('Required field'),
});

export const useTechnologyState = () => {
  const initialState = {
    selectedTechnology: {
      id: null,
    },
    selectedCollateral: {
      id: null,
    },
    selectedModal: {
      delete: false,
      create: false,
      edit: false,
      createCollateral: false,
      editCollateral: false,
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(TechReducer, initialState);

  // actions
  const setCreateModal = () => {
    dispatch({
      type: 'SET_CREATE',
    });
  };

  const setEditModal = (selectedTechnology: any) => {
    dispatch({
      type: 'SET_EDIT',
      payload: { selectedTechnology },
    });
  };

  const setDeleteModal = (selectedTechnology: any) => {
    dispatch({
      type: 'SET_DELETE',
      payload: { selectedTechnology },
    });
  };

  const resetAllModals = () => {
    createEquipFormik.resetForm();
    editEquipFormik.resetForm();
    dispatch({
      type: 'RESET_ALL_MODALS',
    });
  };

  const setAddCollModal = (selectedTechnology: any) => {
    dispatch({
      type: 'SET_CREATE_COLLATERAL',
      payload: { selectedTechnology },
    });
  };

  const closeCollateral = () => {
    dispatch({
      type: 'CLOSE_COLLATERAL',
    });
  };

  const setEditCollateral = (selectedCollateral: any) => {
    dispatch({
      type: 'SET_EDIT_COLLATERAL',
      payload: { selectedCollateral },
    });
  };

  const setDeleteCollateral = (selectedCollateral: any) => {
    dispatch({
      type: 'SET_DELETE_COLLATERAL',
      payload: { selectedCollateral },
    });
  };

  // search form
  const formikEquipSearch = useFormik({
    initialValues: {
      type: { value: 0 },
      name: '',
      searchTerm: null,
    },
    onSubmit: (values) => console.log(values),
  });

  const debouncedSearchTerm = useDebounce(
    formikEquipSearch.values.searchTerm,
    500
  );

  const {
    data: searchEquipments,
    loading,
    error,
    refetch,
  } = useQuery(SEARCH_EQUIPMENTS_TABLE, {
    variables: { input: { name: debouncedSearchTerm, skip: null, take: null } },
  });

  const { selectedModal, selectedTechnology, selectedCollateral } = state;

  const refetchEquipHelper = () => {
    if (
      formikEquipSearch.values.searchTerm === '' ||
      formikEquipSearch.values.searchTerm === null
    ) {
      refetch({
        input: { name: '', skip: null, take: null },
      });
    }
    if (debouncedSearchTerm) {
      refetch({
        input: { name: debouncedSearchTerm, skip: null, take: null },
      });
    }
  };

  useEffect(() => {
    refetchEquipHelper();

    // eslint-disable-next-line
  }, [formikEquipSearch.values.searchTerm, debouncedSearchTerm]);

  // create formik
  const createEquipFormik = useFormik({
    initialValues: {
      name: '',
      imgPreview: null,
      image: undefined,
      imageUrl: '',
      logoImageName: '',
      equipmentCategoryId: {
        label: '',
        value: '',
      },
      roomIds: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => console.log(values),
  });

  const editEquipFormik = useFormik({
    initialValues: {
      name: selectedTechnology?.name,
      imgPreview: selectedTechnology?.imageUrl,
      image: '',
      logoImageName: '',
      imageUrl: selectedTechnology?.imageUrl,
      equipmentCategoryId: {
        value: techTypes?.find(
          (type) => type.label === selectedTechnology?.type
        )?.value,
        label: selectedTechnology?.type,
      },
      roomIds: selectedTechnology?.rawSuitableRooms?.map((room: any) => ({
        label: room.label,
        value: room.value,
      })),
    },
    enableReinitialize: true,
    onSubmit: (values) => console.log(values),
  });

  // create mutation
  const [createMutation, { loading: createMutationLoading }] =
    useMutation(CREATE_EQUIPMENT);

  const createEquipmentMutation = () => {
    if (createMutationLoading) return;
    const input = {
      name: createEquipFormik.values.name,
      roomIds: createEquipFormik.values.roomIds.map(
        (room: { value: string }) => room.value
      ),
      image: createEquipFormik.values.imageUrl,
      sortOrder: 1,
      imageName: createEquipFormik.values.logoImageName,
      equipmentCategoryId: Number(
        createEquipFormik.values?.equipmentCategoryId?.value
      ),
    };
    createMutation({ variables: { input } })
      .then((response) => {
        resetAllModals();
        createEquipFormik.resetForm();
        refetchEquipHelper();
        enqueueSnackbar('Equipment created successfully', {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  // edit mutation
  const [editMutation, { loading: editMutationLoading }] =
    useMutation(EDIT_EQUIPMENT);

  const editEquipmentMutation = async () => {
    if (editMutationLoading) return;
    const replaceImage: any = editEquipFormik.values.image;
    const input: any = {
      id: selectedTechnology.id,
      name: editEquipFormik.values.name,
      roomIds: editEquipFormik.values.roomIds.map(
        (room: { value: string }) => room.value
      ),
      sortOrder: 1,
      equipmentCategoryId: Number(
        editEquipFormik.values?.equipmentCategoryId?.value
      ),
    };
    if (editEquipFormik.values.imageUrl && editEquipFormik.values.image) {
      input.image = editEquipFormik.values.imageUrl;
      input.imageName = editEquipFormik.values.image;
    }
    editMutation({ variables: { input } })
      .then((response) => {
        resetAllModals();
        editEquipFormik.resetForm();
        refetchEquipHelper();
        enqueueSnackbar('Equipment edited successfully', {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const deleteEquipFormik = useFormik({
    initialValues: {
      id: selectedTechnology?.id,
      reason: '',
    },
    onSubmit: (values) => console.log(values),
  });

  // delete mutation
  const [deleteMutation] = useMutation(DELETE_EQUIPMENT);

  const deleteEquipmentMutation = () => {
    const input = {
      id: selectedTechnology?.id,
      reason: deleteEquipFormik.values.reason,
    };
    deleteMutation({ variables: { input } })
      .then(() => {
        resetAllModals();
        deleteEquipFormik.resetForm();
        refetchEquipHelper();
        enqueueSnackbar('Equipment deleted successfully', {
          variant: 'success',
        });
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  };

  // COLLATERAL

  const {
    data: collateralData,
    loading: collateralLoading,
    refetch: refetchColl,
  } = useQuery(GET_EQUIPMENT);

  useEffect(() => {
    if (selectedTechnology?.id) {
      refetchColl({ id: selectedTechnology?.id });
    }
  }, [selectedTechnology]);

  // create collateral
  const createCollFormik = useFormik({
    initialValues: {
      collateralTypeId: {
        label: '',
        value: '',
      },
      url: '',
      file: '',
      fileName: '',
      sortOrder: 1,
      name: '',
    },
    onSubmit: (values) => console.log(values),
  });

  const [createCollateral, { loading: createCollLoading }] =
    useMutation(CREATE_COLLATERAL);

  const createCollateralMutation = () => {
    const input = {
      collateralTypeId: Number(createCollFormik.values.collateralTypeId.value),
      equipmentId: selectedTechnology?.id,
      url: createCollFormik.values.url,
      file: createCollFormik.values.file,
      fileName: createCollFormik.values.fileName,
      sortOrder: 1,
      name: createCollFormik.values.name,
    };

    createCollateral({ variables: { input } })
      .then((res) => {
        closeCollateral();
        if (selectedTechnology?.id) {
          refetchColl({ id: selectedTechnology?.id });
        }
        createCollFormik.resetForm();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    console.log('selected coll', selectedCollateral);
    console.log(collateralData);
  }, [selectedCollateral]);

  const editCollFormik = useFormik({
    initialValues: {
      id: selectedCollateral?.id,
      name: selectedCollateral?.name,
      url: selectedCollateral?.url,
      file: selectedCollateral?.fileUrl,
      fileName: '',
      sortOrder: selectedCollateral?.sortOrder,
      collateralTypeId: {
        value: selectedCollateral?.collateralTypeId?.toString(),
        label: returnCollateralType(selectedCollateral?.collateralTypeId),
      },
      equipmentId: selectedTechnology?.id,
    },
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  //   edit mutation
  const [editCollateral] = useMutation(EDIT_COLLATERAL);

  const editCollateralMutation = () => {
    let input: any = {
      id: selectedCollateral?.id,
      name: editCollFormik.values?.name,
      url: editCollFormik.values?.url,
      sortOrder: selectedCollateral?.sortOrder,
      collateralTypeId: selectedCollateral?.collateralTypeId,
      equipmentId: selectedTechnology?.id,
    };

    if (editCollFormik?.values?.file && editCollFormik?.values?.fileName) {
      input.file = editCollFormik?.values.file;
      input.fileName = editCollFormik.values?.fileName;
    }

    editCollateral({ variables: { input } })
      .then((_) => {
        closeCollateral();
        if (selectedTechnology?.id) {
          refetchColl({ id: selectedTechnology?.id });
        }
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const [deleteCollateral] = useMutation(DELETE_COLLATERAL);

  const deleteCollateralMutation = () => {
    const input = {
      id: selectedCollateral?.id,
      reason: 'no reason',
    };

    console.log('delete input', input);
    deleteCollateral({ variables: { input } })
      .then(() => {
        closeCollateral();
        if (selectedTechnology?.id) {
          refetchColl({ id: selectedTechnology?.id });
        }
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  };

  return {
    // equipments
    searchEquipments: searchEquipments?.searchEquipments,
    formikEquipSearch,
    createEquipFormik,
    editEquipFormik,
    deleteEquipFormik,
    createEquipmentMutation,
    editEquipmentMutation,
    deleteEquipmentMutation,
    refetchEquipHelper,

    // modals
    selectedModal,
    selectedTechnology,
    setCreateModal,
    setDeleteModal,
    setEditModal,
    resetAllModals,
    setAddCollModal,
    closeCollateral,
    setEditCollateral,
    setDeleteCollateral,

    // coll
    collateralData,
    createCollFormik,
    editCollFormik,
    selectedCollateral,
    createCollateralMutation,
    editCollateralMutation,
    deleteCollateralMutation,
  };
};
