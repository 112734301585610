import { SvgIcon } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  ShopItemImg,
  DisplayItem,
  CheckBoxWrapper,
  CheckBox,
  Text,
  InfoWrapper,
  ShopItemInfo,
  Link,
} from './styles';
import { ShopItem as ShopItemT } from './types';
import { ReactComponent as DataSheet } from '../../images/hardware/DataSheet.svg';
import { ReactComponent as ProductLiterature } from '../../images/hardware/ProductLiterature.svg';
import { ReactComponent as ProductVideos } from '../../images/hardware/ProductVideos.svg';
import { ReactComponent as WiringDiagrams } from '../../images/hardware/WiringDiagrams.svg';

const icons: any = {
  0: ProductLiterature,
  1: DataSheet,
  2: ProductVideos,
  3: WiringDiagrams,
};

export const ShopItem = ({
  imageUrl,
  name,
  collateral,
  onChange,
  isSelected,
  readOnly,
}: ShopItemT) => {
  useEffect(() => {
    console.log('collateral', collateral);
  }, [collateral]);
  return (
    <DisplayItem>
      <ShopItemImg src={imageUrl} />
      <CheckBoxWrapper>
        <CheckBox
          onChange={onChange}
          type='checkbox'
          name='equipment'
          id='equipment'
          checked={isSelected}
          value={isSelected ? 'true' : 'false'}
          readOnly={readOnly}
        />
        <Text>{name}</Text>
      </CheckBoxWrapper>
      <ShopItemInfo>
        {collateral
          ?.filter((eq: any) => eq.status !== -1)
          ?.map((info, index) => (
            <InfoWrapper key={`shopiteminfo-${index}`}>
              <SvgIcon
                component={icons[info.collateralTypeId]}
                viewBox='0 0 32 32'
              />
              <Link href={info.url || info.fileUrl} target='_blank'>
                {info.name}
              </Link>
            </InfoWrapper>
          ))}
      </ShopItemInfo>
    </DisplayItem>
  );
};
