/**
 *
 * Workspaces
 *
 */

import styled from 'styled-components';

export const FlexReverse = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
