/**
 *
 * Carousel
 *
 */

import React, { useEffect } from 'react';
import { Props } from './types';
import { StyleWrapper, Title } from './styles';
import Slides from 'react-multi-carousel';
import { Slide } from './Slide';
import 'react-multi-carousel/lib/styles.css';

import { useIntl } from 'react-intl';
import messages from './messages';

const Carousel = ({ isOpen, rooms, selectedRoom, setSelectedRoom }: Props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const intl = useIntl();

  if (!rooms || !isOpen) {
    return null;
  }

  return (
    <StyleWrapper>
      {rooms.length > 0 && <Title>{intl.formatMessage(messages.title)}</Title>}
      <Slides
        responsive={responsive}
        autoPlay={false}
        infinite
        centerMode={false}
        renderButtonGroupOutside={false}
      >
        {rooms?.map((item: any, index: number) => (
          <Slide
            isSelected={
              selectedRoom?.name?.toLowerCase() === item?.name?.toLowerCase()
            }
            onClick={() => setSelectedRoom({ name: item?.name, id: item?.id })}
            key={`carousel-slide-${index}`}
            image={item.imageUrl}
            name={item.name}
          />
        ))}
      </Slides>
    </StyleWrapper>
  );
};

export default Carousel;
