/**
 *
 * TableProof
 *
 */

import React from 'react';
import { Props } from './types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { StyledTableCell, useStyles } from './styles';
import { CircularProgress, Grid } from '@material-ui/core';
import { TablePagination } from '@mui/material';

export default function CustomizedTables({
  headers,
  tableData,
  renderBody,
  loading,
  pagination,
}: Props) {
  const classes = useStyles();

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell className={classes.loading} colSpan={headers.length}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    if (!loading && tableData.length) {
      return tableData.map((row, index) => renderBody(row, index));
    }
    return [];
  };

  return (
    <React.Fragment>
      <TableContainer className={classes.tableBody}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index} className={classes.tableHead}>
                  {header.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      {pagination ? <TablePagination {...pagination} /> : null}
    </React.Fragment>
  );
}
