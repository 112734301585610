import { gql } from '@apollo/client';

export const DELETE_ORGANISATION = gql`
  mutation DeleteOrganisation($input: DeleteOrganisationRequestInput!) {
    deleteOrganisation(input: $input) {
      organisation {
        id
      }
    }
  }
`;
