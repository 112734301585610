/**
 *
 * SummaryContainer
 *
 */

import styled from 'styled-components';

export const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
