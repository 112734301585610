import { FC, Fragment, useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
// styles
import { StyleWrapper, Container, Review } from './styles';
// comps
import EditProposalForm from '../../components/Forms/EditProposal';
import EditProposal from '../../components/EditProposal';
import ProposalBricks from '../../components/ProposalBricks';
import Carousel from '../../components/Carousel';
import HardwareComponents from '../../components/HardwareComponents';
import SendProposalButton from '../../components/SendProposalButton';
import ProposalContent from '../../components/ProposalContent';
import BrickSelector from '../../components/BrickSelector';
import YourRoomSolutions from '../../components/YourRoomSolutions';
import WebCamImg from '../../components/WebCamImg';
//  hooks
import useViewProposalState from './useViewProposalState';
import useMeetingSpaces from './useMeetingSpaces';
import usePermissions from '../../utils/hooks/usePermissions';

const ViewProposalPage: FC = () => {
  const {
    id,
    bricks,
    refetchBricks,
    editMode,
    addBrick,
    setEditMode,
    setAddBrick,
  } = useViewProposalState();

  const { roleName, permissions } = usePermissions();

  const {
    templateRooms,
    rooms,
    handleAddRoomToProposal,
    selectedRoom,
    setSelectedRoom,

    openYourRooms,
    setOpenYourRooms,
    setStartTime,
    setEndTime,
    createViewingSession,
  } = useMeetingSpaces();

  const startSession = roleName === 'Customer';

  useEffect(() => {
    const sessionStart: any = new Date().toISOString();
    startSession && setStartTime(sessionStart);

    return () => {
      const sessionEnd: any = new Date().toISOString();
      startSession && setEndTime(sessionEnd);
      startSession && createViewingSession(sessionStart, sessionEnd);
    };
  }, [startSession]);

  return (
    <Fragment>
      <SendProposalButton permissions={permissions} />
      <StyleWrapper>
        <Container>
          <Review>
            <FormattedMessage {...messages.proposalReview} />
          </Review>
          <EditProposalForm />
        </Container>

        {/* <EditProposal /> */}
        <ProposalContent editMode={editMode} setEditMode={setEditMode} />
        <ProposalBricks
          bricks={bricks}
          refetchBricks={refetchBricks}
          type='DEFAULT'
          editMode={!['Customer'].includes(roleName) && editMode}
          proposalId={id}
          setAddBrick={setAddBrick}
        />

        <BrickSelector
          readOnly={['Customer'].includes(roleName)}
          isOpen={addBrick}
          setAddBrick={setAddBrick}
        />
        <YourRoomSolutions
          isOpen={openYourRooms}
          setIsOpen={setOpenYourRooms}
          type='DEFAULT'
          templateRooms={templateRooms}
          rooms={rooms}
          handleAddRoomToProposal={handleAddRoomToProposal}
          readOnly={['Customer'].includes(roleName)}
        />

        <Container>
          <Carousel
            isOpen={openYourRooms}
            rooms={rooms}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
          />
          <HardwareComponents
            selectedRoom={selectedRoom}
            meetingSpaces={openYourRooms}
            readOnly={['Customer'].includes(roleName)}
            setSelectedRoom={setSelectedRoom}
            includeNonSelectedItems={true}
          />
          <WebCamImg type='DEFAULT' />
        </Container>
      </StyleWrapper>
    </Fragment>
  );
};

export default ViewProposalPage;
