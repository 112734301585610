/**
 *
 * BrickModal
 *
 */

import styled from 'styled-components';
import {
  InputWrapperProps,
  TextInputProps,
  FlexProps,
  TitleProps,
  TextProps,
  TextAreaProps,
} from './types';
import { fonts, colors } from '../../utils/styledTheme';
import ButtonHover from '../ButtonHover';

export const Container = styled.div`
  max-width: 1184px;
  background: #f7f7f7;
  width: 88%;
  margin: 0 auto;
`;

export const Title = styled.div<TitleProps>`
  font-size: 61px;
  color: #393b3a;
  text-transform: uppercase;
  font-family: ${fonts.bold};

  margin-bottom: ${(props) => props.marginBottom || '33px'};
  margin-top: ${(props) => props.marginTop || '62px'};
`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  width: ${(props) => props.width || '100%'};
  min-height: ${(props) => props.minHeight || '106px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  input {
    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &::file-selector-button {
      visibility: hidden;
    }
  }
`;

export const TextInput = styled.input<TextInputProps>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  border-radius: 5px;
  border: 2px solid #e1e2e3;
  color: ${colors.fontColor};
  padding: ${(props) => props.padding};
  flex: 1;
`;

export const TextArea = styled.textarea<TextAreaProps>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  min-height: ${(props) => props.minHeight || '72px'};
  border-radius: 5px;
  border: 2px solid ${colors.grey};
  color: ${colors.fontColor};
  flex: 1;
  padding: 2.5% 2.5%;
`;

export const FileInput = styled.input`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  border-radius: 5px;
  border: 2px solid ${colors.grey};
  flex: 1;
  background-image: url("data:image/svg+xml,%3Csvg id='Group_24750' data-name='Group 24750' xmlns='http://www.w3.org/2000/svg' width='29.15' height='28.396' viewBox='0 0 29.15 28.396'%3E%3Cpath id='Path_30922' data-name='Path 30922' d='M32.676,964.375a7.067,7.067,0,0,0-5.01,2.079l-15.334,14.96A4.9,4.9,0,0,0,11,984.93a5.225,5.225,0,0,0,5.15,5.232,4.926,4.926,0,0,0,3.6-1.343L34.72,973.846a2.926,2.926,0,0,0,.946-2,2.978,2.978,0,0,0-2.99-2.99,2.917,2.917,0,0,0-2,.934l-9.355,8.981a.748.748,0,1,0,1.028,1.086l9.343-8.969c.4-.365.539-.537.981-.537a1.373,1.373,0,0,1,1.495,1.495,1.808,1.808,0,0,1-.549.981L18.7,987.756a3.374,3.374,0,0,1-2.546.911,3.732,3.732,0,0,1-3.655-3.737,3.3,3.3,0,0,1,.9-2.464l15.323-14.949a5.609,5.609,0,0,1,3.959-1.647,5.96,5.96,0,0,1,5.98,5.979,5.156,5.156,0,0,1-1.647,3.948l-15.7,15.7a.748.748,0,1,0,1.051,1.063l15.7-15.7a6.806,6.806,0,0,0,2.091-5.01A7.48,7.48,0,0,0,32.676,964.375Z' transform='translate(-11 -964.375)' fill='%23707070'/%3E%3C/svg%3E%0A");
  background-position: left 22px top 50%;
  background-repeat: no-repeat;
  color: transparent;
  cursor: pointer;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export const Label = styled.div`
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-family: 'BrownProTTBold';
  color: black;
  margin-bottom: 10px;
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 20%;
  height: 20px;
  font-size: 20px;
  font-family: ${fonts.light};
  color: black;

  span {
    font-family: ${fonts.bold};
  }

  @media (max-width: 768px) {
    left: 30%;
  }
`;

export const Flex = styled.div<FlexProps>`
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'space-between'};
  margin-top: ${(props) => props.marginTop};
`;

export const DeleteContainer = styled.div`
  margin: 0 auto;
  width: 84%;
  height: auto;
`;

export const Text = styled.div<TextProps>`
  font-family: ${fonts.normal};
  font-size: ${(props) => props.fontSize || '18px'};
  color: black;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

export const FlexRow = styled.div`
  margin-top: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 120px;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: 100px;
    flex-direction: column;
  }
`;

export const StyledHoverButton = styled(ButtonHover)<{ width?: string }>`
  width: ${({ width }) => width || '220px'};
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
