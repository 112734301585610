/**
 *
 * DeleteModal
 *
 */

import styled from 'styled-components';
import { colors, fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const customStylesDeleteModal = {
  content: {
    maxWidth: 656,
    margin: 'auto',
    height: 296,
    borderRadius: 6,
    background: '#F7F7F7',
    padding: 0,
    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 1,
  },
};

export const Container = styled.div<{ width?: string }>`
  width: ${(props) => props.width || '90%'};
  height: auto;
  margin: 0 auto;
`;

export const FlexRow = styled.div<{
  flexDirection?: string;
  justify?: string;
  marginTop?: number;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
  justify-content: ${(props) => props.justify || 'space-between'};
  flex-direction: ${(props) => props.flexDirection};
  margin-top: ${(props) => props.marginTop}px;
`;

export const Text = styled.div<{
  marginTop?: number;
  fontSize?: number;
  upperCase?: boolean;
}>`
  color: black;
  font-size: 30px;
  font-family: ${fonts.bold};
  white-space: nowrap;
  margin-top: ${(props) => props.marginTop}px;
  font-size: ${(props) => props.fontSize}px;
  text-transform: ${(props) => (props.upperCase ? 'uppercase' : null)};
`;
