/**
 *
 * TechModal
 *
 */

import styled from 'styled-components';
import { fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  margin: 50px auto 0;
  max-width: 1249px;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 61px;
  color: #393b3a;
  text-transform: uppercase;
  font-family: ${fonts.bold};
`;

export const Flex = styled.div<{
  justify?: string;
  marginTop?: number;
  direction?: string;
}>`
  margin-top: ${(props) => props.marginTop}px;
  display: flex;
  justify-content: ${(props) => props.justify || 'space-between'};
  flex-direction: ${(props) => props.direction || 'row'};
  width: 100%;
`;

export const Label = styled.div`
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-family: ${fonts.bold};
  color: black;
  margin-bottom: 10px;
`;

export const dzStyles = {
  position: 'relative',
  zIndex: '1',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  borderRadius: '6px',
  border: '2px solid #e1e2e3',
  backgroundSize: '45px 45px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
