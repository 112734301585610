/**
 *
 * Dropzone
 *
 */

import styled from 'styled-components';
import { fonts, colors } from '../../utils/styledTheme';

export const uploadIcon = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47.566' height='47.566' viewBox='0 0 47.566 47.566'%3E%3Cg id='Icon_feather-upload' data-name='Icon feather-upload' transform='translate(1.5 1.5)'%3E%3Cpath id='Path_30919' data-name='Path 30919' d='M49.066,22.5v9.9a4.952,4.952,0,0,1-4.952,4.952H9.452A4.952,4.952,0,0,1,4.5,32.4V22.5' transform='translate(-4.5 7.211)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3Cpath id='Path_30920' data-name='Path 30920' d='M35.259,16.88,22.88,4.5,10.5,16.88' transform='translate(-0.596 -4.5)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3Cpath id='Path_30921' data-name='Path 30921' d='M18,4.5V34.211' transform='translate(4.283 -4.5)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E%0A")`;

export const StyleWrapper = styled.div<{ width?: number; marginTop?: number }>`
  position: relative;
  margin-top: ${(props) => props.marginTop}px;
  width: ${(props) => props.width || 49}%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputWrapper = styled.div<{ width?: number }>`
  position: relative;
  width: ${(props) => props.width || 100}%;
  background-color: white;
  margin-top: auto;
  flex: 1;
`;

export const Label = styled.div<{ marginTop?: number }>`
   width: 100%;
   height: 20px;
   font-size: 20px;
   font-family: ${fonts.bold}
   color: black;
   margin-bottom: 10px;
   margin-top: ${(props) => props.marginTop}px;
 `;

export const Background = styled.img<{
  imageUrl?: string;
  width?: string;
  height?: string;
}>`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: contain;
  border: 0 !important;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

export const BackgroundLabel = styled.div<{ opacity?: string }>`
  position: absolute;
  opacity: ${(props) => props.opacity};
  width: 175px;
  height: 44px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.logitechBlack};
  font: ${fonts.normal};
  text-align: center;
`;

export const UploadIcon = styled.div<{
  imageUrl?: string;
  width?: string;
  height?: string;
}>`
  z-index: 0;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20%;
  height: 20%;
  background-color: white;
  border-radius: 6px;
  background-image: ${uploadIcon};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;
