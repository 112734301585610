import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { SEARCH_ROOMS } from '../../utils/gql/rooms/searchRooms';
import { CREATE_ROOM } from '../../utils/gql/rooms/createRoomMutation';
import { EDIT_ROOM } from '../../utils/gql/rooms/editRoomMutation';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

const workSpaceHeaders = [
  'Image',
  'Workspace name',
  'Workspace Min Capacity',
  'Workspace Max Capacity',
  'icons',
];

const workSpaceObjectKeys = [
  ['imageUrl'],
  ['name'],
  ['minCapacity'],
  ['maxCapacity'],
  ['icons'],
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  minCapacity: Yup.number().typeError('min capacity must be a number'),
  maxCapacity: Yup.number()
    .typeError('max capacity must be a number')
    .min(Yup.ref('minCapacity'), "Can't be lower than minimum capacity"),
});

const useWorkspaceState = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: roomData, refetch: refetchRooms } = useQuery<any>(
    SEARCH_ROOMS,
    {
      variables: { input: { name: null, skip: null, take: null } },
      fetchPolicy: 'network-only',
    }
  );

  const [workspaceModals, setWorkspaceModals] = useState<any>({
    addWorkspace: false,
    editWorkspace: false,
    deleteWorkspace: false,

    selectedSpace: {
      name: '',
      imageUrl: '',
      imageName: '',
      iconUrl: ' ',
      iconName: '',
      minCapacity: '',
      maxCapacity: '',
      sortOrder: '',
      image: null,
    },
  });

  const setEditModal = (row: any) => {
    setWorkspaceModals({
      ...workspaceModals,
      addWorkspace: false,
      editWorkspace: true,
      deleteWorkspace: false,
      selectedSpace: row,
    });
  };

  const setCreateModal = () => {
    setWorkspaceModals({
      ...workspaceModals,
      addWorkspace: true,
      editWorkspace: false,
      deleteWorkspace: false,
    });
  };

  const setDeleteModal = (row: any) => {
    setWorkspaceModals({
      ...workspaceModals,
      addWorkspace: false,
      editWorkspace: false,
      deleteWorkspace: true,
      selectedSpace: row,
    });
  };

  const createWorkspaceFormik = useFormik({
    initialValues: {
      name: '',
      imageUrl: '',
      imageName: '',
      iconUrl: ' ',
      iconName: '',
      minCapacity: '',
      maxCapacity: '',
      sortOrder: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [createRoom] = useMutation<any>(CREATE_ROOM);

  const createWorkspaceSend = () => {
    let input = {
      name: createWorkspaceFormik.values.name,
      image: createWorkspaceFormik.values.imageUrl,
      imageName: createWorkspaceFormik.values.imageName,
      icon: createWorkspaceFormik.values.iconUrl,
      iconName: createWorkspaceFormik.values.iconName,
      minCapacity: Number(createWorkspaceFormik.values.minCapacity),
      maxCapacity: Number(createWorkspaceFormik.values.maxCapacity),
      proposalId: '3944048e6eeb3c347bf1382dac4f137a',
      sortOrder: Number(createWorkspaceFormik.values.sortOrder),
    };

    if (input.minCapacity > input.maxCapacity) {
      return;
    }

    createRoom({
      variables: { input },
    })
      .then((res) => {
        setWorkspaceModals({ ...workspaceModals, addWorkspace: false });
        refetchRooms();
        createWorkspaceFormik.resetForm();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const createWorkspaceMutation = () => {
    // validate form then send
    createWorkspaceFormik.validateForm().then((res) => {
      if (res.name || res.minCapacity || res.maxCapacity) {
        return;
      }

      createWorkspaceSend();
    });
  };

  const editWorkspaceFormik = useFormik({
    initialValues: {
      name: workspaceModals.selectedSpace.name,
      imageUrl: workspaceModals.selectedSpace.imageUrl,
      imageName: workspaceModals.selectedSpace.imageName,
      iconUrl: workspaceModals.selectedSpace.iconUrl,
      iconName: workspaceModals.selectedSpace.iconName,
      minCapacity: workspaceModals.selectedSpace.minCapacity,
      maxCapacity: workspaceModals.selectedSpace.maxCapacity,
      sortOrder: 0,
      id: workspaceModals.selectedSpace.id,
      image: null,
      thumbnail: null,
    },
    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [editRoom] = useMutation<any>(EDIT_ROOM);

  const editWorkspaceMutation = () => {
    let input: any = {
      id: editWorkspaceFormik.values.id,
      name: editWorkspaceFormik.values.name,
      minCapacity: Number(editWorkspaceFormik.values.minCapacity),
      maxCapacity: Number(editWorkspaceFormik.values.maxCapacity),
      proposalId: workspaceModals.selectedSpace.proposalId,
      sortOrder: Number(editWorkspaceFormik.values.sortOrder),
      status: 1,
    };

    if (input.minCapacity > input.maxCapacity) {
      return;
    }

    if (
      editWorkspaceFormik?.errors?.name ||
      editWorkspaceFormik?.errors?.minCapacity ||
      editWorkspaceFormik?.errors?.maxCapacity
    ) {
      return;
    }

    const replaceImage = editWorkspaceFormik.values.image;
    const replaceThumbnail = editWorkspaceFormik.values.thumbnail;
    if (replaceImage) {
      input.image = editWorkspaceFormik.values.imageUrl;
      input.imageName = editWorkspaceFormik.values.imageName;
    }
    if (replaceThumbnail) {
      input.icon = editWorkspaceFormik.values.iconUrl;
      input.iconName = editWorkspaceFormik.values.iconName;
    }

    editRoom({
      variables: { input },
    })
      .then((res) => {
        setWorkspaceModals({ ...workspaceModals, editWorkspace: false });
        refetchRooms();
        editWorkspaceFormik.resetForm();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const deleteWorkspaceFormik = useFormik({
    initialValues: {
      reason: 'no reason',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const deleteWorkspaceMutation = () => {
    let input = {
      id: editWorkspaceFormik.values.id,
      name: editWorkspaceFormik.values.name,
      image: editWorkspaceFormik.values.imageUrl,
      imageName: editWorkspaceFormik.values.imageName,
      icon: editWorkspaceFormik.values.iconUrl,
      iconName: editWorkspaceFormik.values.iconName,
      minCapacity: Number(editWorkspaceFormik.values.minCapacity),
      maxCapacity: Number(editWorkspaceFormik.values.maxCapacity),
      proposalId: workspaceModals.selectedSpace.proposalId,
      sortOrder: Number(editWorkspaceFormik.values.sortOrder),
      status: -1,
      deleteReason: deleteWorkspaceFormik.values.reason,
    };

    if (
      editWorkspaceFormik?.errors?.name ||
      editWorkspaceFormik?.errors?.minCapacity ||
      editWorkspaceFormik?.errors?.maxCapacity
    ) {
      return;
    }

    editRoom({
      variables: { input },
    })
      .then((res) => {
        setWorkspaceModals({
          ...workspaceModals,
          editWorkspace: false,
          deleteWorkspace: false,
        });
        refetchRooms();
        editWorkspaceFormik.resetForm();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  return {
    workSpaceHeaders,
    workSpaceObjectKeys,
    workSpaceRows: roomData?.searchRooms?.filter(
      (room: any) => room.status !== -1
    ),
    workspaceModals,
    createWorkspaceFormik,
    setWorkspaceModals,
    setEditModal,
    setDeleteModal,
    setCreateModal,
    createWorkspaceMutation,
    editWorkspaceMutation,
    editWorkspaceFormik,
    deleteWorkspaceMutation,
    deleteWorkspaceFormik,
  };
};

export default useWorkspaceState;
