import styled from 'styled-components';
import {
  withStyles,
  Theme,
  createStyles,
  TableRow,
  TableCell,
} from '@material-ui/core';

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      '&:nth-of-type(even)': {
        backgroundColor: '#F0F0F0',
      },
    },
  })
)(TableRow);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      color: '#707070',
      border: 'none',
    },
  })
)(TableCell);

export const Row = styled.div<{
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  justify?: string;
}>`
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};
`;

export const FlexCellBody = styled.div<{ direction?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: ${({ direction }) => direction || 'row'};
  height: 100%;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 106px;
  max-height: 106px;
`;
