/*
 * Background Messages
 *
 * This contains all the text for the Background component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome: {
    id: 'app.components.Background.welcome',
    defaultMessage: 'WELCOME TO',
  },
  sales: {
    id: 'app.components.Background.sales',
    defaultMessage: 'SALES',
  },
  view: {
    id: 'app.components.Background.view',
    defaultMessage: 'View',
  },
  createAProposal: {
    id: 'app.components.Background.createAProposal',
    defaultMessage: 'Create a Proposal',
  },
  viewProposals: {
    id: 'app.components.Background.viewProposals',
    defaultMessage: 'View Proposals',
  },
});
