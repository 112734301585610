// @ts-nocheck
import React, { useReducer, useEffect, useState } from 'react';
import ClientReducer from './clientReducer';

// graphql
import { useMutation, useQuery } from '@apollo/client';

import { SEARCH_CLIENTS } from '../../utils/gql/clients/searchClientsQuery';
import { SearchClients } from '../../utils/gql/clients/__generated__/SearchClients';
import useDebounce from '../../utils/hooks/useDebounce';

import { EDIT_USER } from '../../utils/gql/users/editUserMutation';
import { CREATE_USER } from '../../utils/gql/users/createUserMutation';
import { RESET_PASSWORD } from '../../utils/gql/users/resetPasswordMutation';
import { SEARCH_ORGANISATIONS } from '../../utils/gql/organisations/searchOrganisationsQuery';
import { SearchOrganisastions } from '../../utils/gql/organisations/__generated__/SearchOrganisastions';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// link to the create organisation page / modal
import { useHistory } from 'react-router';

const validationSchemaCreateClient = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  organisation: Yup.object().shape({
    value: Yup.string().required('Organization is required'),
    label: Yup.string(),
  }),
});

const validationSchemaEditClient = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

const useClientState = () => {
  const initialState = {
    selectedClient: {},
    selectedModal: {
      editModal: false,
      deleteModal: false,
      createModal: false,
      resetPasswordModal: false,
    },
  };

  const [state, dispatch] = useReducer(ClientReducer, initialState);

  const { selectedClient, selectedModal } = state;

  const { enqueueSnackbar } = useSnackbar();

  const [searchTerm, setSearchTerm] = useState('');

  const {
    loading,
    data: searchData,
    refetch: refetchClients,
  } = useQuery<SearchClients>(SEARCH_CLIENTS, {
    variables: { input: { searchTerm: null, skip: null, take: null } },
  });

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const refetchHelper = () => {
    if (searchTerm === '' || searchTerm === null) {
      refetchClients({
        input: { searchTerm: null, skip: null, take: null },
      });
    } else if (debouncedSearchTerm) {
      refetchClients({
        input: { searchTerm: debouncedSearchTerm, skip: 0, take: null },
      });
    }
  };

  useEffect(() => {
    refetchHelper();
    // eslint-disable-next-line
  }, [searchTerm, debouncedSearchTerm]);

  const setCreateModal = () => {
    dispatch({
      type: 'SET_CREATE_MODAL',
    });
  };

  const setEditModal = (selectedClient: any) => {
    dispatch({
      type: 'SET_EDIT_MODAL',
      payload: { selectedClient },
    });
  };

  const setDeleteModal = (selectedClient: any) => {
    dispatch({
      type: 'SET_DELETE_MODAL',
      payload: { selectedClient },
    });
  };

  const setResetPasswordModal = (selectedClient: any) => {
    dispatch({
      type: 'SET_RESET_PASSWORD_MODAL',
      payload: { selectedClient },
    });
  };

  const resetAllModals = () => {
    dispatch({
      type: 'RESET_ALL_MODALS',
    });
  };

  // DELETE MUTATION
  const [editUser] = useMutation<any>(EDIT_USER);

  const deleteClientMutation = () => {
    let input = {
      id: selectedClient?.id,
      status: -1,
    };

    editUser({
      variables: { input },
    })
      .then((res) => {
        refetchHelper();
        resetAllModals();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // EDIT MUTATION
  const formikEditClient = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: selectedClient?.organisation?.name,
      firstName: selectedClient?.firstName,
      lastName: selectedClient?.lastName,
      email: selectedClient?.email,
      phoneNumber: selectedClient?.phoneNumber,
      jobTitle: selectedClient?.jobTitle,
      role: selectedClient?.userRole?.name,
    },
    validationSchema: validationSchemaEditClient,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const editClientMutation = () => {
    let input = {
      id: selectedClient?.id,
      firstName: formikEditClient.values.firstName,
      lastName: formikEditClient.values.lastName,
      jobTitle: formikEditClient.values.jobTitle,
      phoneNumber: formikEditClient.values.phoneNumber,
      userRoleId: formikEditClient.values.role?.id,
    };

    editUser({
      variables: { input },
    })
      .then((res) => {
        refetchHelper();
        resetAllModals();
        formikEditClient.resetForm();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // CREATE MUTATION
  const [createUser] = useMutation<any>(CREATE_USER);

  const formikCreateClient = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      phoneNumber: '',
      jobTitle: '',
      organisation: {},
    },
    validationSchema: validationSchemaCreateClient,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const createClientMutation = () => {
    let input = {
      firstName: formikCreateClient.values.firstName,
      lastName: formikCreateClient.values.lastName,
      jobTitle: formikCreateClient.values.jobTitle,
      phoneNumber: formikCreateClient.values.phoneNumber,
      userRoleId: '39b92e534b184c26acdc5f0bd5927837',
      email: formikCreateClient.values.email,
      organisationId: formikCreateClient?.values.organisation?.value,
    };

    createUser({
      variables: { input },
    })
      .then((res) => {
        refetchHelper();
        resetAllModals();
        formikCreateClient.resetForm();
      })
      .catch((err) => {
        if (err.message === 'A user with the provided email already exists') {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
      });
  };

  // RESET PASSWORD
  const [resetPassword] = useMutation<any>(RESET_PASSWORD);

  const resetPasswordMutation = () => {
    resetPassword({
      variables: {
        input: {
          email: selectedClient?.email,
        },
      },
    })
      .then((res) => {
        resetAllModals();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, refetch } = useQuery<SearchOrganisastions>(
    SEARCH_ORGANISATIONS,
    {
      variables: { input: { name: '', skip: 0, take: 100 } },
    }
  );

  useEffect(() => {
    if (selectedModal?.createModal) {
      refetch();
    }
  }, [selectedModal?.createModal]);

  let history = useHistory();

  const goToOrganisationModal = () => {
    history.push({
      pathname: '/organisations',
      state: { openCreateOrgModal: true },
    });
  };

  return {
    searchCustomers: searchData?.searchCustomers,
    setSearchTerm,
    loading,

    selectedClient,
    selectedModal,
    setCreateModal,
    setDeleteModal,
    setEditModal,
    resetAllModals,
    deleteClientMutation,
    editClientMutation,
    formikEditClient,
    formikCreateClient,
    createClientMutation,
    resetPasswordMutation,
    setResetPasswordModal,
    searchOrganisations: data?.searchOrganisations,

    goToOrganisationModal,
  };
};

export default useClientState;
