/**
 *
 * Icon
 *
 */

import React, { FC, useState, Fragment } from 'react';
import { T, CustomerBtnT } from './types';
import { Wrapper, StyledSvg, ToolTip, Btn } from './styles';

import ProposalSnapShot from '../ProposalSnapShot';

const returnOpacity = (label: string, status: string) => {
  if (label === 'Sharing' && status === 'Sent') {
    return 1;
  }

  if (label === 'Sharing' && status === 'Draft') {
    return 0.2;
  } else return 1;
};

const DefaultIcon: FC<T> = ({ imageUrl, label, action, status }) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={action}
    >
      <StyledSvg imageUrl={imageUrl} opacity={returnOpacity(label, status)} />

      {hover && <ToolTip>{label}</ToolTip>}
    </Wrapper>
  );
};

const SnapShotIcon: FC<T> = ({
  imageUrl,
  label,
  action,

  row,
  selectedModal,
  selectedProposal,
  resetAllModals,
}) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={action}
    >
      <StyledSvg imageUrl={imageUrl} />

      {hover && <ToolTip>{label}</ToolTip>}

      {selectedModal.snapShotModal &&
        row.proposal.id === selectedProposal.id && (
          <ProposalSnapShot
            proposal={row?.proposal}
            sentTo={row?.proposal?.sentTo}
            onRequestClose={() => resetAllModals()}
          />
        )}
    </Wrapper>
  );
};

const CustomerBtn: FC<CustomerBtnT> = ({ label, action }) => {
  const [hover, setHover] = useState<boolean>(false);

  const returnLabel = () => {
    if (label === 'Review Proposal') {
      return 'VIEW';
    }

    if (label === 'Sharing') {
      return 'SHARE';
    } else return '';
  };

  return (
    <Btn
      initial={false}
      animate={{
        background: hover ? '#fff' : '#2e3132',
        color: hover ? '#2e3132' : '#fff',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={action}
    >
      {returnLabel()}
    </Btn>
  );
};

const Icon: FC<T> = ({
  imageUrl,
  label,
  action,
  row,
  selectedModal,
  selectedProposal,
  resetAllModals,
  userRole,
  status,
}) => (
  <Fragment>
    {label === 'Snapshot' && (
      <SnapShotIcon
        imageUrl={imageUrl}
        label={label}
        action={action}
        row={row}
        selectedModal={selectedModal}
        selectedProposal={selectedProposal}
        resetAllModals={resetAllModals}
      />
    )}

    {label !== 'Snapshot' && userRole !== 'Customer' && (
      <DefaultIcon
        imageUrl={imageUrl}
        label={label}
        action={action}
        row={row}
        selectedModal={selectedModal}
        selectedProposal={selectedProposal}
        resetAllModals={resetAllModals}
        status={status}
      />
    )}

    {userRole === 'Customer' && <CustomerBtn label={label} action={action} />}
  </Fragment>
);

export default Icon;
