/**
 *
 * SummaryProposal
 *
 */

import styled from 'styled-components';
import { fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div<{
  marginTop?: number;
  marginBottom?: number;
  fontSize?: number;
  color?: string;
  fontFamily?: string;
}>`
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  font-size: ${(props) => props.fontSize || '31'}px;
  color: ${(props) => props.color || 'black'};
  font-family: ${(props) => props.fontFamily || fonts.bold};
`;

export const Logo = styled.img`
  margin-top: 5px;
  width: 444px;
  height: 80px;
  object-fit: contain;
`;
