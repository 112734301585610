/**
 *
 * ContentManagementContainer
 *
 */

import { FC } from 'react';
import useCmsState from './useCmsState';

import CMSTabs from '../../components/CmsTabs';
import Technologies from '../../components/Technologies';
import ProposalBricks from '../../components/ProposalBricks';
import EditModal from '../../components/BrickModal/EditModalHelper';

import { StyleWrapper } from './styles';

import Workspaces from '../../components/Workspaces';

const ContentManagementContainer: FC = () => {
  const {
    tabs,
    changeTab,
    returnComponent,
    templateBricks,
    refetchTemplate,
    templateId,
    setAddBrick,
    addBrick,
    formikCreateBrick,
    createBrickMutation,
  } = useCmsState();

  return (
    <StyleWrapper>
      <CMSTabs tabs={tabs} changeTab={changeTab} />
      {returnComponent('Technology') && <Technologies />}
      {returnComponent('Proposal Bricks') && (
        <ProposalBricks
          bricks={templateBricks}
          refetchBricks={refetchTemplate}
          type='CMS'
          editMode={true}
          proposalId={templateId}
          setAddBrick={setAddBrick}
        />
      )}

      <EditModal
        isOpen={addBrick}
        onRequestClose={() => setAddBrick(false)}
        title='ADD PROPOSAL BRICK'
        formik={formikCreateBrick}
        mutation={createBrickMutation}
      />

      {returnComponent('Workspaces') && <Workspaces />}
    </StyleWrapper>
  );
};

export default ContentManagementContainer;
