// @ts-nocheck
import ProductLiterature from '../images/hardware/ProductLiterature.svg';
import DataSheet from '../images/hardware/DataSheet.svg';
import ProductVideos from '../images/hardware/ProductVideos.svg';
import WiringDiagrams from '../images/hardware/WiringDiagrams.svg';

export const techIcons: any = {
  0: ProductLiterature,
  1: DataSheet,
  2: ProductVideos,
  3: WiringDiagrams,
};

export const techTypes = [
  { value: '0', label: 'Video' },
  { value: '1', label: 'BYO' },
  { value: '2', label: 'Audio' },
  { value: '3', label: 'Accessories' },
];

export const collateralTypes = [
  {
    label: 'Product Literature',
    value: '0',
  },
  { label: 'Data Sheet', value: '1' },
  {
    label: 'Product Videos',
    value: '2',
  },
  {
    label: 'Wiring Diagram',
    value: '3',
  },
];

export const returnCollateralType = (id: number) => {
  if (id === 0) return collateralTypes[0].label;
  if (id === 1) return collateralTypes[1].label;
  if (id === 2) return collateralTypes[2].label;
  if (id === 3) return collateralTypes[3].label;
  else return '';
};
