/**
 *
 * SummaryContainer
 *
 */
import { FC, Fragment, useEffect, useState } from 'react';

import useSummaryState from './useSummaryState';

import SummaryData from '../../components/SummaryData';
import SummaryTabs from '../../components/SummaryTabs';
import SummaryProposal from '../../components/SummaryProposal';
import SummaryActivity from '../../components/SummaryActivity';

import ProposalBricks from '../../components/ProposalBricks';
import YourRoomSolutions from '../../components/YourRoomSolutions';
import WebCamImg from '../../components/WebCamImg';

import { StyleWrapper } from './styles';
import Carousel from '../../components/Carousel';
import Container from '../../components/Container';
import HardwareComponents from '../../components/HardwareComponents';
import { useQuery } from '@apollo/client';
import { WHO_AM_I } from '../../utils/gql/login/whoAmIQuery';
import { WhoAmI } from '../../utils/gql/login/__generated__/WhoAmI';

const SummaryContainer: FC = () => {
  const {
    proposal,
    bricks,
    refetchBricks,
    selectedTab,
    setSelectedTab,
    openYourRooms,
    setOpenYourRooms,
    rooms,
    templateRooms,
    returnYourRoomSolutions,
    startTime,
    setStartTime,
    setEndTime,
    createViewingSession,
  } = useSummaryState();

  const [selectedRoom, setSelectedRoom] = useState(null);

  const { data: userData, loading: userLoading } = useQuery<WhoAmI>(WHO_AM_I, {
    fetchPolicy: 'cache-only',
  });

  const isCustomer = userData?.permissions?.roleName === 'Customer';

  useEffect(() => {
    const sessionStart: any = new Date().toISOString();
    setStartTime(sessionStart);

    return () => {
      const sessionEnd: any = new Date().toISOString();
      setEndTime(sessionEnd);
      isCustomer && createViewingSession(sessionStart, sessionEnd);
    };
  }, [isCustomer]);

  return (
    <StyleWrapper>
      <SummaryData proposal={proposal} />

      <SummaryTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      <SummaryProposal proposal={proposal} selectedTab={selectedTab} />

      {selectedTab?.activity && <SummaryActivity proposal={proposal} />}

      <ProposalBricks
        bricks={selectedTab.activity ? [] : bricks}
        refetchBricks={refetchBricks}
        type={selectedTab.preview ? 'PREVIEW' : 'CLICK_STATS'}
        editMode={false}
        proposalId={proposal?.id}
      />
      {returnYourRoomSolutions() && (
        <Fragment>
          <YourRoomSolutions
            type={selectedTab.preview ? 'PREVIEW' : 'CLICK_STATS'}
            isOpen={openYourRooms}
            setIsOpen={setOpenYourRooms}
            templateRooms={templateRooms}
            rooms={rooms}
          />

          <Container>
            <Carousel
              isOpen={openYourRooms}
              rooms={rooms}
              selectedRoom={selectedRoom}
              setSelectedRoom={setSelectedRoom}
            />
            <HardwareComponents
              selectedRoom={selectedRoom}
              meetingSpaces={openYourRooms}
              readOnly
              setSelectedRoom={setSelectedRoom}
              includeNonSelectedItems={false}
            />
          </Container>
        </Fragment>
      )}
      {selectedTab?.activity ? null : <WebCamImg type='PREVIEW' />}
    </StyleWrapper>
  );
};

export default SummaryContainer;
