import React, { useState } from 'react';
import { SelectedTabI } from './types';

import { useParams } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import { GET_PROPOSAL } from '../../utils/gql/proposals/proposalsQuery';
import { CREATE_SESSION } from '../../utils/gql/clickstats/createViewingSession';
import { GetProposal } from '../../utils/gql/proposals/__generated__/GetProposal';
import { GET_BRICKS_FROM_PROPOSAL } from '../../utils/gql/proposals/getBricksFromProposal';
import { GET_TEMPLATE_ROOMS } from '../../utils/gql/templateRooms/templateRoomsQuery';

const useSummaryState = () => {
  const { id } = useParams<any>();

  const { data: proposalData } = useQuery<GetProposal>(GET_PROPOSAL, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const { data: brickData, refetch: refetchBricks } = useQuery<any>(
    GET_BRICKS_FROM_PROPOSAL,
    {
      variables: { id },
      fetchPolicy: 'network-only',
    }
  );

  const [selectedTab, setSelectedTab] = useState<SelectedTabI>({
    activity: true,
    preview: false,
    clickStats: false,
  });

  const [openYourRooms, setOpenYourRooms] = useState<boolean>(false);

  // for Thiago's carousel
  const [selectedRoom, setSelectedRoom] = useState(null);

  const { data: templateData } = useQuery<any>(GET_TEMPLATE_ROOMS, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const returnYourRoomSolutions = () => {
    if (selectedTab.preview || selectedTab.clickStats) {
      return true;
    }
    return false;
  };

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [createSession] = useMutation(CREATE_SESSION);

  const createViewingSession = (start: any, end: any) => {
    const input = {
      start,
      end,
      entityId: 0,
      entityTableId: id,
    };
    createSession({ variables: { input } })
      .then(() => {})
      .catch((error) => console.log(error.message));
  };

  return {
    proposal: proposalData?.proposal,
    bricks: brickData?.proposal?.bricks,
    selectedTab,
    setSelectedTab,
    refetchBricks,

    openYourRooms,
    setOpenYourRooms,
    rooms: templateData?.proposal?.rooms,
    templateRooms: templateData?.proposal?.templateRooms,
    returnYourRoomSolutions,
    selectedRoom,
    setSelectedRoom,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    createViewingSession,
  };
};

export default useSummaryState;
