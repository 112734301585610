/**
 *
 * ProposalSnapShot
 *
 */

import styled from 'styled-components';
import { fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  position: absolute;
  width: 518px;
  height: auto;
  background: white;
  top: 40px;
  right: 20px;
  z-index: 99999999;
  border: 1px solid black;
`;

export const Container = styled.div`
  margin: 18px auto 0;
  width: 482px;
  position: relative;
`;

export const ProposalName = styled.div`
  font-size: 14px;
  color: #000000;
  text-align: left;
  height: 22px;
  text-transform: uppercase;
  font-family: ${fonts.bold};
`;

export const Org = styled.div`
  font-size: 14px;
  color: #00b8fc;
  text-align: left;
  text-transform: uppercase;
  height: 22px;
  font-family: ${fonts.bold};
`;

export const FlexRow = styled.div<{
  marginTop?: string;
  borderBottom?: boolean;
  paddingBottom?: string;
}>`
  margin-top: ${(props) => props.marginTop};
  height: 19px;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  border-bottom: ${(props) => (props.borderBottom ? '1px solid grey' : null)};
  padding-bottom: ${(props) => props.paddingBottom};
`;

export const RowHeader = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
  color: black;
  font-family: ${fonts.light};
  font-size: 16px;
  text-align: left;
`;

export const Item = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
  color: black;
  font-family: ${fonts.normal};
  font-size: 16px;
  text-align: left;
`;

export const SentToColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Close = styled.div`
  position: absolute;
  right: 0px;
  top: 0x;
  color: black;
  cursor: pointer;
`;

export const Arrow = styled.div`
  position: absolute;
  z-index: -1;
  right: 0px;
  top: -7px;
  height: 7px;
  width: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 10 7'%3E%3Cpath id='Polygon_8' data-name='Polygon 8' d='M5,0l5,7H0Z' stroke='%23333' fill='white'/%3E%3C/svg%3E");
`;
