/**
 *
 * CollTable
 *
 */
//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { T } from './types';

import { StyleWrapper, Line, Text, FlexRev, Pages } from './styles';
import Table from '../Table';
import { ReactComponent as Edit } from '../../images/svg/EditIcon.svg';
import { ReactComponent as Delete } from '../../images/svg/DeleteIcon.svg';

import { techIcons, returnCollateralType } from '../../constants/equipments';

const CollTable: React.FC<T> = ({
  collateralData,
  setEditCollateral,
  setDeleteCollateral,
}) => {
  const [slice, setSlice] = useState({
    start: 0,
    end: 4,
  });

  const { start, end } = slice;

  const returnRows = (collateralData: any, start: number, end: number) => {
    let map = collateralData?.equipment?.collateral
      ?.filter((el: any) => el.status !== -1)
      .map((obj: any) => {
        return {
          ...obj,
          collLogo: techIcons[obj?.collateralTypeId],
          collType: returnCollateralType(obj?.collateralTypeId),
        };
      });

    return map?.slice(start, end);
  };

  const returnArrayLength = () => {
    return collateralData?.equipment?.collateral?.filter(
      (el: any) => el.status !== -1
    ).length;
  };

  const left = '<';
  const right = '>';

  const clickLeft = () => {
    let newStart = start - 4;
    let newEnd = end - 4;

    if (newStart < 0) return;

    setSlice({ start: newStart, end: newEnd });
  };

  const clickRight = () => {
    let newStart = start + 4;
    let newEnd = end + 4;

    if (end >= returnArrayLength()) return;

    setSlice({ start: newStart, end: newEnd });
  };

  return (
    <StyleWrapper>
      <Line>
        <Text>Add Collateral</Text>
      </Line>

      <Table
        headers={[' ', 'Collateral Name', 'Collateral Type', 'File', 'icons']}
        rows={returnRows(collateralData, start, end)}
        colObjectKeys={[
          ['collLogo'],
          ['name'],
          ['collType'],
          ['fileUrl'],
          ['icons'],
        ]}
        icons={[
          {
            component: Edit,
            action: setEditCollateral,
          },
          { component: Delete, action: setDeleteCollateral },
        ]}
      />
      {returnArrayLength() > 4 && (
        <FlexRev>
          <Pages>
            <div onClick={() => clickLeft()}>{left}</div>
            <div onClick={() => clickRight()}>{right}</div>
          </Pages>
        </FlexRev>
      )}
    </StyleWrapper>
  );
};

export default CollTable;
