/**
 *
 * WorkspaceModal
 *
 */

import { FC, Fragment, useEffect, useState } from 'react';
import { T } from './types';
import { StyleWrapper, Container, Title, Flex } from './styles';

import Modal from 'react-modal';
import Input from '../../components/Input';
import Dropzone from '../Dropzone';
import ButtonHover from '../ButtonHover';

const customStyles = {
  content: {
    maxWidth: '1360px',
    margin: 'auto',
    borderRadius: '6px',
    background: '#f7f7f7',
    onset: 0,
    paddingBottom: 70,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    onset: 0,
    zIndex: 1,
  },
};

const style = {
  position: 'relative',
  zIndex: '1',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  borderRadius: '6px',
  border: '2px solid #e1e2e3',
  backgroundSize: '45px 45px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const WorkspaceModal: FC<T> = ({
  isOpen,
  onRequestClose,
  formik,
  title,
  editMutation,
  deleteMutation,
  type,
}) => {
  const editMode = type === 'EDIT';
  console.log('formik values', formik.values);
  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
    >
      <Container>
        <Title>{title}</Title>

        <form onSubmit={formik.handleSubmit}>
          <Input
            label='Workspace name'
            id='name'
            name='name'
            type='text'
            onChange={formik.handleChange}
            value={formik.values.name}
            padding='0 2.5%'
            formik={formik}
          />

          <Flex>
            <Input
              label='Minimum capacity'
              id='minCapacity'
              name='minCapacity'
              type='text'
              formik={formik}
              onChange={formik.handleChange}
              value={formik.values.minCapacity}
              padding='0 5%'
              width='49%'
            />

            <Input
              label='Maximum capacity'
              id='maxCapacity'
              name='maxCapacity'
              type='text'
              formik={formik}
              onChange={formik.handleChange}
              value={formik.values.maxCapacity}
              padding='0 5%'
              width='49%'
            />
          </Flex>

          <Flex>
            <Dropzone
              baseStyle={style}
              formik={formik}
              name='iconName'
              file='iconUrl'
              fileName='iconUrl'
              editName='thumbnail'
              backgroundWidth='98px'
              label='Thumbnail'
              editMode={editMode}
              height='314px'
              marginTop={26}
            />

            <Dropzone
              baseStyle={style}
              formik={formik}
              name='imageName'
              file='imageUrl'
              fileName='imageUrl'
              editName='image'
              backgroundWidth='100%'
              label='Carousel Image'
              editMode={editMode}
              height='314px'
              marginTop={26}
            />
          </Flex>

          <Flex marginTop={51} justify='flex-end'>
            <ButtonHover
              label='CANCEL'
              color='white'
              onClick={() => onRequestClose()}
              marginLeft='15px'
            />
            <ButtonHover
              label='SAVE'
              color='black'
              disabled={!formik.values.name}
              marginLeft='15px'
              onClick={() => editMutation()}
            />
          </Flex>
        </form>
      </Container>
    </Modal>
  );
};

export default WorkspaceModal;
