import { gql } from "@apollo/client";

export const CREATE_PROPOSAL = gql`
    mutation CreateProposal($input: CreateProposalRequestInput!) {
        createProposal(input: $input) {
            proposal {
                id
            }
        }
    }
`;
