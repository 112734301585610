import { gql } from '@apollo/client';

export const EDIT_ROOM = gql`
  mutation EditRoom($input: EditRoomRequestInput!) {
    editRoom(input: $input) {
      room {
        id
        name
        imageUrl
        iconUrl
        minCapacity
        maxCapacity
      }
    }
  }
`;
